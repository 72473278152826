import { config } from '@fortawesome/fontawesome-svg-core';
import $axios from 'src/utils/axios';
import IP from 'src/StoreGlobal/IP';

let companyId = parseInt(localStorage.getItem('company_id'));
let accessToken = localStorage.getItem('token');
let user_id = parseInt(localStorage.getItem('user_id'));

export const GetOverviewData = (project_id) => {
  let values = {
    companyId: parseInt(localStorage.getItem('company_id')) || null,
    accessToken: localStorage.getItem('token') || null,
    userId: parseInt(localStorage.getItem('user_id')) || null,
  };

  return $axios.get(`overview/${values.companyId}/${project_id}`)
};

export const SaveOverview = (dataVal, project_id) => {
  let data = {
    update: dataVal,
  };

  const company_id = parseInt(localStorage.getItem('company_id')) || null;

  return $axios.put(`overview/${company_id}/${project_id}`, {
    ...data
  })
};

export const UploadImage = (dataVal, config, project_id) => {
  // let data = {
  //   data: dataVal,
  //   config:config
  // };

  return $axios({
    method: 'put',
    url: IP + `overview/photo-upload/${companyId}/${project_id}`,
    data: dataVal
  })
};
