import React, { lazy, useState, useEffect } from 'react';
import {
  CButton,
  CCard,
  CCardBody,
  CCardFooter,
  CCardHeader,
  CCol,
  CRow,
  CCollapse,
  CFade,
  CSwitch,
  CLink,
  CHeader,
  CFooter,
  CBadge,
  CListGroupItem,
  CInput,
  CInputGroup,
  CInputGroupPrepend,
  CTabs,
  CTabContent,
  CTabPane,
  CNav,
  CNavItem,
  CNavLink,
  CFormGroup,
  CLabel,
  CForm,
  CTextarea,
  CInputRadio,
  CInputGroupText,
  CValidFeedback,
  CSelect,
  CModal,
  CModalHeader,
  CModalTitle,
  CModalFooter,
  CModalBody,
} from '@coreui/react';
import IP from '../../StoreGlobal/IP';
import axios from 'axios';
import swal from 'sweetalert';
import LoadingLottie from '../../json/data_available';
import Lottie from 'react-lottie';
import Loading from '../aimhi-widgets/Loading';
import Select from 'react-select';
import { validateProjectId } from 'src/utils/validation';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { regions, provinces, cities, barangays } from 'select-philippines-address';
import { GetProjectCategory, GetProjectType, SaveProject } from 'src/request/Projects';
const AddProject = (props) => {
  const Swal = require('sweetalert2');
  let accessToken = localStorage.getItem('token');
  let user_id = parseInt(localStorage.getItem('user_id'));

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: LoadingLottie,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  const [projectname, setProjectName] = useState('');
  const [projectlocation, setProjectLocation] = useState('');
  const [projectcategory, setProjectCategory] = useState('');
  const [projectid, setProjectId] = useState('');
  const [projectcost, setProjectCost] = useState('');
  const [sourcefund, setSourceFund] = useState('');
  const [projectstart, setProjectStart] = useState('');
  const [nodays, setNoDays] = useState('');
  const [projectTypeData, setprojectTypeData] = useState([]);
  const [projectType, setProjectType] = useState('');
  const [projectCategoryData, setProjectCategoryData] = useState([]);
  const [typedate, settypedate] = useState('text');
  const [loading, setLoading] = useState(false);
  const [regionData, setRegion] = useState([]);
  const [provinceData, setProvince] = useState([]);
  const [cityData, setCity] = useState([]);
  const [barangayData, setBarangay] = useState([]);
  const [regionAddr, setRegionAddr] = useState('');
  const [provinceAddr, setProvinceAddr] = useState('');
  const [cityAddr, setCityAddr] = useState('');
  const [barangayAddr, setBarangayAddr] = useState('');
  const [streetAddr, setStreetAddr] = useState('');
  const [ocm, setOcm] = useState('');
  const [cp, setCp] = useState('');
  const [hasVat, setHasVat] = useState('');

  useEffect(() => {
    ProjectType();
    region();
    ProjectCategory();
  }, []);

  // const AddProject = (event) => {
  //   console.log(event)
  //   props.ParentCallback(event)
  // }

  const region = () => {
    regions().then((response) => {
      console.log(response);
      setRegion(response);
    });
  };

  const province = (e) => {
    setRegionAddr(e.target.selectedOptions[0].text);
    provinces(e.target.value).then((response) => {
      setProvince(response);
      setCity([]);
      setBarangay([]);
    });
  };

  const city = (e) => {
    setProvinceAddr(e.target.selectedOptions[0].text);
    cities(e.target.value).then((response) => {
      setCity(response);
    });
  };

  const barangay = (e) => {
    setCityAddr(e.target.selectedOptions[0].text);
    barangays(e.target.value).then((response) => {
      setBarangay(response);
    });
  };

  const brgy = (e) => {
    setBarangayAddr(e.target.selectedOptions[0].text);
  };

  const ProjectType = () => {
    GetProjectType()
      .then((res) => {
        if (res.data.status == 1) {
          setprojectTypeData(res.data.response);
        } else {
          Swal.fire({
            icon: 'error',
            title: res.data.response,
            text: res.data.description,
            confirmButtonColor: '#23303D',
            // footer: '<a href="">Why do I have this issue?</a>',
          });
        }

        // setprojectData(res.data);
        // setLoading(false);
      })
      .catch((error) => {
        console.log('projects/AddProject.js', ' ', 'line 147', error);
      });
  };

  const ProjectCategory = () => {
    GetProjectCategory()
      .then((res) => {
        if (res.data.status == 1) {
          setProjectCategoryData(res.data.response);
        } else {
          Swal.fire({
            icon: 'error',
            title: res.data.response,
            text: res.data.description,
            confirmButtonColor: '#23303D',
            // footer: '<a href="">Why do I have this issue?</a>',
          });
        }

        // setprojectData(res.data);
        // setLoading(false);
      })
      .catch((error) => {
        console.log('projects/AddProject.js', ' ', 'line 147', error);
      });
  };

  const option = projectTypeData.map((item, Index) => {
    return (
      <option value={item.value} key={Index}>
        {item.label}
      </option>
    );
  });

  const categoryOptiom = projectCategoryData.map((item, Index) => {
    return (
      <option value={item.value} key={Index}>
        {item.label}
      </option>
    );
  });

  const Save = () => {
    setLoading(true);
    const projectstart_date = new Date(projectstart);
    const projectstart_year = projectstart_date.getFullYear();
    const projectstart_month = String(projectstart_date.getMonth() + 1).padStart(2, '0');
    const projectstart_day = String(projectstart_date.getDate()).padStart(2, '0');
    const projectstart_formattedDate = `${projectstart_year}-${projectstart_month}-${projectstart_day}`;

    const data = {
      name: projectname,
      // location: projectlocation,
      project_category: projectcategory,
      identifier: projectid,
      company_id: parseInt(localStorage.getItem('company_id')),
      total_budget: projectcost,
      source_of_funds: sourcefund,
      // start_date: projectstart,
      start_date: projectstart_formattedDate,
      duration: nodays,
      region: regionAddr,
      province: provinceAddr,
      city: cityAddr,
      barangay: barangayAddr,
      street: streetAddr,
      estimate_status: 1,
      ocm: ocm,
      cp: cp,
      has_vat: hasVat,
      project_type: projectType,
    };

    SaveProject(data)
      .then((response) => {
        if (response.data.status == 200) {
          setLoading(false);
          data['id'] = response.data.response.id; // insert id
          props.Overview(data, 'Overview');
        } else {
          Swal.fire({
            icon: 'error',
            title: response.data.response,
            text: response.data.description,
            confirmButtonColor: '#23303D',
            // footer: '<a href="">Why do I have this issue?</a>',
          });
          setLoading(false);
        }
      })
      .catch((error) => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: error,
          confirmButtonColor: '#23303D',
          // footer: '<a href="">Why do I have this issue?</a>',
        });
        setLoading(false);
      });
  };

  const cancel = () => {
    window.location.reload(true);
  };

  return (
    <>
      {loading ? <Loading /> : ''}
      <CCol xs="12" sm="12" md="7" lg="7">
        <div>
          <span className="Title">Let's Connect!</span>
        </div>

        <span className="module-description">
          {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eget
            pretium sapien. In hac habitasse platea dictumst. Praesent vel diam
            cursus velit sodales ornare. Maecenas tincidunt lacinia ipsum non
            gravida. Nulla ut orci viverra neque sollicitudin luctus mattis eu
            ipsum. Ut id volutpat lectus. Nunc imperdiet metus non velit
            fermentum imperdiet nec in nisi. */}
        </span>
      </CCol>
      {/* <CCol
          xs="12"
          sm="12"
          md="12"
          lg="12"
          xl="7"
          style={{ padding: "10px" }}
        >
          <span className="category_label">
            <CBadge color="secondary_base" shape="pill">
              1
            </CBadge>
            <span style={{ margin: "8px" }}>
              Information you'd like to provide
            </span>
          </span>
        </CCol> */}

      <CCol xs="12" sm="12" md="10" lg="12" xl="7" style={{ padding: '30px' }}>
        <h6>Project Information</h6>
        <span
          style={{
            color: '#B42A19',
            fontSize: '12px',
            fontStyle: 'italic',
          }}
        >
          Note:all fields with (*) is required to be filled out.
        </span>
        <CFormGroup row>
          {projectname != '' && (
            <CLabel className="fade-in col-md-12" style={{ fontSize: '14px' }}>
              Project Name
            </CLabel>
          )}
          <CCol md="5">
            <CInput type="text" placeholder="Project Name" size="md" onChange={(e) => setProjectName(e.target.value)} value={projectname} className="input-design" />
          </CCol>
          {projectname == '' && (
            <span
              className="col-md-1"
              style={{
                color: '#B42A19',
                fontSize: '16px',
                fontWeight: '700',
              }}
            >
              *
            </span>
          )}

          <span className="help-block col-md-12" style={{ fontSize: '14px', marginTop: '8px' }}>
            Project name is unique.
          </span>
        </CFormGroup>
        <CFormGroup row style={{ marginTop: '20px' }}>
          {regionAddr != '' && (
            <CLabel className="fade-in col-md-12" style={{ fontSize: '14px' }}>
              Region
            </CLabel>
          )}

          <CCol md="5">
            <CSelect onChange={province} onSelect={region} placeholder="Region">
              <option>Select Region</option>
              {regionData &&
                regionData.length > 0 &&
                regionData.map((item) => (
                  <option key={item.region_code} value={item.region_code}>
                    {item.region_name}
                  </option>
                ))}
            </CSelect>
          </CCol>
        </CFormGroup>
        <CFormGroup row style={{ marginTop: '20px' }}>
          {provinceAddr != '' && (
            <CLabel className="fade-in col-md-12" style={{ fontSize: '14px' }}>
              Province
            </CLabel>
          )}

          <CCol md="5">
            <CSelect onChange={city} disabled={regionAddr == ''} placeholder="Province">
              <option value="">Select Province</option>
              {provinceData &&
                provinceData.length > 0 &&
                provinceData.map((item) => (
                  <option key={item.province_code} value={item.province_code}>
                    {item.province_name}
                  </option>
                ))}
            </CSelect>
          </CCol>
        </CFormGroup>
        <CFormGroup row style={{ marginTop: '20px' }}>
          {cityAddr != '' && (
            <CLabel className="fade-in col-md-12" style={{ fontSize: '14px' }}>
              City/Municipality
            </CLabel>
          )}

          <CCol md="5">
            <CSelect onChange={barangay} disabled={provinceAddr == ''} placeholder="City/Municipality">
              <option value="">Select City</option>
              {cityData &&
                cityData.length > 0 &&
                cityData.map((item) => (
                  <option key={item.city_code} value={item.city_code}>
                    {item.city_name}
                  </option>
                ))}
            </CSelect>
          </CCol>
        </CFormGroup>
        <CFormGroup row style={{ marginTop: '20px' }}>
          {barangayAddr != '' && (
            <CLabel className="fade-in col-md-12" style={{ fontSize: '14px' }}>
              Barangay
            </CLabel>
          )}

          <CCol md="5">
            <CSelect onChange={brgy} disabled={cityAddr == ''} placeholder="Barangay">
              <option value="">Select Barangay</option>
              {barangayData &&
                barangayData.length > 0 &&
                barangayData.map((item) => (
                  <option key={item.brgy_code} value={item.brgy_code}>
                    {item.brgy_name}
                  </option>
                ))}
            </CSelect>
          </CCol>
        </CFormGroup>
        <CFormGroup row style={{ marginTop: '20px' }}>
          {projectlocation != '' && (
            <CLabel className="fade-in col-md-12" style={{ fontSize: '14px' }}>
              Street
            </CLabel>
          )}
          <CCol md="5">
            <CInput type="text" placeholder="Street Name" size="md" onChange={(e) => setStreetAddr(e.target.value)} value={streetAddr} className="input-design" />
          </CCol>
        </CFormGroup>
        <CFormGroup row style={{ marginTop: '20px' }}>
          {projectcategory != '' && (
            <CLabel className="fade-in col-md-12" style={{ fontSize: '14px' }}>
              Project Category
            </CLabel>
          )}
          <CCol md="5">
            <CSelect placeholder="Project Category" size="md" onChange={(e) => setProjectCategory(e.target.value)} value={projectcategory} className="input-design">
              <option disabled value="">
                Project Category
              </option>

              {categoryOptiom}
              {/* <option value="Government">Government</option> */}
            </CSelect>
          </CCol>
          {projectcategory == '' && (
            <span
              className="col-md-1"
              style={{
                color: '#B42A19',
                fontSize: '16px',
                fontWeight: '700',
              }}
            >
              *
            </span>
          )}
        </CFormGroup>
        <CFormGroup row style={{ marginTop: '20px' }}>
          {projectType != '' && (
            <CLabel className="fade-in col-md-12" style={{ fontSize: '14px' }}>
              Project Type
            </CLabel>
          )}
          <CCol md="5">
            <CSelect placeholder="Project Type" size="md" onChange={(e) => setProjectType(e.target.value)} value={projectType} className="input-design">
              <option disabled value="">
                Project Type
              </option>
              {option}
              {/* <option value="Government">Government</option> */}
            </CSelect>
          </CCol>
          {projectType == '' && (
            <span
              className="col-md-1"
              style={{
                color: '#B42A19',
                fontSize: '16px',
                fontWeight: '700',
              }}
            >
              *
            </span>
          )}
        </CFormGroup>
        <CFormGroup row style={{ marginTop: '20px' }}>
          {projectid != '' && (
            <CLabel className="fade-in col-md-12" style={{ fontSize: '14px' }}>
              Project ID
            </CLabel>
          )}
          <CCol md="5">
            <CInput
              type="text"
              placeholder="Project Id"
              size="md"
              // onChange={(e) => setProjectId(validateProjectId(projectid, e.target.value))}
              onChange={(e) => setProjectId(e.target.value)}
              value={projectid}
              className="input-design"
            />
          </CCol>
          {projectid == '' && (
            <span
              className="col-md-1"
              style={{
                color: '#B42A19',
                fontSize: '16px',
                fontWeight: '700',
              }}
            >
              *
            </span>
          )}
        </CFormGroup>

        <CFormGroup row style={{ marginTop: '20px' }}>
          {sourcefund != '' && (
            <CLabel className="fade-in col-md-12" style={{ fontSize: '14px' }}>
              Implementing Client:
            </CLabel>
          )}
          <CCol md="5">
            <CSelect value={sourcefund} onChange={(e) => setSourceFund(e.target.value)}>
              <option value="">Implementing Client</option>
              <option value="DPWH"> DPWH</option>
              <option value="Provincial Government">Provincial Government</option>
              <option value="Local Government Unit">Local Government Unit</option>
              <option value="Private">Private</option>
              <option value="Joint Venture">Joint Venture</option>
            </CSelect>

            {/* <CInput
              type="text"
              placeholder="Source of Funds"
              size="md"
              onChange={(e) => setSourceFund(e.target.value)}
              value={sourcefund}
              className="input-design"
            /> */}
          </CCol>
          {sourcefund == '' && (
            <span
              className="col-md-1"
              style={{
                color: '#B42A19',
                fontSize: '16px',
                fontWeight: '700',
              }}
            >
              *
            </span>
          )}
        </CFormGroup>
        <CFormGroup row style={{ marginTop: '20px' }}>
          {projectstart != '' && (
            <CLabel className="fade-in col-md-12" style={{ fontSize: '14px' }}>
              Project Start
            </CLabel>
          )}
          <CCol md="5">
            <DatePicker
              className="input-design form-control"
              placeholderText="Start Date"
              format="MM/dd/yyyy"
              value={projectstart}
              onChange={(value) => {
                const date = new Date(value);
                const month = String(date.getMonth() + 1).padStart(2, '0');
                const day = String(date.getDate()).padStart(2, '0');
                const year = date.getFullYear();
                const formattedValue = `${month}/${day}/${year}`;

                setProjectStart(formattedValue);
              }}
            />
          </CCol>
          {projectstart == '' && (
            <span
              className="col-md-1"
              style={{
                color: '#B42A19',
                fontSize: '16px',
                fontWeight: '700',
              }}
            >
              *
            </span>
          )}
        </CFormGroup>
        <CFormGroup row style={{ marginTop: '20px' }}>
          {nodays != '' && (
            <CLabel className="fade-in col-md-12" style={{ fontSize: '14px' }}>
              No of Days
            </CLabel>
          )}
          <CCol md="5">
            <CInput type="number" placeholder="No of Days" size="md" onChange={(e) => setNoDays(e.target.value)} className="input-design" value={nodays} />
          </CCol>
          {nodays == '' && (
            <span
              className="col-md-1"
              style={{
                color: '#B42A19',
                fontSize: '16px',
                fontWeight: '700',
              }}
            >
              *
            </span>
          )}
        </CFormGroup>
        <CFormGroup row style={{ marginTop: '20px' }}>
          {projectcost != '' && (
            <CLabel className="fade-in col-md-12" style={{ fontSize: '14px' }}>
              Project Cost
            </CLabel>
          )}
          <CCol md="5">
            <CInput type="number" placeholder="Project Cost" size="md" onChange={(e) => setProjectCost(e.target.value)} value={projectcost} className="input-design" />
          </CCol>
        </CFormGroup>
        <CFormGroup row style={{ marginTop: '20px' }}>
          {ocm != '' && (
            <CLabel className="fade-in col-md-12" style={{ fontSize: '14px' }}>
              OCM %
            </CLabel>
          )}
          <CCol md="5">
            <CInput type="number" placeholder="OCM %" size="md" value={ocm} onChange={(e) => setOcm(e.target.value)} className="input-design" />
          </CCol>
        </CFormGroup>
        <CFormGroup row style={{ marginTop: '20px' }}>
          {cp != '' && (
            <CLabel className="fade-in col-md-12" style={{ fontSize: '14px' }}>
              Contractor’s Profit %
            </CLabel>
          )}
          <CCol md="5">
            <CInput type="number" placeholder="Contractor’s Profit %" size="md" onChange={(e) => setCp(e.target.value)} className="input-design" value={cp} />
          </CCol>
        </CFormGroup>
        <CFormGroup row style={{ marginTop: '20px' }}>
          {hasVat != '' && (
            <CLabel className="fade-in col-md-12" style={{ fontSize: '14px' }}>
              VAT Type
            </CLabel>
          )}
          <CCol md="5">
            <CSelect value={hasVat} onChange={(e) => setHasVat(e.target.value)}>
              <option disabled value="">
                VAT Type
              </option>
              <option value="1">Vatable</option>
              <option value="0">Without VAT</option>
            </CSelect>
          </CCol>
        </CFormGroup>
      </CCol>

      <CCol sm="4" md="4" className="float-right" style={{ paddingBottom: '20px' }}>
        <CRow>
          <CCol md="6" sm="12">
            <CButton color="secondary_base" variant="outline" size="md" block onClick={cancel}>
              Cancel
            </CButton>
          </CCol>
          <CCol md="6" sm="12">
            <CButton
              color="secondary_base"
              size="md"
              block
              onClick={Save}
              disabled={
                !projectname ||
                !projectid ||
                !regionData ||
                !provinceData ||
                !cityData ||
                !barangayData ||
                !projectstart ||
                !nodays ||
                !projectcategory ||
                // (!ocm && sourcefund != "Private") ||
                // (!cp && sourcefund != "Private") ||
                // !hasVat ||
                !projectType
              }
            >
              Connect
            </CButton>
          </CCol>
        </CRow>
      </CCol>
    </>
  );
};

export default AddProject;
