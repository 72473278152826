import React, { lazy, useState, useEffect, useCallback } from 'react';
import { CButton, CCol, CRow, CFormGroup, CLabel, CInputRadio, CSelect, CPopover, CInput, CDropdown, CDropdownToggle, CDropdownMenu, CDropdownItem, CDropdownHeader, CDropdownDivider, CTooltip, CModal, CModalHeader, CModalTitle, CModalFooter, CForm, CBadge, CSpinner } from '@coreui/react';
import Select from 'react-select';
import 'react-circular-progressbar/dist/styles.css';
// import "./Calibrate.css";
import './ProjectDetails.css';
import data from '../../json/dupa';
import NumberFormat from 'react-number-format';
// import setTimeout from "core-js/fn/set-timeout";
import Loading from '../aimhi-widgets/Loading';
import $axios from 'src/utils/axios';

import IP from '../../StoreGlobal/IP';
import CreatableSelect from 'react-select/creatable';
import Index from '../projects/Index';
import { start } from '@popperjs/core';
import { Tree } from 'rsuite';
import { useSelector, useDispatch } from 'react-redux';
import BackupButton from '../aimhi-widgets/BackupButton';
import ai from '../../assets/img/ai.png';
import ai2 from '../../assets/img/ai_2.png';

import { FaRegQuestionCircle, FaPencilAlt, FaTrashAlt, FaArchive } from 'react-icons/fa';

import XLSX from 'xlsx';
import PrintEstimate from './PrintEstimate';
import { GetActualCost, GetEstimate, GetPhases, SearchSow } from 'src/request/Estimate';
import { GetManpowerPosition, AddPosition } from 'src/request/Manpower';
import RiskCalculator from '../aimhi-widgets/RiskCalculator';
import { size } from 'lodash';
import debounce from 'lodash/debounce';
import AIButton from '../aimhi-widgets/AIButton';
import dupa from '../../json/dupa';
import RecommenderPopUp from '../aimhi-widgets/RecommenderPopUp';
import { alignProperty } from '@mui/material/styles/cssUtils';
import EquipmentModal from '../aimhi-widgets/EquipmentModal';
import { DownloadReport } from 'src/request/Projects';
import { getFeatures } from 'src/request/Features';
import { findAllowedFeature } from 'src/utils/findAllowedFeature';
import { useLocation } from 'react-router-dom';
const empty_format = {
  0: '',
  remove_sow: false,
  new: true,
  actual_cp: 0,
  actual_ocm: 0,
  actual_total_contract_cost: 0,
  actual_total_direct_cost: 0,
  actual_total_indirect_cost: 0,
  actual_vat: 0,
  cp_balance: 0,
  equipment: [],
  material: [],
  estimated_cp: 0,
  estimated_ocm: 0,
  estimated_total_contract_cost: 0,
  estimated_total_direct_cost: 0,
  estimated_total_indirect_cost: 0,
  estimated_vat: 0,
  id: 0,
  labor: [],
  ocm_balance: 0,
  phase: '',
  phase_id: 0,
  psow_id: -1,
  quantity: 0,
  sow_id: -1,
  total_actual_equipment_cost: 0,
  total_actual_labor_cost: 0,
  total_actual_material_cost: 0,
  total_contract_cost_balance: 0,
  total_direct_cost_balance: 0,
  total_equipment_balance: 0,
  total_estimated_equipment_cost: 0,
  total_estimated_labor_cost: 0,
  total_estimated_material_cost: 0,
  total_indirect_cost_balance: 0,
  total_labor_balance: 0,
  total_material_balance: 0,
  vat_balance: 0,
  edit: false,
  area_or_volume: 0,
  area_or_volume_unit: 'sq.m',
  is_sub_con: 0,
  measurement: 0,
  unit_id: 7,
  isEmptyWork: true,
  isEmptyPhase: true,
  isEmptyMeasurement: true,
};

const empty_labor = {
  designation: '',
  estimated_workers: 0,
  hired_workers: 0,
  duration: '0 days',
  rate: 0,
  estimated_cost: 0,
  actual_cost: 0,
  balance: 0,
  id: 0,
  labor_id: -1,
  edit: true,
  new: true,
  is_sub_con: false,
  is_variance_order: false,
  calculator: 0,
};

const empty_equipment = {
  description: '',
  estimated_quantity: 0,
  ordered_quantity: 0,
  duration: '0 days',
  rate: 0,
  estimated_cost: 0,
  actual_cost: 0,
  balance: 0,
  id: 0,
  equipment_category_id: -1,
  edit: true,
  new: true,
  is_sub_con: false,
  is_variance_order: false,
  calculator: 0,
};

const empty_material = {
  description: '',
  estimated_quantity: 0,
  ordered_quantity: 0,
  unit: '',
  unit_cost: 0,
  estimated_cost: 0.0,
  actual_cost: 0,
  balance: 0,
  id: 0,
  material_id: -1,
  edit: true,
  new: true,
  is_sub_con: false,
  is_variance_order: false,
  calculator: 0,
  recommendations: {},
};

// variables used for setTimeout for debounce
let quantityTimer = null;
let unitCostTimer = null;

const recommendations = [
  {
    title: 'Based on your project requirements, this quantity is low. It is recommended to have 200 bags of Portland Cement',
    options: ['Change quantity to 200', 'Record new quantity for the project type', 'Do not change'],
  },
];

const recommendations1 = [
  {
    title: 'Php 1,800.00 unit cost for this item is high compared to the average.Would you like to re-calculate?',
    options: ['Change unit cost to Php1,500.00', 'Record new price for the material', 'Do not change'],
  },
];

let sample_data = [
  {
    0: 'Project Billboard',
    id: 803,
    psow_id: 803,
    sow_id: 2,
    phase: 'Pre-Construction',
    phase_id: 1,
    quantity: 0,
    labor: [
      {
        id: 594,
        labor_id: 56,
        designation: 'Watchman',
        estimated_workers: 2,
        duration: '2.0 days',
        rate: 250.0,
        estimated_cost: 1000.0,
        hired_workers: 1,
        actual_cost: 100,
        balance: 1000,
      },
    ],
    material: [
      {
        id: 3751,
        material_id: 3,
        description: '#2 Pvc Pipe',
        estimated_quantity: 1.0,
        unit: 'mm',
        unit_cost: 2.0,
        estimated_cost: 2.0,
        ordered_quantity: 1.0,
        actual_cost: 2.0,
        balance: 0.0,
      },
    ],
    equipment: [
      {
        id: 230,
        equipment_category_id: 123,
        description: 'DUMPTRUCK Mini Dump',
        estimated_quantity: 1.0,
        duration: '2.0 days',
        rate: 3.0,
        estimated_cost: 6.0,
        ordered_quantity: 0,
        actual_cost: 0.0,
        balance: 6.0,
      },
    ],
    total_estimated_labor_cost: 1000.0,
    total_actual_labor_cost: 0,
    total_labor_balance: 1000,
    total_estimated_equipment_cost: 6.0,
    total_actual_equipment_cost: 0,
    total_equipment_balance: 0,
    total_estimated_material_cost: 2.0,
    total_actual_material_cost: 2.0,
    total_material_balance: 0,
    estimated_total_direct_cost: 1008.0,
    actual_total_direct_cost: 1008.0,
    total_direct_cost_balance: 0,
    estimated_ocm: 0.1,
    actual_ocm: 0.1,
    ocm_balance: 0.1,
    estimated_vat: 100,
    actual_vat: 100,
    vat_balance: 100,
    estimated_cp: 200,
    actual_cp: 200,
    cp_balance: 200,
    estimated_total_indirect_cost: 5000,
    actual_total_indirect_cost: 5000,
    total_indirect_cost_balance: 5000,
    estimated_total_contract_cost: 1000,
    actual_total_contract_cost: 1000,
    total_contract_cost_balance: 1000,
  },
];

const ComponentUseCase3 = (props) => {
  const Swal = require('sweetalert2');
  const [selectedOption, setSelectedOption] = useState(null);

  console.log(props, 'yeah4');

  const recommendations = [
    {
      title: props.recommendations.title,
      options: [...props.recommendations.options, { text: 'Use Current Quantity', quantity: props.recommendations.original_quantity }],
    },
  ];

  const handleOptionChange = (value) => {
    setSelectedOption(value);
  };

  const handleSubmit = () => {
    // Handle submit logic here
    console.log('Submit button clicked! Selected Option:', selectedOption);
    props.quantityOnChange(recommendations[0]?.options[selectedOption]?.quantity, props.scopeIndex, props.Index, false);

    props.handleSubmit();
    props.handleClose();
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
      }}
    >
      {recommendations.map((recommendation, index) => (
        <div key={index}>
          <p style={{ fontSize: '12px', marginBottom: '10px', textAlign: 'center' }}>{recommendation.title}</p>
          <ul style={{ listStyleType: 'none', padding: 0 }}>
            {recommendation.options.map((option, optionIndex) => (
              <li key={optionIndex} style={{ marginBottom: '10px' }}>
                <label style={{ display: 'flex', alignItems: 'center' }}>
                  <input type="radio" name={`recommendation-${index}`} value={`option-${optionIndex + 1}`} checked={selectedOption === optionIndex} onChange={() => handleOptionChange(optionIndex)} />
                  <span style={{ marginLeft: '5px' }}>{option.text}</span>
                </label>
              </li>
            ))}
          </ul>
        </div>
      ))}
      <div>
        <CButton disabled={selectedOption === null} className="ml-auto" onClick={handleSubmit} style={{ backgroundColor: '#23303D', color: '#FFFFFF', marginRight: '10px', fontSize: '12px', marginBottom: '10px' }}>
          Apply
        </CButton>
      </div>
    </div>
  );
};

const ComponentUseCase2 = (props) => {
  const Swal = require('sweetalert2');
  const [selectedOption, setSelectedOption] = useState(null);

  const recommendations = [
    {
      title: props.recommendations.title,
      options: [...props.recommendations.options, { text: 'Use Current Unit Cost', price: props.recommendations.original_price }],
    },
  ];

  const handleOptionChange = (value) => {
    setSelectedOption(value);
  };

  const handleSubmit = () => {
    // Handle submit logic here
    console.log('Submit button clicked! Selected Option:', selectedOption);
    props.unitCostOnChange(recommendations[0]?.options[selectedOption]?.price, props.scopeIndex, props.Index, false);
    // if (selectedOption <= 2) {
    //   props.unitCostOnChange(recommendations[0]?.options[selectedOption]?.price, props.scopeIndex, props.Index);
    // } else {
    //   // //record
    //   // Swal.fire('Success', 'Succesfully recorded new quantity', 'success');

    //   // //ignore
    //   // Swal.fire({
    //   //   icon: 'question',
    //   //   title: 'Are you sure?',
    //   //   text: 'You want to ignore this recommendation?',
    //   //   showCancelButton: true,
    //   //   confirmButtonColor: '#23303D',
    //   //   cancelButtonColor: '#B42A19',
    //   //   confirmButtonText: 'Remove',
    //   // });
    // }
    props.handleSubmit(); // callback
    props.handleClose(); // callback
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
      }}
    >
      {recommendations.map((recommendation, index) => (
        <div key={index}>
          <p style={{ fontSize: '12px', marginBottom: '10px', textAlign: 'center' }}>{recommendation.title}</p>
          <ul style={{ listStyleType: 'none', padding: 0 }}>
            {recommendation.options.map((option, optionIndex) => (
              <li key={optionIndex} style={{ marginBottom: '10px' }}>
                <label style={{ display: 'flex', alignItems: 'center' }}>
                  <input type="radio" name={`recommendation-${index}`} value={`option-${optionIndex + 1}`} checked={selectedOption === optionIndex} onChange={() => handleOptionChange(optionIndex)} />
                  <span style={{ marginLeft: '5px' }}>{option.text}</span>
                </label>
              </li>
            ))}
          </ul>
        </div>
      ))}
      <div>
        <CButton disabled={selectedOption === null} className="ml-auto" onClick={handleSubmit} style={{ backgroundColor: '#23303D', color: '#FFFFFF', marginRight: '10px', fontSize: '12px', marginBottom: '10px' }}>
          Apply
        </CButton>
      </div>
    </div>
  );
};

const ProjectBudgetDetail = (props) => {
  const Swal = require('sweetalert2');
  const features = useSelector((state) => state.features);
  const company_id = parseInt(localStorage.getItem('company_id'));
  let accessToken = localStorage.getItem('token');
  let user_id = parseInt(localStorage.getItem('user_id'));
  let completeWorkItemList = null; // will have value when all work items are fetched from backend
  const customStyles = {
    control: (base, state) => ({
      ...base,

      background: '#FFFFFF',
      // Overwrittes the different states of border
      // borderColor: state.isFocused ? "yellow" : "green",
      // Removes weird border around container
      boxShadow: state.isFocused ? null : null,
      '&:hover': {
        // Overwrittes the different states of border
        // borderColor: state.isFocused ? "red" : "blue",
        borderColor: '#23303D',
      },
    }),

    options: (provided, state) => ({
      fontSize: '12px',
    }),

    // input: (base) => ({
    //   ...base,
    //   height: "10px",
    // }),
  };

  const [dupadata, setdupadata] = useState([]);
  const [backupData, setbackupData] = useState([]);
  const [isEdit, setisEdit] = useState(false);
  const [inputclass, setinputclass] = useState('input_outline');
  const [inputdisable, setinputdisable] = useState(true);
  const [buttonName, setbuttonName] = useState('Edit');
  const [togglescope, settogglescope] = useState([]);
  const [loading, setLoading] = useState(true);
  const [show, setshow] = useState(true);
  const [grantTotal, setgrantTotal] = useState({});
  const [errorhandling, seterrorHandling] = useState(true);
  const [sowOptions, setSowOption] = useState({}); // format: { phase_id : [array of work item objects]}
  const [sowSearchOptions, setSowSearchOptions] = useState([]);
  const [listLabor, setlistLabor] = useState([]);
  const [listEquipment, setlistEquipment] = useState([]);
  const [listMaterial, setlistMaterial] = useState([]);
  const [laborValue, setlaborValue] = useState([]);
  const [butttonTransition, setbuttonTransition] = useState(false);
  const [newId, setnewId] = useState(0);
  const [phssenewId, setphasenewId] = useState(0);
  const [estimateStatus, setestimateStatus] = useState(1);
  const [printEstimate, setPrintEstimate] = useState(false);
  const [hasVAT, setHasVAT] = useState(0);
  const [phases, setPhases] = useState([]);
  const [editSow, setEditSow] = useState([]);
  const [dateUpdated, setDateUpdated] = useState('');
  const [updatedBy, setUpdatedBy] = useState('');
  const [search, setSearch] = useState('');
  let permission = useSelector((state) => state.permission);
  const [showOptions, setShowOptions] = useState([]);
  const [isCalculatorOpen, setCalculatorOpen] = useState(false); //state to open calculator
  const [calculatorSow, setCalculatorSow] = useState(''); //state for Sow calculator
  const [calculatorTitle, setCalculatorTitle] = useState(''); //state for calculator title
  const [calculatorWorkItem, setCalculatorWorkItem] = useState(''); //state for calculator work item
  const [dataAvailable, setDataAvailable] = useState(false); //state for dataAvailable
  const [sowIndex, setSowIndex] = useState(0);
  const [index, setIndex] = useState(0);
  const [materialName, setMaterialName] = useState('');
  const [laborProductivity, setLaborProductivity] = useState([]);
  const [equipmentProductivity, setEquipmentProductivity] = useState([]);
  const [materialQuantity, setMaterialQuantity] = useState([]);
  const [isSowAdded, setIsSowAdded] = useState(false);
  // const [showAIButton, setShowAIButton] = useState(false);
  const [modalVarianceOrder, setModalVarianceOrder] = useState(false);
  const [varianceOrderData, setVarianceOrderData] = useState([]);
  const [showRecommender2, setShowRecommender2] = useState(false);
  const [showRecommender3, setShowRecommender3] = useState(false);
  const [activeRecommenderIndex2, setActiveRecommenderIndex2] = useState({});
  const [activeRecommenderIndex3, setActiveRecommenderIndex3] = useState({});
  const [modalCategory, setModalCategory] = useState(false);
  const [equipmentName, setEquipmentName] = useState('');
  const [category, setCategory] = useState('');
  const [units, setUnits] = useState({});
  const [position, setPosition] = useState('');
  const [positionDescription, setPositionDescription] = useState('');
  const [modalPosition, setModalPosition] = useState(false);
  const [laborID, setLaborID] = useState(999);
  const [equipmentID, setEquipmentID] = useState(999);
  const [materialID, setMaterialID] = useState(999);
  const [isLoading, setIsLoading] = useState(false);
  const [isDownloadableReportAllowed, setIsDownloadableReportAllowed] = useState(false);
  const [isEstimateRecoToolAllowed, setIsEstimateRecoToolAllowed] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const sow_id = queryParams.get('sow_id');
  const psow_id = queryParams.get('psow_id');
  console.log('begin sow_id:', sow_id, 'psow_id:', psow_id);

  useEffect(() => {
    // setdupadata(val.location.state.data);
    // setbackupData(val.location.state.data);
    // setdupadata(data);
    // setbackupData(data);
    getdetailed(props.project_id);
    getmanpower(props.project_id);
    getequipment(props.project_id);
    getmaterial(props.project_id);

    // update sowOptions -- get all scope of work
    if (!(-1 in sowOptions)) {
      console.log('getsowlist props.project_id', props.project_id);
      getsowlist(props.project_id, ['UPDATE_SOW_OPTION'], null);
    }
    // getsowlist(props.project_id, ['UPDATE_SOW_SEARCH_OPTION']); // update sowSearchOptions -- get project specific scope of work

    setestimateStatus(props.estimate_status);
    // getPhases();
    getStandardPhases();
    getUnits();
    // console.log(props.estimate_status, "hello estimate");
    start();
  }, []);

  useEffect(() => {
    console.log('feature changed', features);
    if (features.length > 0) {
      setIsDownloadableReportAllowed(findAllowedFeature(features, 5));
      setIsEstimateRecoToolAllowed(findAllowedFeature(features, 6));
    }
  }, [features]);

  {
    /* HIDDEN TEMPORARY - JOHN */
  }
  // if laborProductivity is updated setDataAvailable to true to show the AIButton.
  useEffect(() => {
    if (laborProductivity && laborProductivity.length > 0 && laborProductivity[0].productivity !== null) {
      // setDataAvailable(true);
      setLaborProductivityAvailable({ scopeIndex: sowIndex, Index: index, fromToggle: 0 });
    }
  }, [laborProductivity]);

  useEffect(() => {
    if (equipmentProductivity && equipmentProductivity.length > 0 && equipmentProductivity[0].productivity !== null) {
      // setDataAvailable(true);
      setEquipmentProductivityAvailable({ scopeIndex: sowIndex, Index: index, fromToggle: 0 });
    }
  }, [equipmentProductivity]);

  useEffect(() => {
    if (materialQuantity && materialQuantity.length > 0) {
      // setDataAvailable(true);
      setMaterialQuantityAvailable({ scopeIndex: sowIndex, Index: index, fromToggle: 0 });
    }
  }, [materialQuantity]);

  useEffect(() => {
    let activeIndex2 = { ...activeRecommenderIndex2 };
    let activeIndex3 = { ...activeRecommenderIndex3 };

    for (let i = 0; i < dupadata.length; i++) {
      for (let j = 0; j < dupadata[i].material.length; j++) {
        let has_price_rec = dupadata[i].material[j].has_price_rec;
        let has_quantity_rec = dupadata[i].material[j].has_quantity_rec;
        let recommendations = dupadata[i].material[j].recommendations;

        // Use Case #2
        if (!(i in activeIndex2)) {
          activeIndex2[i] = {};
        }

        if (!(j in activeIndex2[i])) {
          activeIndex2[i][j] = {
            isOpen: has_price_rec == 1,
            isLoading: has_price_rec == 1 && !('price' in recommendations),
          };

          if ('price' in recommendations) {
            activeIndex2[i][j] = { ...activeIndex2[i][j], ...recommendations.price };
          }
        }

        // Use Case #3
        if (!(i in activeIndex3)) {
          activeIndex3[i] = {};
        }

        if (!(j in activeIndex3[i])) {
          activeIndex3[i][j] = {
            isOpen: has_quantity_rec == 1,
            isLoading: has_quantity_rec == 1 && !('quantity' in recommendations),
          };

          if ('quantity' in recommendations) {
            activeIndex3[i][j] = { ...activeIndex3[i][j], ...recommendations.quantity };
          }
        }
      }
    }

    // initialize value
    setActiveRecommenderIndex2(activeIndex2);
    setActiveRecommenderIndex3(activeIndex3);
  }, [dupadata]);

  useEffect(() => {
    if (sow_id && psow_id) {
      const identifier = `#sow_id-${sow_id}-psow_id-${psow_id}`;
      scrollToElement(identifier);
    }
  }, [sow_id, psow_id, dupadata]);

  const setLaborProductivityAvailable = ({ scopeIndex, Index, fromToggle, noData }) => {
    let newArr = [...dupadata];

    if (newArr[scopeIndex] && newArr[scopeIndex].labor) {
      if (fromToggle === 1 || laborProductivity[0]?.from_backup === 1) {
        newArr[scopeIndex].labor[Index].dataAvailable = null;
        if (noData !== 1) {
          newArr[scopeIndex].labor[Index].show_calculator = 0;
        }
        // newArr[scopeIndex].labor[Index].show_calculator = 0;
      } else if (fromToggle === 0) {
        newArr[scopeIndex].labor[Index].dataAvailable = true;
      } else {
        console.log('error undefined');
      }
    }

    setdupadata(newArr);
  };

  const setEquipmentProductivityAvailable = ({ scopeIndex, Index, fromToggle, noData }) => {
    let newArr = [...dupadata];

    if (newArr[scopeIndex] && newArr[scopeIndex].equipment) {
      if (fromToggle === 1 || equipmentProductivity[0]?.from_backup) {
        newArr[scopeIndex].equipment[Index].dataAvailable = null;
        newArr[scopeIndex].equipment[Index].show_calculator = 0;
      } else if (fromToggle === 0) {
        newArr[scopeIndex].equipment[Index].dataAvailable = true;
      } else {
        console.log('error undefined');
      }
    }

    setdupadata(newArr);
  };

  const setMaterialQuantityAvailable = ({ scopeIndex, Index, fromToggle, noData }) => {
    let newArr = [...dupadata];
    if (newArr[scopeIndex] && newArr[scopeIndex].material) {
      if (fromToggle === 1 || materialQuantity[0]?.from_backup || noData === 1) {
        if (newArr[scopeIndex].material[Index]) {
          newArr[scopeIndex].material[Index].dataAvailable = null;
        }
      } else if (fromToggle === 0) {
        if (newArr[scopeIndex].material[Index]) {
          newArr[scopeIndex].material[Index].dataAvailable = true;
        }
      } else {
        console.log('error undefined');
      }
    }

    setdupadata(newArr);
  };

  const toggleCalculator = (props) => {
    if (props.isClosed) {
      setCalculatorOpen(false);
    } else if (props.isApply) {
      setCalculatorOpen(false);

      if (calculatorTitle === 'Labor') {
        setLaborProductivityAvailable({ scopeIndex: sowIndex, Index: index, fromToggle: 1 });
      } else if (calculatorTitle === 'Equipment') {
        setEquipmentProductivityAvailable({ scopeIndex: sowIndex, Index: index, fromToggle: 1 });
      } else if (calculatorTitle === 'Material') {
        setMaterialQuantityAvailable({ scopeIndex: sowIndex, Index: index, fromToggle: 1 });
      }
    } else {
      setSowIndex(props.scopeIndex);
      setIndex(props.Index);
      if (props.title) {
        setCalculatorTitle(props.title);
        setCalculatorSow(dupadata[props.scopeIndex]?.phase);
        setCalculatorWorkItem(dupadata[props.scopeIndex][0]);
      } else {
        setCalculatorTitle(props.history_title ? props.history_title : calculatorTitle);
        setCalculatorSow(dupadata[props.scopeIndex]?.phase);
        setCalculatorWorkItem(dupadata[props.scopeIndex][0]);
      }
      setCalculatorOpen(true);
    }
  };
  {
    /* HIDDEN TEMPORARY - JOHN */
  }

  const updateIsSowAdded = (value) => {
    setIsSowAdded(value);
  };

  const toggleRecommenderPopUp = (component_id, value = null) => {
    if (component_id === 2) {
      setShowRecommender2(value ? value : !showRecommender2);
    } else if (component_id === 3) {
      setShowRecommender3(value ? value : !showRecommender3);
      // setShowRecommender3(!showRecommender3);
    }
  };

  const hideRecommenderPopup = (scopeIndex, Index, type) => {
    let activeIndex = null;
    let modifier = null;

    if (type == 'price') {
      activeIndex = { ...activeRecommenderIndex2 };
      modifier = setActiveRecommenderIndex2;
    } else {
      activeIndex = { ...activeRecommenderIndex3 };
      modifier = setActiveRecommenderIndex3;
    }

    if (Object.keys(activeIndex[scopeIndex]).length > 1) {
      delete activeIndex[scopeIndex][Index];
    } else {
      delete activeIndex[scopeIndex];
    }

    modifier(activeIndex);
  };
  const updateSowList = (phaseId) => {
    if (!(phaseId in sowOptions)) {
      getsowlist(props.project_id, ['UPDATE_SOW_OPTION'], phaseId);
    }
  };

  const AddSow = () => {
    let newarray = [...dupadata];

    let newdata = JSON.stringify(empty_format);
    newarray.unshift(JSON.parse(newdata));

    // let new_data_first = newarray.reverse();
    // setdupadata(new_data_first);

    // update sowOptions -- get all scope of work
    if (!(-1 in sowOptions)) {
      getsowlist(props.project_id, ['UPDATE_SOW_OPTION'], null);
    }
    setdupadata(newarray);
    setbuttonTransition(true);
    setIsSowAdded(true);
  };

  const EditSow = (id) => {
    let arr = [...dupadata];

    let Index = arr.findIndex((a) => a.id == id);
    arr[Index]['edit'] = true;
    arr[Index].edit = true;

    // updateSowList(arr[Index].phase_id);
    updateSowList(arr[Index].phase_category_id);
    setdupadata(arr);
  };

  const RemoveSow = (id) => {
    Swal.fire({
      icon: 'question',
      title: 'Are you sure?',
      text: 'You want to remove this scope of work!',
      showCancelButton: true,
      confirmButtonColor: '#23303D',
      cancelButtonColor: '#B42A19',
      confirmButtonText: 'Remove',
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        let arr = [...dupadata];

        let Index = arr.findIndex((a) => a.id == id);
        arr[Index]['remove_sow'] = true;
        // arr[Index]['edit'] = true;

        setdupadata(arr);
      } else if (result.isDenied) {
      }
    });
  };

  const AddNew = (Index, data) => {
    let newarr = [...dupadata];
    let calculatorDetailData = [];

    if (data == 'Labor') {
      let newlabor = JSON.stringify(empty_labor);
      newarr[Index].labor.push(JSON.parse(newlabor));
      newarr[Index].title = 'Labor';
      setCalculatorTitle('Labor');
    } else if (data == 'Equipment') {
      let newEquipment = JSON.stringify(empty_equipment);
      newarr[Index].equipment.push(JSON.parse(newEquipment));
      newarr[Index].title = 'Equipment';
      setCalculatorTitle('Equipment');
    } else if (data == 'Material') {
      let newMaterial = JSON.stringify(empty_material);
      newarr[Index].material.push(JSON.parse(newMaterial));
      newarr[Index].title = 'Material';
      setCalculatorTitle('Material');
    }
    setdupadata(newarr);
    setCalculatorSow(newarr[Index].phase);
    setCalculatorWorkItem(newarr[Index][0]);
  };

  const sowonchange = (opt, meta, scopeIndex) => {
    let newdata = [...dupadata];
    newdata[scopeIndex][0] = opt.label;
    newdata[scopeIndex].sow_id = opt.value;
    newdata[scopeIndex].isEmptyWork = false;

    // get details of phase
    const findPhase = phases.find((phase) => phase.value == opt.phase_id);

    // same process as phaseonchange()
    newdata[scopeIndex]['phase'] = findPhase.label;
    newdata[scopeIndex]['phase_id'] = opt.phase_id;
    newdata[scopeIndex].isEmptyPhase = false;

    setdupadata(newdata);
  };

  const createOptionsow = (opt, meta, scopeIndex) => {
    let newarr = [...sowOptions];
    let a = newId;
    newarr.push({
      value: --a,
      label: opt,
    });

    setSowOption(newarr);

    let newdata = [...dupadata];
    newdata[scopeIndex][0] = opt;
    newdata[scopeIndex].sow_id = a--;
    setnewId(a--);

    setdupadata(newdata);
  };

  const phaseonchange = (opt, menubar, scopeIndex) => {
    let newdata = [...dupadata];
    newdata[scopeIndex]['phase'] = opt.label;
    newdata[scopeIndex]['phase_id'] = opt.value;
    newdata[scopeIndex]['phase_category_id'] = opt.phase_category_id;
    newdata[scopeIndex].isEmptyPhase = false;

    // same process as sowonchange
    newdata[scopeIndex][0] = ''; // set work item description to empty
    newdata[scopeIndex].sow_id = -1; // set work item id to -1 (empty default)
    newdata[scopeIndex].isEmptyWork = true;

    updateSowList(opt.phase_category_id);
    setdupadata(newdata);
  };

  const createOptionphase = (opt, meta, scopeIndex) => {
    // alert(opt);
    let newarr = [...phases];
    let a = newId;
    newarr.push({
      value: --a,
      label: opt,
    });

    setPhases(newarr);

    let newdata = [...dupadata];
    newdata[scopeIndex]['phase'] = opt;
    newdata[scopeIndex]['phase_id'] = a--;
    setnewId(a--);

    setdupadata(newdata);
  };

  const LaborTotalOnchange = (e, Index) => {
    let newdata = [...dupadata];

    newdata[Index]['total_actual_labor_cost'] = e.target.value;

    console.log(newdata);

    setdupadata(newdata);
  };

  const EquipmentTotalChange = (e, Index) => {
    let newdata = [...dupadata];

    newdata[Index]['total_actual_equipment_cost'] = e.target.value;

    setdupadata(newdata);
  };

  const setLaborProductivityForCalculator = (laborProductivityArray) => {
    setLaborProductivity(laborProductivityArray);
  };

  const setEquipmentProductivityForCalculator = (equipmentProductivityArray) => {
    setEquipmentProductivity(equipmentProductivityArray);
  };

  const setMaterialQuantityForCalculator = (materialQuantityArray) => {
    setMaterialQuantity(materialQuantityArray);
  };

  const LaborOnchange = (opt, meta, scopeIndex, Index) => {
    let newdata = [...dupadata];
    let laborId = laborID;
    let area_value = newdata[scopeIndex].measurement;
    newdata[scopeIndex].labor[Index].designation = opt.label;
    newdata[scopeIndex].labor[Index].labor_id = opt.value;
    newdata[scopeIndex].labor[Index].calculator = 0;
    newdata[scopeIndex].labor[Index].id !== 0 ? (newdata[scopeIndex].labor[Index].id = newdata[scopeIndex].labor[Index].id) : (newdata[scopeIndex].labor[Index].id = laborId);
    newdata[scopeIndex]['edit'] = true;
    if (opt.label && opt.value) {
      newdata[scopeIndex].labor[Index].isEmpty = false;
    }
    if (newdata[scopeIndex].is_sub_con === 1) {
      newdata[scopeIndex].labor[Index].is_sub_con = true;
    } else {
      newdata[scopeIndex].labor[Index].is_sub_con = false;
    }
    setdupadata(newdata);
    setLaborID(laborId++);
    {
      /* HIDDEN TEMPORARY - JOHN */
    }
    const requestData = {
      type: 1,
      phase_id: newdata[scopeIndex].phase_id,
      sow_id: newdata[scopeIndex].sow_id,
      project_id: props.project_id,
      type_id: opt.value,
      unit_area_id: newdata[scopeIndex].unit_id < 0 ? 7 : newdata[scopeIndex].unit_id,
    };

    if (isEstimateRecoToolAllowed) {
      newdata[scopeIndex].labor[Index].show_calculator = 1;
      $axios
        .post(IP + 'calculator/detail', requestData, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
          },
        })
        .then((response) => {
          if (response.data.response == 'No data') {
            let area_unit_id = newdata[scopeIndex].unit_id < 0 ? 7 : newdata[scopeIndex].unit_id;
            let productivity_history = response.data.history;
            console.log('No Productivity Data');
            setLaborProductivityAvailable({ scopeIndex: scopeIndex, Index: Index, noData: 1 });
            toggleCalculator({ isClosed: true });
            let laborProductivityArray = [
              {
                productivity: productivity_history.length > 0 ? productivity_history[0] : null,
                productivity_unit: null,
                area_value: area_value,
                area_unit: null,
                area_unit_id: area_unit_id || 7,
                time_unit: null,
                time_unit_id: null,
                phase_id: newdata[scopeIndex].phase_id,
                sow_id: newdata[scopeIndex].sow_id,
                type_id: opt?.value,
                no_productivity: 1,
                productivity_history: productivity_history,
              },
            ];
            setLaborProductivity(laborProductivityArray);
          } else {
            let productivity = response.data.response.productivity;
            let productivity_unit = `${response.data.response.unit_area_abbreviation} / ${response.data.response.unit_time_abbreviation === 'h' ? 'hr' : 'day'}`;
            let area_unit = response.data.response.unit_area_abbreviation;
            let area_unit_id = response.data.response.unit_area_id;
            let time_unit = response.data.response.unit_time_abbreviation;
            let time_unit_id = response.data.response.unit_time_id;
            let productivity_history = response.data.response.history;
            let laborProductivityArray = [
              {
                productivity: productivity,
                productivity_unit: productivity_unit,
                area_value: area_value,
                area_unit: area_unit,
                area_unit_id: area_unit_id,
                time_unit: time_unit,
                time_unit_id: time_unit_id,
                phase_id: newdata[scopeIndex].phase_id,
                sow_id: newdata[scopeIndex].sow_id,
                type_id: opt.value,
                productivity_history: productivity_history,
              },
            ];

            setLaborProductivityForCalculator(laborProductivityArray);
          }
        })
        .catch((error) => {
          // Handle errors
          console.error('Error making request:', error);
        });
    }
    {
      /* HIDDEN TEMPORARY - JOHN */
    }

    setIndex(Index);
    setSowIndex(scopeIndex);

    // setSowIndex(scopeIndex + 1);
  };

  const createOption = (opt, meta, scopeIndex, Index) => {
    let newarr = [...listLabor];
    let a = newId;
    newarr.push({
      value: a--,
      label: opt,
    });

    setlistLabor(newarr);

    let newdata = [...dupadata];
    newdata[scopeIndex].labor[Index].designation = opt;
    newdata[scopeIndex].labor[Index].labor_id = a--;
    newdata[scopeIndex]['edit'] = true;
    if (opt) {
      newdata[scopeIndex].labor[Index].isEmpty = false;
    }
    setnewId(a--);
    setdupadata(newdata);
    // for position modal
    setPosition(opt);
    setModalPosition(!modalPosition);
  };

  const durationOnchange = (val, scopeIndex, Index, fromCalculator) => {
    if (fromCalculator === 1) {
      let newArr = [...dupadata];
      newArr[scopeIndex].labor[Index].duration = val;
      newArr[scopeIndex]['edit'] = true;
      // newArr[scopeIndex].labor[Index].calculator = 1;

      setdupadata(newArr);
    } else {
      let newArr = [...dupadata];
      newArr[scopeIndex].labor[Index].duration = val ? val : 0;
      newArr[scopeIndex].labor[Index].calculator = 0;
      newArr[scopeIndex]['edit'] = true;

      setdupadata(newArr);
    }
  };

  const LaborHiredWorkers = (val, scopeIndex, Index) => {
    let newArr = [...dupadata];
    newArr[scopeIndex].labor[Index].hired_workers = val ? parseInt(val) : 0;
    newArr[scopeIndex]['edit'] = true;

    setdupadata(newArr);
  };

  const LaborActualCost = (val, scopeIndex, Index) => {
    let newArr = [...dupadata];
    newArr[scopeIndex].labor[Index].actual_cost = val ? parseInt(val) : 0;
    newArr[scopeIndex].labor[Index]['actual_cost_edited'] = true;
    newArr[scopeIndex]['edit'] = true;
    setdupadata(newArr);
  };

  const EquipmentHiredWorkers = (val, scopeIndex, Index) => {
    let newArr = [...dupadata];
    newArr[scopeIndex].equipment[Index].ordered_quantity = val ? parseFloat(val) : 0;
    newArr[scopeIndex]['edit'] = true;

    setdupadata(newArr);
  };

  const EquipmentActualCost = (val, scopeIndex, Index) => {
    let newArr = [...dupadata];
    newArr[scopeIndex].equipment[Index].actual_cost = val ? parseFloat(val) : 0;
    newArr[scopeIndex].equipment[Index]['actual_cost_edited'] = true;
    newArr[scopeIndex]['edit'] = true;

    setdupadata(newArr);
  };

  const MaterialHiredWorkers = (val, scopeIndex, Index) => {
    let newArr = [...dupadata];
    newArr[scopeIndex].material[Index].ordered_quantity = val ? parseFloat(val) : 0;
    newArr[scopeIndex]['edit'] = true;

    setdupadata(newArr);
  };

  const EquipmentCalculatorDetail = (scopeIndex, opt) => {
    const newarr = [...dupadata];
    let area_value = newarr[scopeIndex].measurement;
    let area_unit_id = newarr[scopeIndex].unit_id < 0 ? 7 : newarr[scopeIndex].unit_id;
    const requestData = {
      type: 2,
      phase_id: newarr[scopeIndex].phase_id,
      sow_id: newarr[scopeIndex].sow_id,
      project_id: props.project_id,
      type_id: opt?.value,
      unit_area_id: newarr[scopeIndex].unit_id,
    };

    $axios
      .post(IP + 'calculator/detail', requestData, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        if (response.data.response == 'No data') {
          let productivity_history = response.data.history;
          console.log('No Productivity Data');
          setEquipmentProductivityAvailable({ scopeIndex: sowIndex, Index: index, noData: 1 });
          toggleCalculator({ isClosed: true });
          let equipmentProductivityArray = [
            {
              productivity: productivity_history?.length > 0 ? productivity_history[0] : null,
              productivity_unit: null,
              area_value: area_value,
              area_unit: null,
              area_unit_id: area_unit_id,
              time_unit: null,
              time_unit_id: null,
              phase_id: newarr[scopeIndex].phase_id,
              sow_id: newarr[scopeIndex].sow_id,
              type_id: opt?.value,
              no_productivity: 1,
              productivity_history: productivity_history,
            },
          ];
          setEquipmentProductivity(equipmentProductivityArray);
        } else {
          let productivity = response.data.response.productivity;
          let productivity_unit = `${response.data.response.unit_area_abbreviation} / ${response.data.response.unit_time_abbreviation === 'h' ? 'hr' : 'day'}`;
          let area_unit = response.data.response.unit_area_abbreviation;
          let area_unit_id = response.data.response.unit_area_id;
          let time_unit = response.data.response.unit_time_abbreviation;
          let time_unit_id = response.data.response.unit_time_id;
          let productivity_history = response.data.response.history;
          let equipmentProductivityArray = [
            {
              productivity: productivity,
              productivity_unit: productivity_unit,
              area_value: area_value,
              area_unit: area_unit,
              area_unit_id: area_unit_id,
              time_unit: time_unit,
              time_unit_id: time_unit_id,
              phase_id: newarr[scopeIndex].phase_id,
              sow_id: newarr[scopeIndex].sow_id,
              type_id: opt?.value,
              productivity_history: productivity_history,
            },
          ];

          setEquipmentProductivity(equipmentProductivityArray);
        }
      })
      .catch((error) => {
        // Handle errors
        console.error('Error making request:', error);
      });
  };

  const EquipmentOnchange = (opt, meta, scopeIndex, Index) => {
    const newarr = [...dupadata];
    let equipmentId = equipmentID;

    newarr[scopeIndex].equipment[Index].description = opt.label;
    newarr[scopeIndex].equipment[Index].equipment_category_id = opt.value;
    newarr[scopeIndex].equipment[Index].calculator = 0;
    newarr[scopeIndex].equipment[Index].id !== 0 ? (newarr[scopeIndex].equipment[Index].id = newarr[scopeIndex].equipment[Index].id) : (newarr[scopeIndex].equipment[Index].id = equipmentId);
    newarr[scopeIndex]['edit'] = true;
    if (opt.label && opt.value) {
      newarr[scopeIndex].equipment[Index].isEmpty = false;
    }

    if (newarr[scopeIndex].is_sub_con === 1) {
      newarr[scopeIndex].equipment[Index].is_sub_con = true;
    } else {
      newarr[scopeIndex].equipment[Index].is_sub_con = false;
    }

    setdupadata(newarr);
    setEquipmentID(equipmentId++);
    {
      /* HIDDEN TEMPORARY - JOHN */
    }
    // const requestData = {
    //   type: 2,
    //   phase_id: newarr[scopeIndex].phase_id,
    //   sow_id: newarr[scopeIndex].sow_id,
    //   project_id: null,
    //   type_id: null,
    // };

    // $axios
    //   .post(IP + 'calculator/detail', requestData, {
    //     headers: {
    //       Authorization: `Bearer ${accessToken}`,
    //       'Content-Type': 'application/json',
    //     },
    //   })
    //   .then((response) => {
    //     if (response.data.response == 'No data') {
    //       console.log('No Productivity Data');
    //       setEquipmentProductivityAvailable({ scopeIndex: sowIndex, Index: index, noData: 1 });
    //       toggleCalculator({ isClosed: true });
    //     } else {
    //       console.log('response detail equipment:', response.data);
    //       let productivity = response.data.response.productivity;
    //       let productivity_unit = `${response.data.response.unit_area_abbreviation} / ${response.data.response.unit_time_abbreviation === 'h' ? 'hr' : 'day'}`;
    //       let area_unit = response.data.response.unit_area_abbreviation;
    //       let area_unit_id = response.data.response.unit_area_id;
    //       let time_unit = response.data.response.unit_time_abbreviation;
    //       let time_unit_id = response.data.response.unit_time_id;
    //       let equipmentProductivityArray = [
    //         { productivity: productivity, productivity_unit: productivity_unit, area_unit: area_unit, area_unit_id: area_unit_id, time_unit: time_unit, time_unit_id: time_unit_id, phase_id: newarr[scopeIndex].phase_id, sow_id: newarr[scopeIndex].sow_id, type_id: opt.value },
    //       ];

    //       setEquipmentProductivity(equipmentProductivityArray);
    //     }
    //   })
    //   .catch((error) => {
    //     // Handle errors
    //     console.error('Error making request:', error);
    //   });
    // {
    /* HIDDEN TEMPORARY - JOHN */

    if (isEstimateRecoToolAllowed) {
      newarr[scopeIndex].equipment[Index].show_calculator = 1;
      EquipmentCalculatorDetail(scopeIndex, opt);
    }

    setIndex(Index);
    setSowIndex(scopeIndex);
    // setDataAvailable(true);
  };

  const showEquipmentModal = (e, scopeIndex, Index) => {
    let newarr = [...listEquipment];
    console.log('showEquipmentModal e', e);
    let a = newId;
    newarr.push({
      value: a--,
      label: e,
    });

    let newdata = [...dupadata];
    newdata[scopeIndex].equipment[Index].description = e;
    newdata[scopeIndex].equipment[Index].equipment_category_id = a--;
    newdata[scopeIndex]['edit'] = true;
    setnewId(a--);

    setdupadata(newdata);

    // setEquipmentName(e);
    setCategory(e);
    setIndex(Index);
    setSowIndex(scopeIndex);
    setModalCategory(!modalCategory);
  };

  const createOptionEquipment = (opt, meta, scopeIndex, Index) => {
    let newarr = [...listEquipment];
    let a = newId;
    newarr.push({
      value: a--,
      label: opt,
    });

    setlistEquipment(newarr);

    let newdata = [...dupadata];
    newdata[scopeIndex].equipment[Index].description = opt;
    newdata[scopeIndex].equipment[Index].equipment_category_id = a--;
    newdata[scopeIndex]['edit'] = true;
    setnewId(a--);

    setdupadata(newdata);
    setIndex(Index);
    // setDataAvailable(true);
  };

  const qtyonchange = (val, scopeIndex, Index) => {
    let newArr = [...dupadata];
    newArr[scopeIndex].equipment[Index].estimated_quantity = val ? parseInt(val) : 0;
    newArr[scopeIndex]['edit'] = true;
    setdupadata(newArr);
  };

  const eqdurationonchange = (val, scopeIndex, Index) => {
    let newArr = [...dupadata];
    newArr[scopeIndex].equipment[Index].duration = val ? val : 0;
    newArr[scopeIndex]['edit'] = true;
    setdupadata(newArr);
  };

  const eqrateonchange = (val, scopeIndex, Index) => {
    let newArr = [...dupadata];
    newArr[scopeIndex].equipment[Index].rate = val ? parseFloat(val) : 0;
    newArr[scopeIndex]['edit'] = true;
    setdupadata(newArr);
  };

  const MaterialOnchange = (opt, meta, scopeIndex, Index) => {
    const newarr = [...dupadata];
    let materialId = materialID;
    let area_value = newarr[scopeIndex].measurement;

    newarr[scopeIndex].material[Index].description = opt.label;
    newarr[scopeIndex].material[Index].material_id = opt.value;
    newarr[scopeIndex].material[Index].calculator = 0;
    newarr[scopeIndex].material[Index].id !== 0 ? (newarr[scopeIndex].material[Index].id = newarr[scopeIndex].material[Index].id) : (newarr[scopeIndex].material[Index].id = materialId);
    newarr[scopeIndex]['edit'] = true;
    newarr[scopeIndex].material[Index].has_price_rec = null;
    newarr[scopeIndex].material[Index].has_quantity_rec = null;
    newarr[scopeIndex].material[Index].recommendations = {};

    if (opt.label && opt.value) {
      newarr[scopeIndex].material[Index].isEmpty = false;
    }

    if (newarr[scopeIndex].is_sub_con === 1) {
      newarr[scopeIndex].material[Index].is_sub_con = true;
    } else {
      newarr[scopeIndex].material[Index].is_sub_con = false;
    }

    const requestData = {
      type: 3,
      phase_id: newarr[scopeIndex].phase_id,
      sow_id: newarr[scopeIndex].sow_id,
      project_id: props.project_id,
      type_id: newarr[scopeIndex].material[Index].material_id,
    };

    if (isEstimateRecoToolAllowed) {
      $axios
        .post(IP + 'calculator/detail', requestData, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
          },
        })
        .then((response) => {
          if (response.data.response == 'No data') {
            console.log('No Productivity Data');
            setMaterialQuantityAvailable({ scopeIndex: scopeIndex, Index: Index, noData: 1 });
            toggleCalculator({ isClosed: true });
          } else {
            let data = [response.data.data];
            let input = [response.data.input];
            let materialArray = [{ data: data, input: input, type_id: newarr[scopeIndex].material[Index].material_id, phase_id: newarr[scopeIndex].phase_id, sow_id: newarr[scopeIndex].sow_id, area_value: area_value }];
            setMaterialQuantityForCalculator(materialArray);
          }
        })
        .catch((error) => {
          // Handle errors
          console.error('Error making request:', error);
        });
    }
    setdupadata(newarr);
    setMaterialName(opt);
    setIndex(Index);
    setSowIndex(scopeIndex);
    setMaterialID(materialId++);
    // setDataAvailable(true);

    // // Use Case #2
    // if (scopeIndex in activeRecommenderIndex2 && Index in activeRecommenderIndex2[scopeIndex]) {
    //   let materialId = newarr[scopeIndex].material[Index].material_id;
    //   let material = newarr[scopeIndex].material[Index].description;
    //   let price = newarr[scopeIndex].material[Index].unit_cost;
    //   let pm_id = newarr[scopeIndex].material[Index].id;
    //   hasRecommendedMaterialPrice(scopeIndex, Index, pm_id, materialId, material, price);
    // }

    // // Use Case #3
    // if (scopeIndex in activeRecommenderIndex3 && Index in activeRecommenderIndex3[scopeIndex]) {
    //   let phaseId = newarr[scopeIndex].phase_id;
    //   let sowId = newarr[scopeIndex].sow_id;
    //   let materialId = newarr[scopeIndex].material[Index].material_id;
    //   hasRecommendedMaterialQuantity(phaseId, sowId, materialId, scopeIndex, Index);
    // }
  };

  const createOptionMaterial = (opt, meta, scopeIndex, Index) => {
    let newarr = [...listMaterial];
    let a = newId;
    newarr.push({
      value: -1,
      label: opt,
    });

    setlistMaterial(newarr);

    let newdata = [...dupadata];
    newdata[scopeIndex].material[Index].description = opt;
    newdata[scopeIndex].material[Index].material_id = -1;
    newdata[scopeIndex]['edit'] = true;
    if (opt) {
      newdata[scopeIndex].material[Index].isEmpty = false;
    }
    setMaterialName(opt);
    setnewId(a--);
    setdupadata(newdata);
    // setDataAvailable(true);
  };

  const matquantityonchange = (val, scopeIndex, Index, recommend = true) => {
    clearTimeout(quantityTimer);

    let newdata = [...dupadata];
    newdata[scopeIndex].material[Index].estimated_quantity = val ? parseFloat(val) : 0;
    newdata[scopeIndex].material[Index]['edit'] = true;
    newdata[scopeIndex]['edit'] = true;

    // check if calculator is 1
    if (newdata[scopeIndex].material[Index].calculator == 1) {
      newdata[scopeIndex].material[Index].has_quantity_rec = 0;
      delete newdata[scopeIndex].material[Index].recommendations['quantity'];
      recommend = false;
    }

    if (recommend && isEstimateRecoToolAllowed) {
      // debounce
      quantityTimer = setTimeout(() => {
        let phaseId = newdata[scopeIndex].phase_id;
        let sowId = newdata[scopeIndex].sow_id;
        let materialId = newdata[scopeIndex].material[Index].material_id;
        hasRecommendedMaterialQuantity(phaseId, sowId, materialId, scopeIndex, Index);
      }, 1000);
    }

    setdupadata(newdata);
  };

  const matunitonchange = (val, scopeIndex, Index) => {
    let newdata = [...dupadata];
    newdata[scopeIndex].material[Index].unit = val;
    newdata[scopeIndex]['edit'] = true;
    setdupadata(newdata);

    // if (newdata[scopeIndex].material[Index].description != '' && newdata[scopeIndex].material[Index].estimated_quantity != 0 && newdata[scopeIndex].material[Index].unit != '') {
    //   setShowRecommender2(true);
    // }
  };

  // NOTE: trigger recommendation if recommend=true
  const matunitoncostchange = (val, scopeIndex, Index, recommend = true) => {
    clearTimeout(unitCostTimer);

    let newdata = [...dupadata];
    newdata[scopeIndex].material[Index].unit_cost = val ? parseFloat(val) : 0;
    newdata[scopeIndex]['edit'] = true;
    newdata[scopeIndex].material[Index]['edit'] = true;

    if (recommend && isEstimateRecoToolAllowed) {
      // debounce
      unitCostTimer = setTimeout(() => {
        let materialId = newdata[scopeIndex].material[Index].material_id;
        let material = newdata[scopeIndex].material[Index].description;
        let price = newdata[scopeIndex].material[Index].unit_cost;
        let pm_id = newdata[scopeIndex].material[Index].id;
        hasRecommendedMaterialPrice(scopeIndex, Index, pm_id, materialId, material, price);
      }, 1000);
    }

    setdupadata(newdata);
  };

  const editLabor = (scopeIndex, Index) => {
    let newArr = [...dupadata];

    newArr[scopeIndex].labor[Index].edit = true;
    // newArr[Index].title = 'Labor';
    newArr[scopeIndex].title = 'Labor';

    setdupadata(newArr);
    setCalculatorTitle('Labor');
  };

  const editEquipment = (scopeIndex, Index) => {
    let newArr = [...dupadata];

    newArr[scopeIndex].equipment[Index].edit = true;
    // newArr[Index].title = 'Equipment';
    newArr[scopeIndex].title = 'Equipment';

    setdupadata(newArr);
    setCalculatorTitle('Equipment');
  };

  const editMaterial = (scopeIndex, Index) => {
    let newArr = [...dupadata];

    newArr[scopeIndex].material[Index].edit = true;
    if (newArr[Index]) {
      // newArr[Index].title = 'Material';
      newArr[scopeIndex].title = 'Material';
    }

    // // Use Case #2
    // if (scopeIndex in activeRecommenderIndex2 && Index in activeRecommenderIndex2[scopeIndex]) {
    //   let materialId = newArr[scopeIndex].material[Index].material_id;
    //   let material = newArr[scopeIndex].material[Index].description;
    //   let price = newArr[scopeIndex].material[Index].unit_cost;
    //   let pm_id = newArr[scopeIndex].material[Index].id;
    //   hasRecommendedMaterialPrice(scopeIndex, Index, pm_id, materialId, material, price);
    // }

    // // Use Case #3
    // if (scopeIndex in activeRecommenderIndex3 && Index in activeRecommenderIndex3[scopeIndex]) {
    //   let phaseId = newArr[scopeIndex].phase_id;
    //   let sowId = newArr[scopeIndex].sow_id;
    //   let materialId = newArr[scopeIndex].material[Index].material_id;
    //   hasRecommendedMaterialQuantity(phaseId, sowId, materialId, scopeIndex, Index);
    // }

    setdupadata(newArr);
    setCalculatorTitle('Material');
  };

  const editLaborTotal = (Index) => {
    let newArr = [...dupadata];
    newArr[Index]['edit_labor_cost'] = true;
    newArr[Index]['edit'] = true;
    setdupadata(newArr);
  };

  const editEquipmentTotal = (Index) => {
    let newArr = [...dupadata];
    newArr[Index]['edit_equipment_cost'] = true;
    newArr[Index]['edit'] = true;
    setdupadata(newArr);
  };

  const getPhases = () => {
    GetPhases().then(function (res) {
      if (res.data.status == 1) {
        console.log(res.data.response.data, 'phases');
        setPhases(res.data.response.data);
      }
      console.log(res);
    });
  };

  const getStandardPhases = () => {
    // $axios.get(`phase/?limit=-1&standard=1`).then((res) => {
    //   if (res.data.status == 1) {
    //     console.log(res.data.response.data, 'phases');
    //     setPhases(res.data.response.data);
    //   }
    // });

    $axios.get(`phase/v2/?limit=25&standard=1&project_id=${props.project_id}`).then((res) => {
      if (res.data.status == 1) {
        console.log(res.data.response.data, 'phases');
        setPhases(res.data.response.data);
      }
    });
  };

  const getdetailed = (project_id) => {
    GetEstimate(project_id)
      .then((res) => {
        console.log(res, 'dupa data');
        if (res.data.response.detailed.length != 1) {
          // let newarr = res.data.response.detailed;
          // let newData = res.data.response.detailed;
          // console.log(res.data.response.detailed, "dupa");
          // let data = newarr.pop();

          setgrantTotal(res.data.response.detailed.grand_total);
          setestimateStatus(res.data.response.estimate_status);
          setdupadata(res.data.response.detailed.items);
          // setdupadata(sample_data);
          setbuttonTransition(false);
          setHasVAT(res.data.response.has_vat);
          setDateUpdated(res.data.response.date_updated);
          setUpdatedBy(res.data.response.updated_by);
          console.log(res.data.response, 'data estimate');
          const ocm_percentage = res.data.response.detailed.grand_total.ocm_percentage;
          const cp_percenteage = res.data.response.detailed.grand_total.cp_percenteage;
          const grand_total = res.data.response.detailed.grand_total;
          getActualCostComputation(project_id, res.data.response.detailed.items, ocm_percentage, cp_percenteage, res.data.response.has_vat, grand_total);
        }

        seterrorHandling(false);
        setLoading(false);
      })
      .catch((error) => {
        console.log('projectdetails/ProjectBudgetDetails.js', ' ', 'line 433', error);
      });
  };

  const updateDupaWithActualCost = async (dupadata, projectId, ocm_percentage, cp_percentage, has_vat, grand_total) => {
    const response = await GetActualCost(projectId);
    const actualCostData = response.data;
    const updatedDupadata = [...dupadata];
    const updatedGrandTotal = { ...grand_total };
    console.log('dupadata', dupadata);
    updatedDupadata.forEach((work_item, index) => {
      const actualLaborItems = actualCostData.data.breakdowns;

      // Find the corresponding actualLaborItem for the current work_item
      const actualLaborItem = actualLaborItems.find((item) => item.sow_id === work_item.sow_id);

      if (actualLaborItem) {
        work_item.labor.forEach((labor) => {
          // Find the corresponding labor in actualLaborItem
          const actualLabor = actualLaborItem?.labor?.filter((al) => al.labor_id === labor.labor_id);

          if (actualLabor) {
            const actualCost = actualLaborItem?.labor?.filter((al) => al.labor_id === labor.labor_id).reduce((sum, al) => sum + al.days_rendered * al.rate_per_day, 0);
            const labor_actual_cost = (labor.actual_cost || 0) + actualCost;

            labor.hired_workers = actualLabor.length;
            labor.actual_cost = labor_actual_cost;
            labor.balance = labor.estimated_cost - labor_actual_cost;
          }
        });
        work_item.equipment.forEach((equipment) => {
          // Find the corresponding equipment in actualLaborItem
          const actualEquipment = actualLaborItem?.equipment?.filter((ae) => ae.equipment_id === equipment.equipment_category_id);

          if (actualEquipment) {
            const actualCost = actualLaborItem?.equipment?.filter((ae) => ae.equipment_id === equipment.equipment_category_id).reduce((sum, ae) => sum + ae.days_rendered * ae.rate_per_day, 0);
            // const equipment_actual_cost = (equipment.actual_cost || 0) + actualCost;
            const equipment_actual_cost = equipment.is_auto_compute ? actualCost : (equipment.actual_cost || 0) + actualCost;

            equipment.ordered_quantity = actualEquipment.length;
            equipment.actual_cost = equipment_actual_cost;
            equipment.balance = equipment.estimated_cost - equipment_actual_cost;
          }
        });
        work_item.material.forEach((material) => {
          let utilized_qty = 0;
          const actualMaterial = actualLaborItem?.material?.filter((am) => am.material_id === material.material_id);

          if (actualMaterial) {
            if (actualMaterial.length > 0) {
              utilized_qty = actualMaterial[0].utilized_qty;
            }
            const actualCost = actualLaborItem.material.filter((am) => am.material_id === material.material_id).reduce((sum, am) => sum + am.utilized_qty * am.unit_cost, 0);
            // const material_actual_cost = material.is_auto_compute ? actualCost : (material.actual_cost || 0) + actualCost;
            const material_actual_cost = actualCost;

            // material.ordered_quantity = material?.ordered_quantity + utilized_qty;
            material.ordered_quantity = utilized_qty;
            material.actual_cost = material_actual_cost;
            material.balance = material.estimated_cost - material_actual_cost;
          }
        });

        // labor computation
        const total_actual_labor_cost = work_item.labor.reduce((sum, labor) => sum + (labor.actual_cost || 0), 0);
        const total_labor_balance = work_item?.total_estimated_labor_cost - total_actual_labor_cost;

        // equipment computation
        const total_actual_equipment_cost = work_item.equipment.reduce((sum, equipment) => sum + (equipment.actual_cost || 0), 0);
        const total_equipment_balance = work_item?.total_estimated_equipment_cost - total_actual_equipment_cost;

        // material computation
        const total_actual_material_cost = work_item.material.reduce((sum, material) => sum + (material.actual_cost || 0), 0);
        const total_material_balance = work_item?.total_estimated_material_cost - total_actual_material_cost;

        const total_direct_cost_balance = total_labor_balance + total_equipment_balance + total_material_balance;
        const actual_total_direct_cost = total_actual_labor_cost + total_actual_equipment_cost + total_actual_material_cost;
        const actual_ocm = actual_total_direct_cost * (ocm_percentage / 100);
        const actual_cp = actual_total_direct_cost * (cp_percentage / 100);
        const actual_vat = has_vat !== 0 ? actual_total_direct_cost * (12 / 100) : 0;
        const ocm_balance = total_direct_cost_balance * (ocm_percentage / 100);
        const cp_balance = total_direct_cost_balance * (cp_percentage / 100);
        const vat_balance = has_vat !== 0 ? total_direct_cost_balance * (12 / 100) : 0;
        const actual_total_indirect_cost = actual_ocm + actual_cp + actual_vat;
        const total_indirect_cost_balance = ocm_balance + cp_balance + vat_balance;

        const updatedLaborItem = {
          ...work_item,
          total_actual_labor_cost: total_actual_labor_cost,
          total_labor_balance: total_labor_balance,
          total_actual_equipment_cost: total_actual_equipment_cost,
          total_equipment_balance: total_equipment_balance,
          total_actual_material_cost: total_actual_material_cost,
          total_material_balance: total_material_balance,
          actual_total_direct_cost: actual_total_direct_cost,
          total_direct_cost_balance: total_direct_cost_balance,
          actual_ocm: actual_ocm,
          ocm_balance: ocm_balance,
          actual_cp: actual_cp,
          cp_balance: cp_balance,
          actual_vat: actual_vat,
          vat_balance: vat_balance,
          actual_total_indirect_cost: actual_total_indirect_cost,
          total_indirect_cost_balance: total_indirect_cost_balance,
          actual_total_contract_cost: actual_total_direct_cost + actual_total_indirect_cost,
          total_contract_cost_balance: total_direct_cost_balance + total_indirect_cost_balance,
        };

        // Update the work_item in updatedDupadata
        updatedDupadata[index] = updatedLaborItem;
      }
    });
    const totalActualLaborCost = updatedDupadata.reduce((sum, item) => sum + (item.total_actual_labor_cost || 0), 0);
    const totalActualEquipmentCost = updatedDupadata.reduce((sum, item) => sum + (item.total_actual_equipment_cost || 0), 0);
    const totalActualMaterialCost = updatedDupadata.reduce((sum, item) => sum + (item.total_actual_material_cost || 0), 0);
    const totalActualDirectCost = updatedDupadata.reduce((sum, item) => sum + (item.actual_total_direct_cost || 0), 0);
    const actualCP = updatedDupadata.reduce((sum, item) => sum + (item.actual_cp || 0), 0);
    const actualOCM = updatedDupadata.reduce((sum, item) => sum + (item.actual_ocm || 0), 0);
    const actualVAT = updatedDupadata.reduce((sum, item) => sum + (item.actual_vat || 0), 0);
    const totalIndirectCost = updatedDupadata.reduce((sum, item) => sum + (item.actual_total_indirect_cost || 0), 0);
    const totalContractCost = updatedDupadata.reduce((sum, item) => sum + (item.actual_total_contract_cost || 0), 0);
    const totalLaborBalance = updatedDupadata.reduce((sum, item) => sum + (item.total_labor_balance || 0), 0);
    const totalEquipmentBalance = updatedDupadata.reduce((sum, item) => sum + (item.total_equipment_balance || 0), 0);
    const totalMaterialBalance = updatedDupadata.reduce((sum, item) => sum + (item.total_material_balance || 0), 0);
    const totalDirectCostBalance = updatedDupadata.reduce((sum, item) => sum + (item.total_direct_cost_balance || 0), 0);
    const balanceCP = updatedDupadata.reduce((sum, item) => sum + (item.cp_balance || 0), 0);
    const balanceOCM = updatedDupadata.reduce((sum, item) => sum + (item.ocm_balance || 0), 0);
    const balanceVAT = updatedDupadata.reduce((sum, item) => sum + (item.vat_balance || 0), 0);
    const totalIndirectCostBalance = updatedDupadata.reduce((sum, item) => sum + (item.total_indirect_cost_balance || 0), 0);
    const totalContractCostBalance = updatedDupadata.reduce((sum, item) => sum + (item.total_contract_cost_balance || 0), 0);

    const updatedGrandTotalData = {
      ...updatedGrandTotal,
      total_actual_labor_cost: totalActualLaborCost,
      total_actual_equipment_cost: totalActualEquipmentCost,
      total_actual_material_cost: totalActualMaterialCost,
      actual_total_direct_cost: totalActualDirectCost,
      actual_cp: actualCP,
      actual_ocm: actualOCM,
      actual_vat: actualVAT,
      total_actual_indirect_cost: totalIndirectCost,
      total_actual_contract_cost: totalContractCost,
      total_labor_balance: totalLaborBalance,
      total_equipment_balance: totalEquipmentBalance,
      total_material_balance: totalMaterialBalance,
      total_direct_cost_balance: totalDirectCostBalance,
      cp_balance: balanceCP,
      ocm_balance: balanceOCM,
      vat_balance: balanceVAT,
      total_indirect_cost_balance: totalIndirectCostBalance,
      total_contract_cost_balance: totalContractCostBalance,
    };

    return { updatedDupadata, updatedGrandTotalData };
  };

  const handleUpdateDupadata = async (projectId, dupa, ocm_percentage, cp_percentage, has_vat, grand_total) => {
    try {
      setLoading(true);
      const { updatedDupadata, updatedGrandTotalData } = await updateDupaWithActualCost(dupa, projectId, ocm_percentage, cp_percentage, has_vat, grand_total);
      setdupadata(updatedDupadata);
      setgrantTotal(updatedGrandTotalData);
      setLoading(false);
    } catch (error) {
      console.error('Error updating dupadata:', error);
      setLoading(false);
    }
  };

  const getActualCostComputation = (project_id, dupa, ocm_percentage, cp_percentage, has_vat, grand_total) => {
    handleUpdateDupadata(project_id, dupa, ocm_percentage, cp_percentage, has_vat, grand_total);
  };

  const searchSow = async (e) => {
    let arr = [...sowSearchOptions];
    let search = e;

    let sow_filter = arr.filter((a) => a.label.toLowerCase() == search.toLowerCase());
    console.log('SEARCH ', search.toLowerCase());
    let sow_id = sow_filter.length != 0 ? sow_filter[0].value : '';
    setSearchLoading(true);
    await SearchSow(data, props.project_id, sow_id, search.toLowerCase())
      .then((res) => {
        console.log(res);
        if (res.data.response.detailed.length != 1) {
          setgrantTotal(res.data.response.detailed.grand_total);
          setestimateStatus(res.data.response.estimate_status);
          setdupadata(res.data.response.detailed.items);
          setbuttonTransition(false);
          setHasVAT(res.data.response.has_vat);
          setDateUpdated(res.data.response.date_updated);
          setUpdatedBy(res.data.response.updated_by);
          setSearchLoading(false);
          console.log(res.data.response, 'data estimate');
        }

        seterrorHandling(false);
        setLoading(false);
      })
      .catch((error) => {
        console.log('projectdetails/ProjectBudgetDetails.js', ' ', 'line 433', error);
      });
  };

  const debouncedSearchSow = debounce(searchSow, 300);

  const hasRecommendedMaterialQuantity = (phaseId, sowId, materialId, scopeIndex, index) => {
    const requestData = {
      type: 3,
      phase_id: phaseId,
      sow_id: sowId,
      project_id: props.project_id,
      type_id: materialId,
    };

    $axios({
      method: 'post',
      url: IP + 'calculator/detail',
      data: requestData,
      headers: {
        'content-type': 'application/vnd.myapp.type+json',
        Authorization: `Bearer ${accessToken}`,
      },
    }).then((res) => {
      if (res.status == 200) {
        console.log('trigger-', res);

        // for new items in Estimate
        if (dupadata[scopeIndex].material[index].id == 0) {
          let newData = [...dupadata];
          newData[scopeIndex].material[index].has_quantity_rec = parseInt(res.data.response == 'No Data');
          setdupadata(newData);
        }

        if (res.data) {
          let activeIndex = { ...activeRecommenderIndex3 };

          if (!(scopeIndex in activeIndex)) {
            activeIndex[scopeIndex] = {};
          }

          if (!(index in activeIndex[scopeIndex])) {
            activeIndex[scopeIndex][index] = { isOpen: true, isLoading: true };
          }

          setActiveRecommenderIndex3(activeIndex);
          setShowRecommender3(true);
          // markRecommendedItem('quantity', scopeIndex, index, 1)
          getRecommendedMaterialQuantity(scopeIndex, index);
        } else {
          setShowRecommender3(false);
        }
      }
    });
  };

  const getRecommendedMaterialQuantity = (scopeIndex, index) => {
    let params = {
      phase_id: dupadata[scopeIndex].phase_id,
      sow_id: dupadata[scopeIndex].sow_id,
      area: parseFloat(dupadata[scopeIndex].measurement),
      unit_id: dupadata[scopeIndex].unit_id,
      material_id: dupadata[scopeIndex].material[index].material_id,
      material_desc: dupadata[scopeIndex].material[index].description,
      pm_id: dupadata[scopeIndex].material[index].id,
      quantity: dupadata[scopeIndex].material[index].estimated_quantity,
      project_id: props.project_id,
    };

    $axios({
      method: 'post',
      url: IP + 'material/get-quantity-recommendation',
      data: params,
      headers: {
        'content-type': 'application/vnd.myapp.type+json',
        Authorization: `Bearer ${accessToken}`,
      },
    }).then((res) => {
      if (res.status == 200) {
        if (res.data.response?.options.length > 0) {
          let newData = [...dupadata];
          let activeIndex = { ...activeRecommenderIndex3 };

          if (!(scopeIndex in activeIndex)) {
            activeIndex[scopeIndex] = {};
          }

          newData[scopeIndex].material[index].recommendations['quantity'] = res.data.response;
          activeIndex[scopeIndex][index] = { isOpen: true, isLoading: false, ...res.data.response };
          setActiveRecommenderIndex3(activeIndex);
          setShowRecommender3(true);
          markRecommendedItem('quantity', scopeIndex, index, 1);
        } else {
          let newData = [...dupadata];
          let recommendations = newData[scopeIndex].material[index].recommendations;
          newData[scopeIndex].material[index].has_quantity_rec = null;

          if ('quantity' in recommendations) {
            delete newData[scopeIndex].material[index].recommendations['quantity'];
          }
          setdupadata(newData);
          setShowRecommender3(false);
        }
      }
    });
  };

  const markRecommendedItem = (recommendationType, scopeIndex, Index, flagValue) => {
    const newData = [...dupadata];

    if (recommendationType == 'price') {
      newData[scopeIndex].material[Index].has_price_rec = flagValue;
      setdupadata(newData);
    } else if (recommendationType == 'quantity') {
      newData[scopeIndex].material[Index].has_quantity_rec = flagValue;
      setdupadata(newData);
    }

    // axios({
    //   method: 'post',
    //   url: IP + 'material/mark-recommended-item',
    //   data: {
    //     type: recommendationType,
    //     pm_id: dupadata[scopeIndex].material[Index].id,
    //     value: flagValue
    //   },
    //   headers: {
    //     'content-type': 'application/vnd.myapp.type+json',
    //     Authorization: `Bearer ${accessToken}`,
    //   }
    // }).catch((error) => {
    //   Swal.fire({
    //     icon: 'error',
    //     title: 'Error',
    //     text: 'Oops! Something went wrong',
    //     confirmButtonColor: '#23303D',
    //   });
    // })
  };

  // NOTE: flagValue is used to set has_price_rec in database
  // NOTE: flagValue=1 should only be triggered on unit price edit
  const hasRecommendedMaterialPrice = (scopeIndex, Index, pm_id, material_id, material, price) => {
    $axios({
      method: 'post',
      url: IP + 'material/has-price-recommendation',
      data: { material, material_id, price, pm_id },
      headers: {
        'content-type': 'application/vnd.myapp.type+json',
        Authorization: `Bearer ${accessToken}`,
      },
    }).then((res) => {
      if (res.status == 200) {
        if (res.data.response?.options.length > 0) {
          let newData = [...dupadata];

          // for new items in Estimate
          if (dupadata[scopeIndex].material[Index].id == 0) {
            newData[scopeIndex].material[Index].has_price_rec = 1;
          }

          let activeIndex = { ...activeRecommenderIndex2 };

          if (!(scopeIndex in activeIndex)) {
            activeIndex[scopeIndex] = {};
          }

          activeIndex[scopeIndex][Index] = {
            isOpen: true,
            isLoading: false,
            ...res.data.response,
          };

          newData[scopeIndex].material[Index].recommendations['price'] = res.data.response;
          setdupadata(newData);
          setActiveRecommenderIndex2(activeIndex);
          setShowRecommender2(true);
          markRecommendedItem('price', scopeIndex, Index, 1);
        } else {
          let newData = [...dupadata];
          let recommendations = newData[scopeIndex].material[Index].recommendations;
          newData[scopeIndex].material[Index].has_price_rec = null;

          if ('price' in recommendations) {
            delete newData[scopeIndex].material[Index].recommendations['price'];
          }

          setdupadata(newData);
          setShowRecommender2(false);
        }
      }
    });
  };

  const getUnits = () => {
    $axios({
      method: 'get',
      url: IP + 'estimate/units',
      headers: {
        'content-type': 'application/vnd.myapp.type+json',
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((res) => {
        if (res.status == 200) {
          setUnits(res.data.response);
        } else {
          Swal.fire({
            icon: 'error',
            title: res.data.response,
            text: res.data.description,
            confirmButtonColor: '#23303D',
          });
        }
      })
      .catch((error) => {
        Swal.fire({
          icon: 'error',
          title: error.response.data.message,
          text: error.response.data.response,
          confirmButtonColor: '#23303D',
        });
      });
  };

  const getmanpower = () => {
    GetManpowerPosition(false)
      .then((res) => {
        if (res.status == 200) {
          setlistLabor(res.data.response.data);
        }
      })
      .catch((error) => {
        console.log('projectdetails/ProjectBudgetDetails.js', ' ', 'line 450', error);
      });
  };

  useEffect(() => {
    console.log(sowOptions, 'sowOptions');
  }, [sowOptions]);

  const getequipment = (project_id) => {
    // axios
    //   .post(IP + 'equipment/category', {
    //     // project_id, not sure if this will be used
    //     company_id: company_id,
    //     headers: {
    //       'Content-Type': 'application/json',
    //       Authorization: `Bearer ${accessToken}`,
    //     },
    //   })
    //   .then((res) => {
    //     setlistEquipment(res.data.response);
    //   })
    //   .catch((error) => {
    //     console.log('projectdetails/ProjectBudgetDetails.js', ' ', 'line 467', error);
    //   });

    let accessToken = localStorage.getItem('token');
    const company_id = parseInt(localStorage.getItem('company_id'));
    // temporary fix to get equipment drop-down
    $axios
      .get('equipment/category/list', {
        // project_id, not sure if this will be used
        company_id: company_id,
      })
      .then((res) => {
        setlistEquipment(res.data.response);
      })
      .catch((error) => {
        console.log('projectdetails/ProjectBudgetDetails.js', ' ', 'line 467', error);
      });
    // temporary fix to get equipment drop-down
  };

  const getmaterial = (project_id) => {
    let company_id = parseInt(localStorage.getItem('company_id'));
    console.log(company_id, 'getmaterialzxzx');
    $axios
      .get(`estimate/material-list/${company_id}`)
      .then((res) => {
        if (res.data.status == 200) {
          setlistMaterial(res.data.response);
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: res.data.message,
            confirmButtonColor: '#23303D',
          });
        }
      })
      .catch((error) => {
        console.log('projectdetails/ProjectBudgetDetails.js', ' ', 'line 483', error);
      });

    // axios
    //   .post(IP + 'material/list/all', {
    //     project_id,
    //     headers: {
    //       'Content-Type': 'application/json',
    //       Authorization: `Bearer ${accessToken}`,
    //     },
    //   })
    //   .then((res) => {
    //     setlistMaterial(res.data.response);
    //   })
    //   .catch((error) => {
    //     console.log('projectdetails/ProjectBudgetDetails.js', ' ', 'line 483', error);
    //   });
  };

  const debouncedFetchOptions = useCallback(
    debounce((input, scope) => {
      if (input) {
        getsowlist(props.project_id, ['UPDATE_SOW_OPTION'], scope?.phase_category_id, input);
      } else {
        // get all work items
        getsowlist(props.project_id, ['UPDATE_SOW_OPTION'], scope?.phase_category_id);
      }
    }, 500),
    [],
  );

  // const getsowlist = (project_id = null, method = ['UPDATE_SOW_OPTION'], phase_id = null, sowInput = null) => {
  //   // if need to fetch all work items but completeWorkItemList is not null
  //   // No need to fetch all list from backend
  //   if (!project_id && !phase_id && !sowInput && completeWorkItemList) {
  //     let sowOptionNew = { ...sowOptions };
  //     sowOptionNew[-1] = completeWorkItemList;
  //     setSowOption(sowOptionNew);
  //   } else {
  //     let url = 'sow/list?standard=1&categorized=1';
  //     let company_id = parseInt(localStorage.getItem('company_id'));

  //     if (project_id) {
  //       url = `sow/list?project=${project_id}`;
  //     }

  //     if (phase_id) {
  //       url = `${url}&phase=${phase_id}`;
  //     }

  //     if (sowInput) {
  //       url = `${url}&sow=${sowInput}`;
  //     }

  //     $axios
  //       .get(url, {
  //         company_id: company_id,
  //       })
  //       .then((res) => {
  //         if (method.includes('UPDATE_SOW_OPTION') && res.data.response?.length > 0) {
  //           let sowOptionNew = { ...sowOptions };

  //           // if getting all work items and completeWorkItemList is empty
  //           if (!project_id && !phase_id && !sowInput && !completeWorkItemList) {
  //             completeWorkItemList = res.data.response;
  //           }

  //           if (!phase_id) {
  //             // no assigned phase yet. Assign to phase id -1 temporarily
  //             sowOptionNew[-1] = res.data.response;
  //           } else {
  //             sowOptionNew[phase_id] = res.data.response;
  //           }

  //           setSowOption(sowOptionNew);
  //         }

  //         if (method.includes('UPDATE_SOW_SEARCH_OPTION')) {
  //           setSowSearchOptions(res.data.response);
  //         }
  //       })
  //       .catch((error) => {
  //         console.log('projectdetails/ProjectBudgetDetails.js', ' ', 'line 499', error);
  //       });
  //   }
  // };

  const getsowlist = (project_id = null, method = ['UPDATE_SOW_OPTION'], phase_id = null, sowInput = null) => {
    // if need to fetch all work items but completeWorkItemList is not null
    // No need to fetch all list from backend
    if (!project_id && !phase_id && !sowInput && completeWorkItemList) {
      let sowOptionNew = { ...sowOptions };
      sowOptionNew[-1] = completeWorkItemList;
      setSowOption(sowOptionNew);
    } else {
      let url = `phase/v2/sowlist/?project_id=${project_id}&standard=1&phase_category_id=${phase_id}&sowInput=`;
      let company_id = parseInt(localStorage.getItem('company_id'));

      // if (project_id) {
      //   url = `phase/v2/sowlist/?project_id=${project_id}`;
      // }

      // if (phase_id) {
      //   url = `${url}&phase_category_id=${phase_id}`;
      // }

      if (sowInput) {
        url = `phase/v2/sowlist/?project_id=${project_id}&standard=1&phase_category_id=${phase_id}&sowInput=${sowInput}`;
      }

      $axios
        .get(url, {
          company_id: company_id,
        })
        .then((res) => {
          if (method.includes('UPDATE_SOW_OPTION') && res.data.response?.length > 0) {
            let sowOptionNew = { ...sowOptions };

            // if getting all work items and completeWorkItemList is empty
            if (!project_id && !phase_id && !sowInput && !completeWorkItemList) {
              completeWorkItemList = res.data.response;
            }

            if (!phase_id) {
              // no assigned phase yet. Assign to phase id -1 temporarily
              sowOptionNew[-1] = res.data.response;
            } else {
              sowOptionNew[phase_id] = res.data.response;
            }
            setSowOption(sowOptionNew);
          }

          if (method.includes('UPDATE_SOW_SEARCH_OPTION')) {
            setSowSearchOptions(res.data.response);
          }
        })
        .catch((error) => {
          console.log('projectdetails/ProjectBudgetDetails.js', ' ', 'line 499', error);
        });
    }
  };

  const toggleShow = (value) => {
    const shownState = togglescope.slice();
    const index = shownState.indexOf(value);

    if (index >= 0) {
      shownState.splice(index, 1);
      settogglescope(shownState);
    } else {
      shownState.push(value);
      settogglescope(shownState);
    }
  };

  const EstWorkers = (val, scopeIndex, Index, fromCalculator) => {
    if (fromCalculator === 1) {
      let newArr = [...dupadata];
      newArr[scopeIndex].labor[Index].estimated_workers = parseInt(val);
      // newArr[scopeIndex]['edit'] = true;
      // newArr[scopeIndex].labor[Index].calculator = 1;
      newArr[scopeIndex].edit = true;
      setdupadata(newArr);
    } else {
      let newArr = [...dupadata];
      newArr[scopeIndex].labor[Index].estimated_workers = val ? parseInt(val) : 0;
      newArr[scopeIndex]['edit'] = true;
      newArr[scopeIndex].labor[Index].calculator = 0;
      setdupadata(newArr);
    }
  };

  const rateOnchgange = (val, scopeIndex, Index) => {
    let newArr = [...dupadata];
    newArr[scopeIndex].labor[Index].rate = val ? parseFloat(val) : 0;
    newArr[scopeIndex]['edit'] = true;
    setdupadata(newArr);
  };

  const estimateValidation = (data) => {
    const uniqueCombinations = new Set();
    const hasAnyRemoveSow = data.some((item) => item.remove_sow === true);
    data.forEach((item) => {
      if (!item.new) {
        const combinationKey = `${item.phase_id}-${item.sow_id}`;
        uniqueCombinations.add(combinationKey);
      }
    });
    const newData = data.map((order, index) => {
      const toggleOptions = (Index) => {
        setShowOptions((prevOptions) => {
          const newOptions = [...prevOptions];
          // Check if the current value is different from the previous value
          if (newOptions[Index] !== undefined) {
            if (newOptions[Index] === false) {
              newOptions[Index] = !newOptions[Index];
            } else {
              newOptions[Index] = newOptions[Index];
            }
          } else {
            newOptions[Index] = !newOptions[Index]; // Set to true if it doesn't exist
          }
          return newOptions;
        });
      };
      const hasEmptyLabor = order.labor.some((labor) => !labor.remove && labor.designation.trim() === '');
      const hasEmptyEquipment = order.equipment.some((equipment) => !equipment.remove && equipment.description.trim() === '');
      const hasEmptyMaterial = order.material.some((material) => !material.remove && material.description.trim() === '');
      if (hasEmptyLabor || hasEmptyEquipment || hasEmptyMaterial) {
        toggleOptions(index); // Call toggleOptions if there is any empty labor, equipment, or material
      }

      const isEmptyWork = order[0].trim() === '';
      const isEmptyPhase = order.phase.trim() === '';
      const isEmptyMeasurement = order.new && (order.measurement === 0 || order.measurement === null);
      const isEmpty = hasEmptyLabor || hasEmptyEquipment || hasEmptyMaterial || isEmptyWork || isEmptyPhase || isEmptyMeasurement;
      const combinationKey = `${order.phase_id}-${order.sow_id}`;
      const isDuplicate = order.new && uniqueCombinations.has(combinationKey);

      if (!hasAnyRemoveSow) {
        if (isDuplicate) {
          return { ...order, isEmpty: true, isDuplicate: true };
        }
      }

      const updatedOrder = {
        ...order,
        isEmptyWork,
        isEmptyPhase,
        labor: order.labor.map((labor) => ({
          ...labor,
          isEmpty: labor.designation.trim() === '' ? true : false,
          edit: labor.designation.trim() === '' && labor.edit != true ? true : labor.edit,
        })),
        equipment: order.equipment.map((equipment) => ({
          ...equipment,
          isEmpty: equipment.description.trim() === '' ? true : false,
          edit: equipment.description.trim() === '' && equipment.edit != true ? true : equipment.edit,
        })),
        material: order.material.map((material) => ({
          ...material,
          isEmpty: material.description.trim() === '' ? true : false,
          edit: material.description.trim() === '' && material.edit != true ? true : material.edit,
        })),
      };

      return { ...updatedOrder, isEmpty, isDuplicate: false }; // Update isEmpty property of the order
    });

    setdupadata(newData);
    return newData;
  };

  const scrollToElement = (identifier) => {
    const element = document.querySelector(identifier);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'center' });
    } else {
      console.error(`Element with identifier "${identifier}" not found.`);
    }
  };

  const autoScroll = (orderWithEmptyField) => {
    let identifier;
    let idOfEmpty;
    // const orderWithEmptyField = checkedData[indexOfEmpty];
    if (orderWithEmptyField.labor.some((labor) => labor.isEmpty)) {
      idOfEmpty = orderWithEmptyField.labor.find((labor) => labor.isEmpty).id;
      identifier = `#labor-row-${idOfEmpty}`;
    } else if (orderWithEmptyField.equipment.some((equipment) => equipment.isEmpty)) {
      idOfEmpty = orderWithEmptyField.equipment.find((equipment) => equipment.isEmpty).id;
      identifier = `#equipment-row-${idOfEmpty}`;
    } else if (orderWithEmptyField.material.some((material) => material.isEmpty)) {
      idOfEmpty = orderWithEmptyField.material.find((material) => material.isEmpty).id;
      identifier = `#material-row-${idOfEmpty}`;
    }
    if (identifier) {
      setTimeout(() => {
        scrollToElement(identifier);
      }, 0);
    }
  };

  const checkVarianceOrder = () => {
    let data = [...dupadata];
    let checkedData = estimateValidation(data);

    const isDuplicate = checkedData.some((order) => order.isDuplicate);
    const hasEmptyFields = checkedData.some((order) => order.isEmpty);
    const indexOfEmpty = checkedData.findIndex((order) => order.isEmpty);
    // const materialIsEmpty = checkedData.sow((order) => order.material)
    setdupadata(checkedData);

    console.log('indexOfEmpty', indexOfEmpty);
    if (isDuplicate) {
      Swal.fire({ icon: 'error', title: 'Error', text: 'This scope of work and work item already exists.', confirmButtonColor: '#23303D' });
      return;
    }
    if (hasEmptyFields) {
      const confirmation = window.confirm('Please fill in all required fields.');
      if (confirmation || !confirmation) {
        autoScroll(checkedData[indexOfEmpty]);
      }
      return;
    }
    let val = data.filter((a) => a.new == true || a.edit == true || a.remove_sow);

    if (val.length === 0) {
      window.alert('Please edit a scope of work first.');
    } else if (val.length > 0 && estimateStatus === 2) {
      let varianceOrder = val.filter((a) => (a.new || a.edit) && !a.remove_sow);
      if (varianceOrder.length > 0) {
        let directSave = false;
        varianceOrder.forEach((order) => {
          // Check labor
          if (order.labor && Array.isArray(order.labor)) {
            order.labor.forEach((labor) => {
              if (labor.remove) {
                directSave = true;
              }
            });
          }
          // Check equipment
          if (order.equipment && Array.isArray(order.equipment)) {
            order.equipment.forEach((equipment) => {
              if (equipment.remove) {
                directSave = true;
              }
            });
          }
          // Check material
          if (order.material && Array.isArray(order.material)) {
            order.material.forEach((material) => {
              if (material.remove) {
                directSave = true;
              }
            });
          }
        });

        if (directSave) {
          varianceOrder.forEach((order, index) => {
            updateItems(varianceOrder, index, false);
          });
          SaveSow(2);
        } else {
          setModalVarianceOrder(!modalVarianceOrder);
          setVarianceOrderData(varianceOrder);
        }
      } else {
        SaveSow(2);
      }
    } else {
      SaveSow(2);
    }
  };

  const SaveSow = (Status, varianceOrderData) => {
    let data = [];
    let val;
    if (varianceOrderData) {
      val = [...varianceOrderData];
      console.log(val, ' varianceOrder val');
    } else {
      data = [...dupadata];
      // console.log('dupadata', dupadata);

      let checkedData = estimateValidation(data);
      setdupadata(checkedData);

      const isDuplicate = checkedData.some((order) => order.isDuplicate);
      const hasEmptyFields = checkedData.some((order) => order.isEmpty);
      const indexOfEmpty = checkedData.findIndex((order) => order.isEmpty);

      if (isDuplicate) {
        Swal.fire({ icon: 'error', title: 'Error', text: 'This scope of work and work item already exists.', confirmButtonColor: '#23303D' });
        return;
      }
      if (hasEmptyFields) {
        const confirmation = window.confirm('Please fill in all required fields.');
        if (confirmation || !confirmation) {
          autoScroll(checkedData[indexOfEmpty]);
        }

        return;
      }

      val = data.filter((a) => a.new == true || a.edit == true || a.remove_sow == true);
      console.log(val, 'val');

      val.forEach((order, index) => {
        updateItems(val, index, false);
      });

      // updateItems(val);

      console.log(JSON.stringify(val, null, 2), 'here me');
    }
    // setPrintEstimate(true);

    // let getdata = data.pop();

    // added code: the API/button only works if there is event/s in scope of work
    if (val.length === 0) {
      window.alert('Please edit a scope of work first.');
    } else {
      $axios
        .post('estimate/save', {
          project_id: props.project_id,
          estimate_data: val,
          estimate_status: Status,
          company_id: company_id,
          user_id: user_id,
        })
        .then((res) => {
          console.log(res);
          if (res.data.status == 1) {
            let newData = JSON.parse(JSON.stringify(data));
            for (let i = 0; i < newData.length; i++) {
              newData[i].new = false;
            }
            setdupadata(newData);
            getdetailed(props.project_id);
            props.refreshTabStatus(props.project_id); // callback from ProjectDetails.js
            console.log(newData);

            const isApprover = permission['approver'] === 1;
            if (isApprover) {
              Swal.fire({
                title: 'Success!',
                text: 'Your modifications have been submitted successfully',
                icon: 'success',
                confirmButtonText: 'Continue',
              });
            } else {
              Swal.fire({
                title: 'Success!',
                text: 'Your modifications have been submitted successfully and are now pending approval.',
                icon: 'success',
                confirmButtonText: 'Continue',
              });
            }
          } else {
            Swal.fire({
              icon: 'error',
              title: res.data.response,
              text: res.data.description,
              confirmButtonColor: '#23303D',
            });
          }
        })
        .catch((error) => {
          console.log('projectdetails/ProjectBudgetDetails.js', ' ', 'line 562', error);
        });
    }
  };

  const dropDown = listLabor.map((data, index) => {
    return (
      <option value={data.value} key={Index}>
        {data.label}
      </option>
    );
  });

  const DownloadEstimate = () => {
    setPrintEstimate(true);
    setTimeout(() => {
      var tbl = document.getElementById('my-table');
      const workSheet = XLSX.utils.table_to_sheet(tbl);
      const workBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workBook, workSheet, 'dupadata');
      let buf = XLSX.write(workBook, { bookType: 'xlsx', type: 'buffer' });
      XLSX.write(workBook, { bookType: 'xlsx', type: 'binary' });
      XLSX.writeFile(workBook, 'Estimate.xlsx');
      setPrintEstimate(false);
    }, 1000);
  };

  const removeLabor = (scopeIndex, Index) => {
    const newArr = [...dupadata];
    newArr[scopeIndex].labor[Index]['remove'] = true;
    newArr[scopeIndex]['edit'] = true;
    setdupadata(newArr);
  };

  const removeEquipment = (scopeIndex, Index) => {
    const newArr = [...dupadata];
    newArr[scopeIndex].equipment[Index]['remove'] = true;
    newArr[scopeIndex]['edit'] = true;
    setdupadata(newArr);
  };

  const removeMaterial = (scopeIndex, Index) => {
    const newArr = [...dupadata];
    newArr[scopeIndex].material[Index]['remove'] = true;
    newArr[scopeIndex]['edit'] = true;
    setdupadata(newArr);
  };

  const start = () => {
    if (dupadata.length == 0) {
      let newarray = [...dupadata];

      let newdata = JSON.stringify(empty_format);
      newarray.push(JSON.parse(newdata));

      setdupadata(newarray);
      setbuttonTransition(true);
    }
  };

  const areaORVolumeUnitOnChange = (opt, scopeIndex) => {
    let newdata = [...dupadata];
    newdata[scopeIndex].unit_id = opt;
    setdupadata(newdata);

    // Update the area_unit_id in the equipmentProductivity state
    setEquipmentProductivity((prevState) => {
      let newState = [...prevState];
      if (newState[0]) {
        newState[0].area_unit_id = opt;
      }
      return newState;
    });
    // Update the area_unit_id in the laborProductivity state
    setLaborProductivity((prevState) => {
      let newState = [...prevState];
      if (newState[0]) {
        newState[0].area_unit_id = opt;
      }
      return newState;
    });
  };

  const areaOrVolumeOnChange = (val, scopeIndex) => {
    let newdata = [...dupadata];
    newdata[scopeIndex].measurement = val;
    if (val > 0) {
      newdata[scopeIndex].isEmptyMeasurement = false;
    }
    setdupadata(newdata);
    if (newdata[scopeIndex].equipment.length > 0) {
      // Update the area_value in the equipmentProductivity state
      setEquipmentProductivity((prevState) => {
        let newState = [...prevState];
        if (newState[0]) {
          newState[0].area_value = val;
        }
        return newState;
      });
    }
    if (newdata[scopeIndex].labor.length > 0) {
      // Update the area_value in the equipmentProductivity state
      setLaborProductivityForCalculator((prevState) => {
        let newState = [...prevState];
        if (newState[0]) {
          newState[0].area_value = val;
        }
        return newState;
      });
    }
    if (newdata[scopeIndex].material.length > 0) {
      setMaterialQuantityForCalculator((prevState) => {
        let newState = [...prevState];
        if (newState[0]) {
          if (newState[0].data[0].unit_area_value) {
            newState[0].data[0].unit_area_value = val;
          } else {
            newState[0].area_value = val;
          }
        }
        return newState;
      });
    }
  };

  const submitCategory = () => {
    const data = {
      category_name: category,
      company_id: company_id,
    };
    $axios
      .post('equipment/category/create', data)
      .then((res) => {
        if (res.status == 200) {
          Swal.fire('Success', 'Equipment category created succesfully', 'success');
          getequipment();
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Already Exists',
            text: `The equipment category '${category}' already exists`,
            confirmButtonColor: '#23303D',
          });
        }
        onCloseCategory();
      })
      .catch((error) => {
        console.error('API Error:', error);
      });

    EquipmentCalculatorDetail(sowIndex);
  };

  const submitPosition = () => {
    let data = {
      position: position,
      description: positionDescription,
    };

    AddPosition(data)
      .then((res) => {
        if (res.status == 200) {
          Swal.fire('Success', 'Manpower position created succesfully', 'success');
          getmanpower();
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Already Exists',
            text: `The position '${position}' already exists`,
            confirmButtonColor: '#23303D',
          });
        }
        onClosePosition();
      })
      .catch((error) => {
        console.error('API Error:', error);
      });
    // axios
    //   .post(IP + 'equipment/category/create', data, {
    //     headers: {
    //       'Content-Type': 'application/json',
    //       Authorization: `Bearer ${accessToken}`, // Include any necessary headers
    //     },
    //   })
    //   .then((res) => {
    //     if (res.status == 200) {
    //       Swal.fire('Success', 'Equipment category created succesfully', 'success');
    //       getequipment();
    //     } else {
    //       Swal.fire({
    //         icon: 'error',
    //         title: 'Already Exists',
    //         text: `The equipment category '${category}' already exists`,
    //         confirmButtonColor: '#23303D',
    //       });
    //     }
    //     onCloseCategory();
    //   })
    //   .catch((error) => {
    //     console.error('API Error:', error);
    //   });
  };

  const onCloseCategory = () => {
    setCategory('');
    setModalCategory(!modalCategory);
  };

  const onClosePosition = () => {
    setPosition('');
    setPositionDescription('');
    setModalPosition(!modalPosition);
  };

  const workTypeOnChange = (opt, scopeIndex) => {
    let newdata = [...dupadata];
    newdata[scopeIndex].is_sub_con = parseInt(opt.target.value);

    if (newdata[scopeIndex].is_sub_con === 1 && newdata[scopeIndex].edit === true) {
      newdata[scopeIndex]?.labor.map((labor) => {
        labor.is_sub_con = true;
        labor.edit = true;
      });
      newdata[scopeIndex]?.equipment.map((equipment) => {
        equipment.is_sub_con = true;
        equipment.edit = true;
      });
    }
    newdata[scopeIndex]['edit'] = true;

    if (opt.target.value === '0') {
      newdata[scopeIndex]?.labor.map((labor) => {
        labor.is_sub_con = false;
        labor.edit = true;
      });
      newdata[scopeIndex]?.equipment.map((equipment) => {
        equipment.is_sub_con = false;
        equipment.edit = true;
      });
      newdata[scopeIndex]?.material.map((material) => {
        material.is_sub_con = false;
        material.edit = true;
      });
    } else {
      newdata[scopeIndex]?.labor.map((labor) => {
        labor.is_sub_con = true;
        labor.edit = true;
      });
      newdata[scopeIndex]?.equipment.map((equipment) => {
        equipment.is_sub_con = true;
        equipment.edit = true;
      });
      newdata[scopeIndex]?.material.map((material) => {
        material.is_sub_con = true;
        material.edit = true;
      });
    }
  };

  const sowOptionSearch = sowSearchOptions.map((data, Index) => {
    return <option value={data.label} key={Index}></option>;
  });

  const setCalculatorStatus = (title, scopeIndex, Index) => {
    let newdata = [...dupadata];

    if (title === 'Labor') {
      newdata[scopeIndex].labor[Index].calculator = 1;
    } else if (title === 'Equipment') {
      newdata[scopeIndex].equipment[Index].calculator = 1;
    } else {
      newdata[scopeIndex].material[Index].calculator = 1;
    }
    setdupadata(newdata);
  };

  const MeasurementComponent = ({ scope, scopeIndex }) => {
    const unitOptions = Object.keys(units);

    // For Add New Scope Of Work, set default unit to the 1st value in the unitOptions array
    if (scope.unit_id == -1) {
      areaORVolumeUnitOnChange(unitOptions[0], scopeIndex);
    }

    return (
      <span>
        Measurement
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <CInput type="number" style={{ borderRadius: '5px 0px 0px 5px' }} value={scope.measurement} onChange={(e) => areaOrVolumeOnChange(e.target.value, scopeIndex)} />
          <CSelect value={scope.unit_id != -1 ? scope.unit_id : unitOptions[0]} style={{ maxWidth: '100px', borderRadius: '0px 5px 5px 0px', color: ' #22303d', borderColor: '#757070', backgroundColor: '#f4f4f7' }} onChange={(opt) => areaORVolumeUnitOnChange(opt.target.value, scopeIndex)}>
            {unitOptions.map((unitId) => (
              <option key={`unit-key-${unitId}`} value={unitId}>
                {units[unitId]}
              </option>
            ))}
          </CSelect>
        </div>
      </span>
    );
  };

  const tbody = dupadata.map((scope, Index) => {
    // console.log(scope['phase'], 'scope here')
    // console.log(scope['phase_id'], 'scope here')

    let Total_Labor = 0;
    let Total_Equipment = 0;
    let Total_Material = 0;
    let Direct_Total_Cost = 0;
    let Direct_Unit_Cost = 0;
    let Total_OCM = 0;
    let Total_CP = 0;
    let Total_Vat = 0;
    let Total_Cost = 0;
    let Total_Unit_Cost = 0;
    let scopeIndex = Index;
    let sowIndex = 0;

    let tableClass = scope['remove_sow'] == true ? 'disable_table table table-sm' : 'table table-sm';
    // setSowIndex(Index);

    //console.log(
    //  scope.labor.reduce((a, b) => a + b.actual_cost, 0),
    //  "sum"
    //);
    let labor_sum = scope.labor.reduce((a, b) => a + b.actual_cost, 0);
    let equipment_sum = scope.equipment.reduce((a, b) => a + b.actual_cost, 0);

    // const setScopeIndex = (Index) => {
    //   setSowIndex(Index);
    // };
    const phaseCustomStyles = {
      control: (provided, state) => ({
        ...provided,
        borderColor: scope.isEmptyPhase ? '#B42A19' : '#ccc', // Adjust border color based on focus state
        // Add other control styles as needed
      }),
    };

    const sowCustomStyles = {
      control: (provided, state) => ({
        ...provided,
        borderColor: scope.isEmptyWork ? '#B42A19' : '#ccc', // Adjust border color based on focus state
        // Add other control styles as needed
      }),
    };

    const toggleOptions = (Index) => {
      setShowOptions((prevOptions) => {
        const newOptions = [...prevOptions];
        console.log('newOptions,', newOptions);
        newOptions[Index] = !newOptions[Index];
        return newOptions;
      });
    };

    const Labor = scope.labor.map((labor, Index) => {
      const laborCustomStyles = {
        control: (provided, state) => ({
          ...provided,
          borderColor: labor.isEmpty ? '#B42A19' : '#ccc', // Adjust border color based on focus state
          // Add other control styles as needed
        }),
      };
      Total_Labor += labor.estimate;
      let pop_id = 'UncontrolledPopover' + Index;

      let rowClass = 'text-center';
      if (labor.remove == true) {
        rowClass = 'text-center disable-table-column';
      }

      if (labor.is_sub_con) {
        scope.is_sub_con = 1;
      }

      return (
        <tr key={Index} className={rowClass} style={{ fontSize: '14px', backgroundColor: labor.is_archived === 1 ? '#d9d9d9' : 'transparent'}} id={`labor-row-${labor.id}`}>
          <td align="left">
            {labor.edit == true ? (
              <>
                <div style={{ display: 'flex' }}>
                  <div style={{ flex: 1, marginRight: '10px' }}>
                    <CreatableSelect
                      styles={laborCustomStyles}
                      value={{ label: labor.designation, value: labor.id }}
                      options={listLabor}
                      onChange={(opt, meta) => LaborOnchange(opt, meta, scopeIndex, Index)}
                      onCreateOption={(opt, meta) => createOption(opt, meta, scopeIndex, Index)}
                      style={{ borderColor: 'red' }}
                    />
                  </div>
                  {labor.isEmpty && <CLabel style={{ color: '#B42A19' }}>Labor designation is required.</CLabel>}

                  {/* HIDDEN TEMPORARY - JOHN */}
                  {/* show AIButton if data is available - calculator related code */}
                  {/* {labor.dataAvailable ? <AIButton toggleCalculator={toggleCalculator} scopeIndex={scopeIndex} Index={Index} title={'Labor'} /> : null} */}
                  {/* HIDDEN TEMPORARY - JOHN */}
                  {labor.show_calculator === 1 ? <AIButton className={labor.dataAvailable ? 'ai-icon' : ''} toggleCalculator={toggleCalculator} scopeIndex={scopeIndex} Index={Index} title={'Labor'} /> : null}
                </div>
                {/* <> */}
                {/* <div style={{ display: 'flex' }}>
      <div style={{ flex: 1, marginRight: '10px' }}>
        <Select className="basic-single" classNamePrefix="select" defaultValue={{ label: labor.designation, value: labor.id }} isClearable={true} onChange={(opt, meta) => LaborOnchange(opt, meta, scopeIndex, Index)} isSearchable={true} name="color" options={listLabor} />
      </div> */}
                {/* HIDDEN TEMPORARY - JOHN */}
                {/* show AIButton if data is available - calculator related code */}
                {/* {labor.dataAvailable ? <AIButton toggleCalculator={toggleCalculator} scopeIndex={scopeIndex} Index={Index} /> : null} */}
                {/* HIDDEN TEMPORARY - JOHN */}
                {/* </div> */}
              </>
            ) : (
              <>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <span style={{ marginRight: '20px' }}>{labor.designation}</span>
                  {labor.is_variance_order && (
                    <CBadge color="aimhi-success" className="badge-stats">
                      Variance Order
                    </CBadge>
                  )}
                </div>
              </>
            )}
          </td>
          <td>
            {labor.edit == true ? (
              <CInput type="number" placeholder={0} value={labor.estimated_workers === 0 ? '' : labor.estimated_workers} onChange={(e) => EstWorkers(e.target.value, scopeIndex, Index, 0)} step="any" />
            ) : (
              <>
                <div></div>
                {labor.estimated_workers}
                {/* HIDDEN TEMPORARY - JOHN */}
                {labor.calculator === 1 && isEstimateRecoToolAllowed ? (
                  <BackupButton
                    toggleCalculator={() => toggleCalculator({ history_title: 'Labor', scopeIndex, Index })}
                    projectID={props.project_id}
                    dupadata={dupadata}
                    scopeIndex={scopeIndex}
                    Index={Index}
                    accessToken={accessToken}
                    setLaborProductivityForCalculator={setLaborProductivityForCalculator}
                    title={'Labor'}
                    editLabor={editLabor}
                  />
                ) : (
                  ''
                )}
                {/* HIDDEN TEMPORARY - JOHN */}
                {/* <BackupButton toggleCalculator={toggleCalculator} /> */}
                {/* <CTooltip content="Vivamus sagittis lacus vel augue laoreet rutrum faucibus." placement="right" style={{ '--cui-tooltip-bg': '#F3F6F1' }}>
                  <CButton style={{ width: '50px', height: '50px', padding: 0 }}>
                    <img src={warning} style={{ width: '100%', height: '100%' }} />
                  </CButton>
                </CTooltip> */}
              </>
            )}
          </td>
          <td>{labor.edit == true ? <CInput placeholder={'0 days'} value={labor.duration === '0 days' ? '' : labor.duration} onChange={(e) => durationOnchange(e.target.value, scopeIndex, Index, 0)} /> : labor.duration}</td>
          <td style={{ textAlign: 'right' }}>
            {labor.edit == true ? (
              <CInput type="number" placeholder={0} value={labor.rate === 0 ? '' : labor.rate} onChange={(e) => rateOnchgange(e.target.value, scopeIndex, Index)} step="any" />
            ) : (
              <span>
                <NumberFormat value={labor.rate} displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true} style={{ marginLeft: '5px' }} />
              </span>
            )}
          </td>
          <td style={{ textAlign: 'right' }}>
            <NumberFormat value={labor.estimated_cost} displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true} style={{ marginLeft: '5px' }} />
          </td>
          <td>{labor.edit == true && estimateStatus === 2 ? <CInput type="number" placeholder={0} value={labor.hired_workers === 0 ? '' : labor.hired_workers} onChange={(e) => LaborHiredWorkers(e.target.value, scopeIndex, Index)} step="any" /> : labor.hired_workers}</td>
          <td style={{ textAlign: 'right', backgroundColor: '#5497613d' }}>
            {/* Actual Cost */}
            {labor.edit == true && estimateStatus === 2 ? (
              <CInput type="number" step="any" placeholder={0} value={labor.actual_cost === 0 ? '' : labor.actual_cost} onChange={(e) => LaborActualCost(e.target.value, scopeIndex, Index)} />
            ) : (
              <span>
                {labor.is_auto_compute == false ? (
                  <CTooltip content="This actual cost is edited and not auto computed." placement="right-end" color="white">
                    <span>
                      <NumberFormat value={labor.actual_cost} displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true} style={{ marginLeft: '5px' }} />
                      <FaRegQuestionCircle
                        style={{
                          width: '15px',
                          height: '15px',
                          marginLeft: '5px',
                          color: '#757070',
                        }}
                      />
                    </span>
                  </CTooltip>
                ) : (
                  <span>
                    <NumberFormat value={labor.actual_cost} displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true} style={{ marginLeft: '5px' }} />
                  </span>
                )}
              </span>
            )}
          </td>

          <td style={{ textAlign: 'right' }}>
            {/* balance */}

            <NumberFormat
              value={labor.balance}
              displayType={'text'}
              thousandSeparator={true}
              decimalScale={2}
              fixedDecimalScale={true}
              style={{
                marginLeft: '5px',
                color: labor.balance < 0 ? '#B42A19' : '',
              }}
            />
              {labor.remove != true && (
              <span
                style={{ marginLeft: '10px' }}
                className="float-right"
                id={Index}
              >
                {labor.is_archived === 1 ? (
                  <CTooltip content="This labor is archived">
                    <span className="ml-4">
                      <FaArchive />
                    </span>
                  </CTooltip>
                ) : (
                  <CDropdown size="sm">
                    <CDropdownToggle className="caret">
                      <span className="iconify" data-icon="akar-icons:more-vertical"></span>
                    </CDropdownToggle>
                    <CDropdownMenu>
                      <CDropdownItem header>Action</CDropdownItem>
                      <CDropdownDivider />
                      {permission['update'][0] == 1 && (
                        <CDropdownItem onClick={() => editLabor(scopeIndex, Index)}>
                          <span className="iconify" data-icon="bi:pencil-square"></span>
                          <span style={{ marginLeft: '5px' }}>Edit</span>
                        </CDropdownItem>
                      )}
                      {permission['delete'][0] == 1 && (
                        <CDropdownItem onClick={() => removeLabor(scopeIndex, Index)}>
                          <span className="iconify" data-icon="akar-icons:minus"></span>
                          <span style={{ marginLeft: '5px' }}>Remove</span>
                        </CDropdownItem>
                      )}
                    </CDropdownMenu>
                  </CDropdown>
                )}
              </span>
            )}
          </td>
        </tr>
      );
    });

    const Equipment = scope.equipment.map((equipment, Index) => {
      const equipmentCustomStyles = {
        control: (provided, state) => ({
          ...provided,
          borderColor: equipment.isEmpty ? '#B42A19' : '#ccc', // Adjust border color based on focus state
          // Add other control styles as needed
        }),
      };
      Total_Equipment += equipment.estimate;

      let rowClass = 'text-center';

      if (equipment.remove == true) {
        rowClass = 'text-center disable-table-column';
      }

      if (equipment.is_sub_con) {
        scope.is_sub_con = 1;
      }

      return (
        <tr key={Index} className={rowClass} style={{ fontSize: '14px', backgroundColor: equipment.is_archived === 1 ? '#d9d9d9' : 'transparent'}} id={`equipment-row-${equipment.id}`}>
          <td align="left">
            {equipment.edit == true ? (
              <>
                <div style={{ display: 'flex' }}>
                  <div style={{ flex: 1, marginRight: '10px' }}>
                    <CreatableSelect
                      styles={equipmentCustomStyles}
                      value={{
                        label: equipment.description,
                        value: equipment.id,
                      }}
                      options={listEquipment}
                      onChange={(opt, meta) => EquipmentOnchange(opt, meta, scopeIndex, Index)}
                      onCreateOption={(opt, meta) => showEquipmentModal(opt, scopeIndex, Index)}
                    />
                    {equipment.isEmpty && <CLabel style={{ color: '#B42A19' }}>Equipment description is required.</CLabel>}
                  </div>
                  {/* HIDDEN TEMPORARY - JOHN */}
                  {/* {equipment.dataAvailable ? <AIButton toggleCalculator={toggleCalculator} scopeIndex={scopeIndex} Index={Index} title={'Equipment'} /> : null} */}
                  {/* HIDDEN TEMPORARY - JOHN */}
                  {equipment.show_calculator === 1 ? <AIButton className={equipment.dataAvailable ? 'ai-icon' : ''} toggleCalculator={toggleCalculator} scopeIndex={scopeIndex} Index={Index} title={'Equipment'} /> : null}
                </div>
              </>
            ) : (
              <>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <span style={{ marginRight: '20px' }}>{equipment.description}</span>
                  {equipment.is_variance_order && (
                    <CBadge color="aimhi-success" className="badge-stats">
                      Variance Order
                    </CBadge>
                  )}
                </div>
              </>
            )}
          </td>
          <td>
            {equipment.edit == true ? (
              <CInput type="number" placeholder={0} value={equipment.estimated_quantity === 0 ? '' : equipment.estimated_quantity} onChange={(e) => qtyonchange(e.target.value, scopeIndex, Index)} />
            ) : (
              <>
                {' '}
                {equipment.estimated_quantity}
                {/* HIDDEN TEMPORARY - JOHN */}
                {equipment.calculator === 1 && isEstimateRecoToolAllowed ? (
                  <BackupButton
                    toggleCalculator={() => toggleCalculator({ history_title: 'Equipment', scopeIndex, Index })}
                    projectID={props.project_id}
                    dupadata={dupadata}
                    scopeIndex={scopeIndex}
                    Index={Index}
                    accessToken={accessToken}
                    setEquipmentProductivityForCalculator={setEquipmentProductivityForCalculator}
                    title="Equipment"
                    editEquipment={editEquipment}
                  />
                ) : (
                  ''
                )}
                {/* HIDDEN TEMPORARY - JOHN */}
                {/* <BackupButton toggleCalculator={toggleCalculator} /> */}
              </>
            )}
          </td>
          <td>
            {equipment.edit == true ? (
              //  equipment.duration
              <CInput placeholder={'0 days'} value={equipment.duration === '0 days' ? '' : equipment.duration} onChange={(e) => eqdurationonchange(e.target.value, scopeIndex, Index)} />
            ) : (
              equipment.duration
            )}
          </td>

          <td style={{ textAlign: 'right' }}>
            {equipment.edit == true ? (
              <CInput type="number" placeholder={0} value={equipment.rate === 0 ? '' : equipment.rate} onChange={(e) => eqrateonchange(e.target.value, scopeIndex, Index)} />
            ) : (
              <span>
                <NumberFormat value={equipment.rate} displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true} style={{ marginLeft: '5px' }} />
              </span>
            )}
          </td>
          <td style={{ textAlign: 'right' }}>
            <NumberFormat value={equipment.estimated_cost} displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true} style={{ marginLeft: '5px' }} />
          </td>
          <td>{equipment.edit == true && estimateStatus === 2 ? <CInput type="number" placeholder={0} value={equipment.ordered_quantity === 0 ? '' : equipment.ordered_quantity} onChange={(e) => EquipmentHiredWorkers(e.target.value, scopeIndex, Index)} /> : equipment.ordered_quantity}</td>
          <td style={{ textAlign: 'right', backgroundColor: '#5497613d' }}>
            {/* Equipment Actual Cost */}
            {equipment.edit == true && estimateStatus === 2 ? (
              <CInput type="number" placeholder={0} value={equipment.actual_cost === 0 ? '' : equipment.actual_cost} onChange={(e) => EquipmentActualCost(e.target.value, scopeIndex, Index)} />
            ) : (
              <span>
                {equipment.is_auto_compute == false ? (
                  <CTooltip content="This actual cost is edited and not auto computed." placement="right-end" color="white">
                    <span>
                      <NumberFormat value={equipment.actual_cost} displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true} style={{ marginLeft: '5px' }} />
                      <FaRegQuestionCircle
                        style={{
                          width: '15px',
                          height: '15px',
                          marginLeft: '5px',
                          color: '#757070',
                        }}
                      />
                    </span>
                  </CTooltip>
                ) : (
                  <span>
                    {' '}
                    <NumberFormat value={equipment.actual_cost} displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true} style={{ marginLeft: '5px' }} />
                  </span>
                )}
              </span>
            )}
          </td>
          <td style={{ textAlign: 'right' }}>
            {/* Equipment Balance */}

            <NumberFormat
              value={equipment.balance}
              displayType={'text'}
              thousandSeparator={true}
              decimalScale={2}
              fixedDecimalScale={true}
              style={{
                marginLeft: '5px',
                color: equipment.balance < 0 ? '#B42A19' : '',
              }}
            />
            <span style={{ marginLeft: '10px' }} className="float-right">
              {equipment.is_archived === 1 ? (
                <CTooltip content="This equipment is archived">
                  <span className="ml-4">
                    <FaArchive />
                  </span>
                </CTooltip>
              ) : (
                equipment.remove !== true && (
                  <CDropdown>
                    <CDropdownToggle className="caret">
                      <span className="iconify" data-icon="akar-icons:more-vertical"></span>
                    </CDropdownToggle>
                    <CDropdownMenu>
                      <CDropdownItem header>Action</CDropdownItem>
                      <CDropdownDivider />
                      {permission['update'][0] == 1 && (
                        <CDropdownItem onClick={() => editEquipment(scopeIndex, Index)}>
                          <span className="iconify" data-icon="bi:pencil-square"></span>
                          <span style={{ marginLeft: '5px' }}>Edit</span>
                        </CDropdownItem>
                      )}
                      {permission['delete'][0] == 1 && (
                        <CDropdownItem onClick={() => removeEquipment(scopeIndex, Index)}>
                          <span className="iconify" data-icon="akar-icons:minus"></span>
                          <span style={{ marginLeft: '5px' }}>Remove</span>
                        </CDropdownItem>
                      )}
                    </CDropdownMenu>
                  </CDropdown>
                )
              )}
            </span>
          </td>
        </tr>
      );
    });

    const Material = scope.material.map((material, Index) => {
      const materialCustomStyles = {
        control: (provided, state) => ({
          ...provided,
          borderColor: material.isEmpty ? '#B42A19' : '#ccc', // Adjust border color based on focus state
          // Add other control styles as needed
        }),
      };
      Total_Material += material.estimate;
      let rowClass = 'text-center';
      if (material.remove == true) {
        rowClass = 'text-center disable-table-column';
      }
      if (material.is_sub_con) {
        scope.is_sub_con = 1;
      }

      const PopUpUseCase2 = ({ size }) => {
        if (!isEstimateRecoToolAllowed) {
          return;
        }
        if (!material.has_price_rec && scopeIndex in activeRecommenderIndex2) {
          return <div style={{ height: '25px', width: '25px', display: 'hidden', marginLeft: '8px' }}></div>;
        }

        // no recommendations
        else if (!(scopeIndex in activeRecommenderIndex2) || !(Index in activeRecommenderIndex2[scopeIndex]) || !activeRecommenderIndex2[scopeIndex][Index].isOpen) {
          return null;
        }

        const isLoading = activeRecommenderIndex2[scopeIndex][Index].isLoading;
        let Component = null;

        if (isLoading) {
          if (scopeIndex in activeRecommenderIndex2 && Index in activeRecommenderIndex2[scopeIndex]) {
            let materialId = dupadata[scopeIndex].material[Index].material_id;
            let material = dupadata[scopeIndex].material[Index].description;
            let price = dupadata[scopeIndex].material[Index].unit_cost;
            let pm_id = dupadata[scopeIndex].material[Index].id;
            hasRecommendedMaterialPrice(scopeIndex, Index, pm_id, materialId, material, price);
          }
        } else {
          Component = (
            <ComponentUseCase2
              recommendations={activeRecommenderIndex2[scopeIndex][Index]}
              handleClose={() => {
                toggleRecommenderPopUp(2, false);
                hideRecommenderPopup(scopeIndex, Index, 'price');
              }}
              handleSubmit={() => markRecommendedItem('price', scopeIndex, Index, 0)}
              unitCostOnChange={matunitoncostchange}
              scopeIndex={scopeIndex}
              Index={Index}
            />
          );
        }

        return <RecommenderPopUp size={size}>{Component}</RecommenderPopUp>;
      };

      const PopUpUseCase3 = ({ size }) => {
        if (!isEstimateRecoToolAllowed) {
          return;
        }
        // other items in this group has recommendations, put an invisible div near the text so that unit price text aligns
        if (!material.has_quantity_rec && scopeIndex in activeRecommenderIndex3) {
          return <div style={{ height: '25px', width: '25px', display: 'hidden', marginLeft: '8px' }}></div>;
        }

        // no recommendations
        else if (!(scopeIndex in activeRecommenderIndex3) || !(Index in activeRecommenderIndex3[scopeIndex]) || !activeRecommenderIndex3[scopeIndex][Index].isOpen) {
          return null;
        }

        const isLoading = activeRecommenderIndex3[scopeIndex][Index].isLoading;
        let Component = null;

        if (isLoading) {
          if (scopeIndex in activeRecommenderIndex3 && Index in activeRecommenderIndex3[scopeIndex]) {
            let phaseId = dupadata[scopeIndex].phase_id;
            let sowId = dupadata[scopeIndex].sow_id;
            let materialId = dupadata[scopeIndex].material[Index].material_id;
            hasRecommendedMaterialQuantity(phaseId, sowId, materialId, scopeIndex, Index);
          }
        } else {
          Component = (
            <ComponentUseCase3
              recommendations={activeRecommenderIndex3[scopeIndex][Index]}
              handleClose={() => {
                toggleRecommenderPopUp(3, false);
                hideRecommenderPopup(scopeIndex, Index, 'quantity');
              }}
              handleSubmit={() => markRecommendedItem('quantity', scopeIndex, Index, 0)}
              quantityOnChange={matquantityonchange}
              scopeIndex={scopeIndex}
              Index={Index}
            />
          );
        }

        return <RecommenderPopUp size={size}>{Component}</RecommenderPopUp>;
      };

      return (
        <tr key={Index} className={rowClass} style={{ fontSize: '14px', backgroundColor: material.is_archived === 1 ? '#d9d9d9' : 'transparent'}} id={`material-row-${material.id}`}>
          <td align="left">
            {material.edit == true ? (
              <>
                <div style={{ display: 'flex' }}>
                  <div style={{ flex: 1, marginRight: '10px' }}>
                    <CreatableSelect
                      styles={materialCustomStyles}
                      value={{
                        label: material.description,
                        value: material.id,
                      }}
                      options={listMaterial}
                      onChange={(opt, meta) => MaterialOnchange(opt, meta, scopeIndex, Index)}
                      onCreateOption={(opt, meta) => createOptionMaterial(opt, meta, scopeIndex, Index)}
                    />
                    {material.isEmpty && <CLabel style={{ color: '#B42A19' }}>Material description is required.</CLabel>}
                  </div>
                  {/* {dataAvailable ? <AIButton toggleCalculator={toggleCalculator} /> : null}  */}
                  {material.dataAvailable ? <AIButton className="ai-icon" toggleCalculator={toggleCalculator} scopeIndex={scopeIndex} Index={Index} title={'Material'} /> : null}
                </div>
              </>
            ) : (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <span style={{ marginRight: '20px' }}>{material.description}</span>
                {material.is_variance_order && (
                  <CBadge color="aimhi-success" className="badge-stats">
                    Variance Order
                  </CBadge>
                )}
              </div>
            )}
          </td>
          <td style={{ display: 'flex', paddingLeft: '30px', justifyContent: 'center' }}>
            {material.edit == true ? (
              <div style={{ display: 'flex' }}>
                <div style={{ flex: '2' }}>
                  <CInput type="number" placeholder={0} value={material.estimated_quantity === 0 ? '' : material.estimated_quantity} onChange={(e) => matquantityonchange(e.target.value, scopeIndex, Index)} />
                </div>
              </div>
            ) : (
              <div>
                {material.estimated_quantity}
                {material.calculator === 1 && isEstimateRecoToolAllowed ? (
                  <BackupButton
                    toggleCalculator={() => toggleCalculator({ history_title: 'Material', scopeIndex, Index })}
                    projectID={props.project_id}
                    dupadata={dupadata}
                    scopeIndex={scopeIndex}
                    Index={Index}
                    accessToken={accessToken}
                    setMaterialQuantityForCalculator={setMaterialQuantityForCalculator}
                    title={'Material'}
                    editMaterial={editMaterial}
                  />
                ) : null}
              </div>
            )}
            {/* {isEstimateRecoToolAllowed && <PopUpUseCase3 size={material.edit ? '' : 's'} />} HIDDEN -- will not be used because there is already calculator */}
            {/* {material.has_quantity_rec === 1 && <PopUpUseCase3 size={(material.edit) ? '' : 's'} />} */}
          </td>
          <td>{material.edit == true ? <CInput type="text" value={material.unit} onChange={(e) => matunitonchange(e.target.value, scopeIndex, Index)} /> : material.unit}</td>

          {/* here use case 2 */}
          <td style={{ textAlign: 'right' }}>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              {material.edit ? (
                <CInput type="number" placeholder={0} value={material.unit_cost === 0 ? '' : material.unit_cost} onChange={(e) => matunitoncostchange(e.target.value, scopeIndex, Index)} />
              ) : (
                <NumberFormat value={material.unit_cost} displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true} style={{ marginLeft: '5px' }} />
              )}
              {isEstimateRecoToolAllowed && <PopUpUseCase2 size={material.edit ? '' : 's'} />}
            </div>
            {/* {material.edit == true ? (
              <div style={{ display: 'flex' }}>
                <div style={{ flex: '2' }}>
                  <CInput type="number" placeholder={0} value={material.unit_cost === 0 ? '' : material.unit_cost} onChange={(e) => matunitoncostchange(e.target.value, scopeIndex, Index)} />
                </div>
              </div>
            ) : (
              <div style={{ display: 'flex' }}>
                <NumberFormat value={material.unit_cost} displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true} style={{ marginLeft: '5px' }} />
              </div>
            )} */}
            {/* <PopUpUseCase2 size={material.edit ? '' : 's'} /> */}
          </td>
          {/* here use case 2 */}

          <td style={{ textAlign: 'right' }}>
            <NumberFormat value={material.estimated_cost} displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true} style={{ marginLeft: '5px' }} />
          </td>

          <td>{material.edit == true && estimateStatus === 2 ? <CInput type="number" placeholder={0} value={material.ordered_quantity === 0 ? '' : material.ordered_quantity} onChange={(e) => MaterialHiredWorkers(e.target.value, scopeIndex, Index)} step="any" /> : material.ordered_quantity}</td>

          <td style={{ textAlign: 'right', backgroundColor: '#5497613d' }}>
            <NumberFormat value={material.actual_cost} displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true} style={{ marginLeft: '5px' }} />
          </td>
          <td style={{ textAlign: 'right' }}>
            <NumberFormat
              value={material.balance}
              displayType={'text'}
              thousandSeparator={true}
              decimalScale={2}
              fixedDecimalScale={true}
              style={{
                marginLeft: '5px',
                color: material.balance < 0 ? '#B42A19' : '',
              }}
            />
              <span style={{ marginLeft: '10px' }} className="float-right">
                {material.is_archived === 1 ? (
                  <CTooltip content="This material is archived">
                  <span className="ml-4">
                    <FaArchive />
                  </span>
                  </CTooltip>
                ) : (
                  material.remove !== true && (
                    <CDropdown>
                      <CDropdownToggle className="caret">
                        <span className="iconify" data-icon="akar-icons:more-vertical"></span>
                      </CDropdownToggle>
                      <CDropdownMenu>
                        <CDropdownItem header>Action</CDropdownItem>
                        <CDropdownDivider />
                        {permission['update'][0] == 1 && (
                          <CDropdownItem onClick={() => editMaterial(scopeIndex, Index)}>
                            <span className="iconify" data-icon="bi:pencil-square"></span>
                            <span style={{ marginLeft: '5px' }}>Edit</span>
                          </CDropdownItem>
                        )}
                        {permission['delete'][0] == 1 && (
                          <CDropdownItem onClick={() => removeMaterial(scopeIndex, Index)}>
                            <span className="iconify" data-icon="akar-icons:minus"></span>
                            <span style={{ marginLeft: '5px' }}>Remove</span>
                          </CDropdownItem>
                        )}
                      </CDropdownMenu>
                    </CDropdown>
                  )
                )}
              </span>
          </td>
        </tr>
      );
    });

    return (
      <>
        <table className={tableClass} key={Index} style={{ marginTop: '30px', textAlign: 'justify' }}>
          <tbody key={Index} lg="12" xl="12">
            <tr className="table-borderless" style={{ marginTop: '20px' }}>
              <td colSpan="1">
                <div
                  style={{
                    fontWeight: '700',
                    fontSize: '16px',
                  }}
                >
                  {scope.new != true ? (
                    <div style={{ display: 'flex', alignItems: 'center' }} id={`phase-${scope.phase_id}`}>
                      <span style={{ marginRight: '20px' }}> {scope['phase']} </span>
                      {scope.is_sub_con === 1 ? (
                        <CBadge color="aimhi-success" className="badge-stats">
                          Subcontractor
                        </CBadge>
                      ) : (
                        ''
                      )}
                    </div>
                  ) : (
                    <span>
                      Scope Of Work
                      <CreatableSelect
                        styles={phaseCustomStyles}
                        value={{
                          label: scope['phase'],
                          value: scope['phase'],
                        }}
                        options={phases}
                        onChange={(opt, meta) => phaseonchange(opt, meta, scopeIndex)}
                        // onCreateOption={(opt, meta) => createOptionphase(opt, meta, scopeIndex)}
                        isValidNewOption={() => false}
                        // styles={customStyles}
                      />
                      {scope.isEmptyPhase && <CLabel style={{ color: '#B42A19' }}>Scope of work is required.</CLabel>}
                    </span>
                  )}
                </div>
                <div
                  style={{
                    fontWeight: '700',
                    fontSize: '16px',
                    marginTop: '10px',
                  }}
                  onClick={() => toggleShow(scope[0])}
                  id={`sow_id-${scope.sow_id}-psow_id-${scope.psow_id}`}
                >
                  {typeof scope.new == 'undefined' && typeof scope['edit'] == 'undefined' ? (
                    <span>
                      {scope[0]}
                      <FaPencilAlt
                        title="Edit Scope of Work"
                        style={{
                          marginLeft: '10px',
                          width: '15px',
                          height: '15px',
                        }}
                        onClick={() => EditSow(scope.id)}
                      />
                      <FaTrashAlt
                        title="Remove Scope of Work"
                        style={{
                          marginLeft: '5px',
                          width: '15px',
                          height: '15px',
                        }}
                        onClick={() => RemoveSow(scope.id)}
                      />
                    </span>
                  ) : (
                    <span>
                      Work Item
                      <CreatableSelect
                        styles={sowCustomStyles}
                        value={{
                          label: scope[0],
                          value: scope[0],
                        }}
                        options={sowOptions[scope['phase_category_id']] || sowOptions[-1] || []}
                        onChange={(opt, meta) => sowonchange(opt, meta, scopeIndex)}
                        onInputChange={(value) => {
                          debouncedFetchOptions(value, scope);
                        }}
                        onBlur={() => {
                          debouncedFetchOptions('', scope);
                        }}
                        // onCreateOption={(opt, meta) => createOptionsow(opt, meta, scopeIndex)}
                        isValidNewOption={() => false}
                        isOptionDisabled={(option) => option.disabled}
                        filterOption={null}
                      />
                      {scope.isEmptyWork && <CLabel style={{ color: '#B42A19' }}>Work item is required.</CLabel>}
                    </span>
                  )}
                </div>

                <div
                  style={{
                    fontWeight: '700',
                    fontSize: '16px',
                    marginTop: '10px',
                  }}
                  onClick={() => toggleShow(scope[0])}
                >
                  {scope.new != true && scope.edit != true ? (
                    ''
                  ) : (
                    <span>
                      Contractor Type
                      <CSelect value={scope.is_sub_con} size="md" style={{ paddingLeft: '8px', fontWeight: 'bold', fontSize: '16px', fontFamily: 'Roboto', color: '#333333' }} onChange={(opt) => workTypeOnChange(opt, scopeIndex)}>
                        <option value="0">General contractor</option>
                        <option value="1">Subcontractor</option>
                      </CSelect>
                    </span>
                  )}
                </div>

                <div
                  style={{
                    fontWeight: '700',
                    fontSize: '16px',
                    marginTop: '10px',
                  }}
                  onClick={() => toggleShow(scope[0])}
                >
                  {/* HIDDEN TEMPORARY - JOHN */}
                  {scope.new != true && scope.edit != true ? (
                    <div>
                      {/* Measurement: {scope.area_or_volume} {scope.area_or_volume_unit} */}
                      {scope.unit_id ? `${scope.measurement} ${units[scope.unit_id]}` : null}
                    </div>
                  ) : (
                    // <MeasurementComponent scope={scope} scopeIndex={scopeIndex} />
                    <span>
                      Measurement
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <CInput type="number" style={{ borderRadius: '5px 0px 0px 5px', marginRight: '1px', borderColor: scope.isEmptyMeasurement ? '#B42A19' : '#757070' }} value={scope.measurement} onChange={(e) => areaOrVolumeOnChange(e.target.value, scopeIndex)} />
                        <CSelect value={scope.unit_id} style={{ maxWidth: '100px', borderRadius: '0px 5px 5px 0px', color: ' #22303d', borderColor: '#757070', backgroundColor: '#f4f4f7' }} onChange={(opt) => areaORVolumeUnitOnChange(opt.target.value, scopeIndex)}>
                          {Object.keys(units).map((unitId) => (
                            <option key={`unit-key-${unitId}`} value={unitId}>
                              {units[unitId]}
                            </option>
                          ))}
                        </CSelect>
                      </div>
                      {scope.isEmptyMeasurement && <CLabel style={{ color: '#B42A19' }}>Measurement is required.</CLabel>}
                    </span>
                  )}
                  {/* HIDDEN TEMPORARY - JOHN */}
                </div>
              </td>
            </tr>

            <CButton
              // onClick={() => toggleOptions(Index)}
              style={{
                padding: '0px',
                width: '98%',
                position: 'absolute',
                //backgroundColor: showOptions[Index] ? '#F4F4F7' : '',
                //color: showOptions[Index] ? 'white' : 'black',
                //boxShadow: "0 0 0 0.01rem rgba(0, 0, 0, 0.3)",
                //boxShadow: showOptions[Index] ? '0 0 0 0.1rem rgba(0, 0, 0, 0.3)' : '0 0 0 0.01rem',
                boxShadow: showOptions[Index] ? '0 0 0 0.01rem white' : '0 0 0 0.01rem white',
              }}
            >
              <div className="float-right" style={{ marginTop: '-42px' }}>
                {/* {showOptions[Index] ? (
                  <a
                    onClick={() => {
                      toggleOptions(Index);
                    }}
                    style={{
                      color: '#39f',
                      textDecoration: 'underline',
                      cursor: 'pointer',
                    }}
                    onMouseEnter={(e) => {
                      e.target.style.color = '#39f';
                      e.target.style.opacity = '0.1';
                    }}
                    onMouseLeave={(e) => {
                      e.target.style.color = '#39f';
                      e.target.style.opacity = ''; // Remove the opacity property
                    }}
                  >
                    <strong>COLLAPSE</strong>
                  </a>
                ) : (
                  <a
                    onClick={() => {
                      toggleOptions(Index);
                    }}
                    style={{
                      color: '#39f',
                      textDecoration: 'underline',
                      cursor: 'pointer',
                    }}
                    onMouseEnter={(e) => {
                      e.target.style.color = '#39f';
                      e.target.style.opacity = '0.1';
                    }}
                    onMouseLeave={(e) => {
                      e.target.style.color = '#39f';
                      e.target.style.opacity = ''; // Remove the opacity property
                    }}
                  >
                    <strong>EXPAND</strong>
                  </a>
                )} */}
                <a
                  onClick={() => {
                    toggleOptions(Index);
                  }}
                  style={{
                    color: '#39f',
                    textDecoration: 'underline',
                    cursor: 'pointer',
                  }}
                  className="expand-collapse"
                >
                  <strong>{showOptions[Index] ? 'COLLAPSE' : 'EXPAND'}</strong>
                </a>
              </div>
            </CButton>

            {showOptions[Index] && (
              <>
                <div style={{ marginTop: '-70px' }}></div>
                <tr
                  className="text-center"
                  style={{
                    background: '#F4F4F7',
                    fontWeight: '600',
                    fontSize: '14px',
                    border: 'none',
                    marginTop: '8px',
                  }}
                >
                  <td style={{ width: '400px' }}>Designation</td>
                  <td>
                    Estimated <br></br>Workers
                  </td>

                  <td>Duration</td>
                  <td style={{ textAlign: 'right' }}>Rate</td>
                  <td style={{ textAlign: 'right' }}>
                    Estimated <br></br>Cost
                  </td>
                  <td>
                    Hired <br></br>Workers
                  </td>

                  <td>
                    Actual <br></br> Cost
                  </td>
                  <td>Balance</td>
                </tr>
                <tr
                  style={{
                    // background: "#F4F4F7",
                    fontWeight: '600',
                    fontSize: '16px',
                  }}
                  // onClick={() => toggleShow(scope[0] + "Labor" + Index)}
                >
                  <td colSpan="4">Labor</td>
                  <td colSpan="1" style={{ textAlign: 'right' }}>
                    <NumberFormat value={scope.total_estimated_labor_cost} displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true} style={{ marginLeft: '5px' }} />
                  </td>
                  <td colSpan="1"></td>

                  {scope['edit_labor_cost'] == true ? (
                    <td colSpan="1" style={{ backgroundColor: '#5497613d' }}>
                      <CInput type="number" size="md" onChange={(e) => LaborTotalOnchange(e, Index)} value={scope.total_actual_labor_cost} />
                    </td>
                  ) : (
                    <td style={{ textAlign: 'right', backgroundColor: '#5497613d' }}>
                      <span>
                        <NumberFormat value={scope.total_actual_labor_cost} displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true} style={{ marginLeft: '5px' }} />
                      </span>
                      {/* <span style={{ marginLeft: "5px" }}>
                        {permission["update"][0] == 1 && labor_sum == 0 && (
                          <FaPencilAlt
                            onClick={() => editLaborTotal(Index)}
                            style={{ height: "15px", width: "15px" }}
                          />
                        )}
                      </span> */}
                    </td>
                  )}

                  <td colSpan="1" style={{ textAlign: 'right' }}>
                    <NumberFormat
                      value={scope.labor_balance}
                      displayType={'text'}
                      thousandSeparator={true}
                      decimalScale={2}
                      fixedDecimalScale={true}
                      style={{
                        marginLeft: '5px',
                        color: scope.labor_balance < 0 ? '#B42A19' : '',
                      }}
                    />
                    <span style={{ marginLeft: '10px' }} className="float-right">
                      <CDropdown>
                        <CDropdownToggle className="caret">
                          <span className="iconify" data-icon="akar-icons:more-vertical"></span>
                        </CDropdownToggle>
                        <CDropdownMenu>
                          <CDropdownItem header>Action</CDropdownItem>
                          <CDropdownDivider />

                          <CDropdownItem onClick={() => AddNew(scopeIndex, 'Labor')}>
                            <span className="iconify" data-icon="ant-design:plus-outlined"></span>
                            Add New
                          </CDropdownItem>
                        </CDropdownMenu>
                      </CDropdown>
                    </span>
                  </td>
                </tr>
                {togglescope.includes(scope[0] + 'Labor' + Index) || (show == true && Labor)}

                <tr
                  className="text-center"
                  style={{
                    background: '#F4F4F7',
                    fontWeight: '600',
                    fontSize: '14px',
                  }}
                >
                  <td>Description</td>
                  <td>
                    Estimated <br></br>Quantity
                  </td>

                  <td>Duration</td>
                  <td style={{ textAlign: 'right' }}>Rate</td>
                  <td style={{ textAlign: 'right' }}>
                    Estimated <br></br>Cost
                  </td>

                  <td>
                    Ordered <br></br> Quantity
                  </td>

                  <td>
                    Actual <br></br>Cost
                  </td>
                  <td>Balance</td>
                </tr>
                <tr
                  style={{
                    // background: "#F4F4F7",
                    fontWeight: '600',
                    fontSize: '16px',
                  }}
                >
                  <td colSpan="4">Equipment</td>
                  <td colSpan="1" style={{ textAlign: 'right' }}>
                    <NumberFormat value={scope.total_estimated_equipment_cost} displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true} style={{ marginLeft: '5px' }} />
                  </td>
                  <td colSpan="1"></td>

                  {scope['edit_equipment_cost'] == true ? (
                    <td colSpan="1" style={{ backgroundColor: '#5497613d' }}>
                      <CInput type="number" size="md" onChange={(e) => EquipmentTotalChange(e, Index)} value={scope.total_actual_equipment_cost} />
                    </td>
                  ) : (
                    <td style={{ textAlign: 'right', backgroundColor: '#5497613d' }}>
                      <span>
                        <NumberFormat value={scope.total_actual_equipment_cost} displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true} style={{ marginLeft: '5px' }} />
                      </span>
                      <span style={{ marginLeft: '5px' }}>
                        {/* {permission["update"][0] == 1 && equipment_sum == 0 && (
                        <FaPencilAlt
                          onClick={() => editEquipmentTotal(Index)}
                          style={{ height: "15px", width: "15px" }}
                        />
                      )} */}
                      </span>
                    </td>
                  )}
                  <td colSpan="1" style={{ textAlign: 'right' }}>
                    <NumberFormat
                      value={scope.total_equipment_balance}
                      displayType={'text'}
                      thousandSeparator={true}
                      decimalScale={2}
                      fixedDecimalScale={true}
                      style={{
                        marginLeft: '5px',
                        color: scope.total_equipment_balance < 0 ? '#B42A19' : '',
                      }}
                    />
                    <span style={{ marginLeft: '10px' }} className="float-right">
                      <CDropdown>
                        <CDropdownToggle className="caret">
                          <span className="iconify" data-icon="akar-icons:more-vertical"></span>
                        </CDropdownToggle>
                        <CDropdownMenu>
                          <CDropdownItem header>Action</CDropdownItem>
                          <CDropdownDivider />
                          <CDropdownItem onClick={() => AddNew(scopeIndex, 'Equipment')}>
                            <span className="iconify" data-icon="ant-design:plus-outlined"></span>
                            Add New
                          </CDropdownItem>
                        </CDropdownMenu>
                      </CDropdown>
                    </span>
                  </td>
                </tr>
                {togglescope.includes(scope[0] + 'Equipment' + Index) || (show == true && Equipment)}

                <tr
                  className="text-center"
                  style={{
                    background: '#F4F4F7',
                    fontWeight: '600',
                    fontSize: '14px',
                  }}
                >
                  <td>Description</td>
                  <td>
                    Estimated <br></br>Quantity
                  </td>

                  <td>Unit</td>
                  <td style={{ textAlign: 'right' }}>Unit Cost</td>
                  <td style={{ textAlign: 'right' }}>
                    Estimated <br></br> Cost
                  </td>
                  <td>
                    Ordered <br></br> Quantity
                  </td>

                  <td>
                    Actual <br></br>Cost
                  </td>
                  <td>Balance</td>
                </tr>
                <tr
                  style={{
                    // background: "#F4F4F7",
                    fontWeight: '600',
                    fontSize: '16px',
                  }}
                >
                  <td colSpan="4">Material</td>
                  <td colSpan="1" style={{ textAlign: 'right' }}>
                    <NumberFormat value={scope.total_estimated_material_cost} displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true} style={{ marginLeft: '5px' }} />
                  </td>
                  <td colSpan="1"></td>
                  <td colSpan="1" style={{ textAlign: 'right', backgroundColor: '#5497613d' }}>
                    <NumberFormat value={scope.total_actual_material_cost} displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true} style={{ marginLeft: '5px' }} />
                  </td>
                  <td colSpan="1" style={{ textAlign: 'right' }}>
                    <NumberFormat value={scope.total_material_balance} displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true} style={{ marginLeft: '5px' }} />
                    <span style={{ marginLeft: '10px' }} className="float-right">
                      <CDropdown>
                        <CDropdownToggle className="caret">
                          <span className="iconify" data-icon="akar-icons:more-vertical"></span>
                        </CDropdownToggle>
                        <CDropdownMenu>
                          <CDropdownItem header>Action</CDropdownItem>
                          <CDropdownDivider />
                          <CDropdownItem onClick={() => AddNew(scopeIndex, 'Material')}>
                            <span className="iconify" data-icon="ant-design:plus-outlined"></span>
                            Add New
                          </CDropdownItem>
                        </CDropdownMenu>
                      </CDropdown>
                    </span>
                  </td>
                </tr>
                {togglescope.includes(scope[0] + 'Material' + Index) || (show == true && Material)}
                <tr>
                  <td colSpan={8}></td>
                </tr>
              </>
            )}

            <div style={{ marginTop: showOptions[Index] ? '' : '-75px' }}></div>
            <tr style={{ fontSize: '14px' }}>
              <td colSpan="4">Labor</td>
              <td colSpan="1" className="text-right">
                <NumberFormat value={scope.total_estimated_labor_cost} displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true} style={{ marginLeft: '5px' }} />
              </td>
              <td colSpan="1"></td>
              <td colSpan="1" style={{ textAlign: 'right', backgroundColor: '#5497613d' }}>
                <NumberFormat value={scope.total_actual_labor_cost} displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true} style={{ marginLeft: '5px' }} />
              </td>
              <td colSpan="1" style={{ textAlign: 'right' }}>
                <NumberFormat
                  value={scope.total_labor_balance}
                  displayType={'text'}
                  thousandSeparator={true}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  style={{
                    marginLeft: '5px',
                    color: scope.total_labor_balance < 0 ? '#B42A19' : '',
                  }}
                />
                <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;</span>
              </td>
            </tr>

            <tr className="table-borderless" style={{ fontSize: '14px' }}>
              <td colSpan="4">Equipment</td>
              <td colSpan="1" className="text-right">
                <NumberFormat value={scope.total_estimated_equipment_cost} displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true} style={{ marginLeft: '5px' }} />
              </td>
              <td colSpan="1"></td>
              <td colSpan="1" style={{ textAlign: 'right', backgroundColor: '#5497613d' }}>
                <NumberFormat value={scope.total_actual_equipment_cost} displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true} style={{ marginLeft: '5px' }} />
              </td>
              <td colSpan="1" style={{ textAlign: 'right' }}>
                <NumberFormat
                  value={scope.total_equipment_balance}
                  displayType={'text'}
                  thousandSeparator={true}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  style={{
                    marginLeft: '5px',
                    color: scope.total_equipment_balance < 0 ? '#B42A19' : '',
                  }}
                />
                <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;</span>
              </td>
            </tr>

            <tr className="table-borderless" style={{ fontSize: '14px' }}>
              <td colSpan="4">Material</td>

              <td className="table-borderless text-right" colSpan="1">
                <NumberFormat value={scope.total_estimated_material_cost} displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true} style={{ marginLeft: '5px' }} />
              </td>
              <td colSpan="1"></td>
              <td colSpan="1" style={{ textAlign: 'right', backgroundColor: '#5497613d' }}>
                <NumberFormat value={scope.total_actual_material_cost} displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true} style={{ marginLeft: '5px' }} />
              </td>
              <td colSpan="1" style={{ textAlign: 'right' }}>
                <NumberFormat
                  value={scope.total_material_balance}
                  displayType={'text'}
                  thousandSeparator={true}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  style={{
                    marginLeft: '5px',
                    color: scope.total_material_balancee < 0 ? '#B42A19' : '',
                  }}
                />
                <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;</span>
              </td>
            </tr>

            <tr style={{ fontSize: '14px' }}>
              <td colSpan="4">
                <CTooltip content="Total Direct Cost = Labor Cost + Equipment Cost + Material Cost" placement="right-end" color="white">
                  <span>
                    Total Direct Cost
                    <FaRegQuestionCircle
                      style={{
                        width: '15px',
                        height: '15px',
                        marginLeft: '5px',
                        color: '#757070',
                      }}
                    />
                  </span>
                </CTooltip>
              </td>

              <td colSpan="1" className="text-right">
                ₱
                <NumberFormat value={scope.estimated_total_direct_cost} displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true} style={{ marginLeft: '5px' }} />
              </td>
              <td colSpan="1"></td>
              <td colSpan="1" style={{ textAlign: 'right', backgroundColor: '#5497613d' }}>
                ₱
                <NumberFormat value={scope.actual_total_direct_cost} displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true} style={{ marginLeft: '5px' }} />
              </td>
              <td colSpan="1" style={{ textAlign: 'right' }}>
                ₱
                <NumberFormat
                  value={scope.total_direct_cost_balance}
                  displayType={'text'}
                  thousandSeparator={true}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  style={{
                    marginLeft: '5px',
                    color: scope.total_direct_cost_balance < 0 ? '#B42A19' : '',
                  }}
                />
                <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;</span>
              </td>
            </tr>
            <tr className="table-borderless">
              <td colSpan="8"></td>
            </tr>

            <tr className="table-borderless" style={{ fontSize: '14px' }}>
              <td colSpan="4">
                <CTooltip content={`Overhead, contingencies, and miscellaneous = ${grantTotal?.ocm_percentage}% of Actual Direct Cost`} placement="right-end" color="white">
                  <span>
                    OCM
                    <FaRegQuestionCircle
                      style={{
                        width: '15px',
                        height: '15px',
                        marginLeft: '5px',
                        color: '#757070',
                      }}
                    />
                  </span>
                </CTooltip>
              </td>

              <td colSpan="1" className="text-right">
                <NumberFormat value={scope.estimated_ocm} displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true} style={{ marginLeft: '5px' }} />
              </td>
              <td colSpan="1"></td>
              <td colSpan="1" style={{ textAlign: 'right', backgroundColor: '#5497613d' }}>
                <NumberFormat value={scope.actual_ocm} displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true} style={{ marginLeft: '5px' }} />
              </td>
              <td colSpan="1" style={{ textAlign: 'right' }}>
                <NumberFormat
                  value={scope.ocm_balance}
                  displayType={'text'}
                  thousandSeparator={true}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  style={{
                    marginLeft: '5px',
                    color: scope.ocm_balance < 0 ? '#B42A19' : '',
                  }}
                />
                <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;</span>
              </td>
            </tr>
            {hasVAT != 2 /**************************** CHANGED FROM "hasVAT != 1" TO "hasVAT != 2" ********************************/ && (
              <tr className="table-borderless" style={{ fontSize: '14px' }}>
                <td colSpan="4">
                  <CTooltip content="Value Added Tax = 12% of (Actual Direct Cost + OCM + CP)" placement="right-end" color="white">
                    <span>
                      VAT
                      <FaRegQuestionCircle
                        style={{
                          width: '15px',
                          height: '15px',
                          marginLeft: '5px',
                          color: '#757070',
                        }}
                      />
                    </span>
                  </CTooltip>
                </td>

                <td colSpan="1" className="text-right">
                  <NumberFormat value={scope.estimated_vat} displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true} style={{ marginLeft: '5px' }} />
                </td>
                <td colSpan="1"></td>
                <td colSpan="1" style={{ textAlign: 'right', backgroundColor: '#5497613d' }}>
                  <NumberFormat value={scope.actual_vat} displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true} style={{ marginLeft: '5px' }} />
                </td>
                <td colSpan="1" style={{ textAlign: 'right' }}>
                  <NumberFormat
                    value={scope.vat_balance}
                    displayType={'text'}
                    thousandSeparator={true}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    style={{
                      marginLeft: '5px',
                      color: scope.vat_balance < 0 ? '#B42A19' : '',
                    }}
                  />
                  <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;</span>
                </td>
              </tr>
            )}

            <tr className="table-borderless" style={{ fontSize: '14px' }}>
              <td colSpan="4">
                <CTooltip content={`Contractor’s Profit = ${grantTotal?.cp_percenteage}% of Actual Direct Cost`} placement="right-end" color="white">
                  <span>
                    CP
                    <FaRegQuestionCircle
                      style={{
                        width: '15px',
                        height: '15px',
                        marginLeft: '5px',
                        color: '#757070',
                      }}
                    />
                  </span>
                </CTooltip>
              </td>

              <td colSpan="1" className="text-right">
                <NumberFormat value={scope.estimated_cp} displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true} style={{ marginLeft: '5px' }} />
              </td>
              <td colSpan="1"></td>
              <td colSpan="1" style={{ textAlign: 'right', backgroundColor: '#5497613d' }}>
                <NumberFormat value={scope.actual_cp} displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true} style={{ marginLeft: '5px' }} />
              </td>
              <td colSpan="1" style={{ textAlign: 'right' }}>
                <NumberFormat
                  value={scope.cp_balance}
                  displayType={'text'}
                  thousandSeparator={true}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  style={{
                    marginLeft: '5px',
                    color: scope.cp_balance < 0 ? '#B42A19' : '',
                  }}
                />
                <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;</span>
              </td>
            </tr>

            <tr style={{ fontSize: '14px' }}>
              <td colSpan="4">
                <CTooltip content="Indirect Cost = OCM + CP + VAT" placement="right-end" color="white">
                  <span>
                    Total Indirect Cost
                    <FaRegQuestionCircle
                      style={{
                        width: '15px',
                        height: '15px',
                        marginLeft: '5px',
                        color: '#757070',
                      }}
                    />
                  </span>
                </CTooltip>
              </td>

              <td colSpan="1" className="text-right">
                ₱
                <NumberFormat value={scope.estimated_total_indirect_cost} displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true} style={{ marginLeft: '5px' }} />
              </td>
              <td colSpan="1"></td>
              <td colSpan="1" style={{ textAlign: 'right', backgroundColor: '#5497613d' }}>
                ₱
                <NumberFormat value={scope.actual_total_indirect_cost} displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true} style={{ marginLeft: '5px' }} />
              </td>
              <td colSpan="1" style={{ textAlign: 'right' }}>
                ₱
                <NumberFormat
                  value={scope.total_indirect_cost_balance}
                  displayType={'text'}
                  thousandSeparator={true}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  style={{
                    marginLeft: '5px',
                    color: scope.total_indirect_cost_balance < 0 ? '#B42A19' : '',
                  }}
                />
                <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;</span>
              </td>
            </tr>

            <tr className="table-borderless" style={{ fontSize: '14px' }}>
              <td colSpan="4">
                <CTooltip content=" Contract Cost = Actual Direct Cost + Indirect Cost" placement="right-end" color="white">
                  <span>
                    Total Contract Cost
                    <FaRegQuestionCircle
                      style={{
                        width: '15px',
                        height: '15px',
                        marginLeft: '5px',
                        color: '#757070',
                      }}
                    />
                  </span>
                </CTooltip>
              </td>

              <td colSpan="1" className="text-right">
                ₱
                <NumberFormat value={scope.estimated_total_contract_cost} displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true} style={{ marginLeft: '5px' }} />
              </td>
              <td colSpan="1"></td>
              <td colSpan="1" style={{ textAlign: 'right', backgroundColor: '#5497613d' }}>
                ₱
                <NumberFormat value={scope.actual_total_contract_cost} displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true} style={{ marginLeft: '5px' }} />
              </td>
              <td colSpan="1" style={{ textAlign: 'right' }}>
                ₱
                <NumberFormat
                  value={scope.total_contract_cost_balance}
                  displayType={'text'}
                  thousandSeparator={true}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  style={{
                    marginLeft: '5px',
                    color: scope.total_contract_cost_balance < 0 ? '#B42A19' : '',
                  }}
                />
                <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;</span>
              </td>
            </tr>

            <tr className="table-borderless">
              <td> </td>
            </tr>
            <tr className="table-borderless">
              <td> </td>
            </tr>
            <tr>
              <td
                colSpan="8"
                style={{
                  borderBottom: '5px solid #549764',
                  borderTop: '1px solid white',
                }}
              ></td>
            </tr>
          </tbody>
        </table>
      </>

      // <Accordion>
      //     <AccordionItem>

      // <table className={tableClass} key={Index} style={{ marginTop: '30px', textAlign: 'justify' }}>
      //   <tbody key={Index} lg="12" xl="12">
      //       <AccordionItemHeading>
      //         <AccordionItemButton>
      //           This item is
      //           <tr className="table-borderless" style={{ marginTop: '20px' }}>
      //             <td colSpan="1">
      //               <div
      //                 style={{
      //                   fontWeight: '700',
      //                   fontSize: '16px',
      //                 }}
      //                 className="float-left"
      //               >
      //                 {scope.new != true ? (
      //                   scope['phase']
      //                 ) : (
      //                   <span>
      //                     Scope Of Work
      //                     <CreatableSelect
      //                       value={{
      //                         label: scope['phase'],
      //                         value: scope['phase'],
      //                       }}
      //                       options={phases}
      //                       onChange={(opt, meta) => phaseonchange(opt, meta, scopeIndex)}
      //                       onCreateOption={(opt, meta) => createOptionphase(opt, meta, scopeIndex)}
      //                       styles={customStyles}
      //                     />
      //                   </span>
      //                 )}
      //               </div>
      //               <br />
      //               <div
      //                 style={{
      //                   fontWeight: '700',
      //                   fontSize: '16px',
      //                   marginTop: '10px',
      //                 }}
      //                 onClick={() => toggleShow(scope[0])}
      //               >
      //                 {typeof scope.new == 'undefined' && typeof scope['edit'] == 'undefined' ? (
      //                   <span>
      //                     {scope[0]}
      //                     <FaPencilAlt
      //                       title="Edit Scope of Work"
      //                       style={{
      //                         marginLeft: '10px',
      //                         width: '15px',
      //                         height: '15px',
      //                       }}
      //                       onClick={() => EditSow(scope.id)}
      //                     />
      //                     <FaTrashAlt
      //                       title="Remove Scope of Work"
      //                       style={{
      //                         marginLeft: '5px',
      //                         width: '15px',
      //                         height: '15px',
      //                       }}
      //                       onClick={() => RemoveSow(scope.id)}
      //                     />
      //                   </span>
      //                 ) : (
      //                   <span>
      //                     Work Item
      //                     <CreatableSelect
      //                       value={{
      //                         label: scope[0],
      //                         value: scope[0],
      //                       }}
      //                       options={sowOptions}
      //                       onChange={(opt, meta) => sowonchange(opt, meta, scopeIndex)}
      //                       onCreateOption={(opt, meta) => createOptionsow(opt, meta, scopeIndex)}
      //                       styles={customStyles}
      //                     />
      //                   </span>
      //                 )}
      //               </div>
      //             </td>
      //           </tr>
      //         </AccordionItemButton>
      //       </AccordionItemHeading>
      //       <AccordionItemPanel>
      //         <p>I am the content</p>
      //         {/* <div style={{ marginTop: showOptions[Index] ? '' : '75px' }}></div> */}
      //         <tr style={{ fontSize: '14px' }}>
      //           <td colSpan="4">Labor</td>
      //           <td colSpan="1" className="text-right">
      //
      //             <NumberFormat
      //               value={scope.total_estimated_labor_cost}
      //               displayType={'text'}
      //               thousandSeparator={true}
      //               decimalScale={2}
      //               fixedDecimalScale={true}
      //               style={{ marginLeft: '5px' }}
      //             />
      //           </td>
      //           <td colSpan="1"></td>
      //           <td colSpan="1" style={{ textAlign: 'right', backgroundColor: '#5497613d' }}>
      //
      //             <NumberFormat
      //               value={scope.total_actual_labor_cost}
      //               displayType={'text'}
      //               thousandSeparator={true}
      //               decimalScale={2}
      //               fixedDecimalScale={true}
      //               style={{ marginLeft: '5px' }}
      //             />
      //           </td>
      //           <td colSpan="1" style={{ textAlign: 'right' }}>
      //
      //             <NumberFormat
      //               value={scope.total_labor_balance}
      //               displayType={'text'}
      //               thousandSeparator={true}
      //               decimalScale={2}
      //               fixedDecimalScale={true}
      //               style={{
      //                 marginLeft: '5px',
      //                 color: scope.total_labor_balance < 0 ? '#B42A19' : '',
      //               }}
      //             />
      //             <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;</span>
      //           </td>
      //         </tr>

      //         <tr className="table-borderless" style={{ fontSize: '14px' }}>
      //           <td colSpan="4">Equipment</td>
      //           <td colSpan="1" className="text-right">
      //
      //             <NumberFormat
      //               value={scope.total_estimated_equipment_cost}
      //               displayType={'text'}
      //               thousandSeparator={true}
      //               decimalScale={2}
      //               fixedDecimalScale={true}
      //               style={{ marginLeft: '5px' }}
      //             />
      //           </td>
      //           <td colSpan="1"></td>
      //           <td colSpan="1" style={{ textAlign: 'right', backgroundColor: '#5497613d' }}>
      //
      //             <NumberFormat
      //               value={scope.total_actual_equipment_cost}
      //               displayType={'text'}
      //               thousandSeparator={true}
      //               decimalScale={2}
      //               fixedDecimalScale={true}
      //               style={{ marginLeft: '5px' }}
      //             />
      //           </td>
      //           <td colSpan="1" style={{ textAlign: 'right' }}>
      //
      //             <NumberFormat
      //               value={scope.total_equipment_balance}
      //               displayType={'text'}
      //               thousandSeparator={true}
      //               decimalScale={2}
      //               fixedDecimalScale={true}
      //               style={{
      //                 marginLeft: '5px',
      //                 color: scope.total_equipment_balance < 0 ? '#B42A19' : '',
      //               }}
      //             />
      //             <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;</span>
      //           </td>
      //         </tr>

      //         <tr className="table-borderless" style={{ fontSize: '14px' }}>
      //           <td colSpan="4">Material</td>

      //           <td className="table-borderless text-right" colSpan="1">
      //
      //             <NumberFormat
      //               value={scope.total_estimated_material_cost}
      //               displayType={'text'}
      //               thousandSeparator={true}
      //               decimalScale={2}
      //               fixedDecimalScale={true}
      //               style={{ marginLeft: '5px' }}
      //             />
      //           </td>
      //           <td colSpan="1"></td>
      //           <td colSpan="1" style={{ textAlign: 'right', backgroundColor: '#5497613d' }}>
      //
      //             <NumberFormat
      //               value={scope.total_actual_material_cost}
      //               displayType={'text'}
      //               thousandSeparator={true}
      //               decimalScale={2}
      //               fixedDecimalScale={true}
      //               style={{ marginLeft: '5px' }}
      //             />
      //           </td>
      //           <td colSpan="1" style={{ textAlign: 'right' }}>
      //
      //             <NumberFormat
      //               value={scope.total_material_balance}
      //               displayType={'text'}
      //               thousandSeparator={true}
      //               decimalScale={2}
      //               fixedDecimalScale={true}
      //               style={{
      //                 marginLeft: '5px',
      //                 color: scope.total_material_balancee < 0 ? '#B42A19' : '',
      //               }}
      //             />
      //             <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;</span>
      //           </td>
      //         </tr>

      //         <tr style={{ fontSize: '14px' }}>
      //           <td colSpan="4">
      //             <CTooltip
      //               content="Total Direct Cost = Labor Cost + Equipment Cost + Material Cost"
      //               placement="right-end"
      //               color="white"
      //             >
      //               <span>
      //                 Total Direct Cost
      //                 <FaRegQuestionCircle
      //                   style={{
      //                     width: '15px',
      //                     height: '15px',
      //                     marginLeft: '5px',
      //                     color: '#757070',
      //                   }}
      //                 />
      //               </span>
      //             </CTooltip>
      //           </td>

      //           <td colSpan="1" className="text-right">
      //
      //             <NumberFormat
      //               value={scope.estimated_total_direct_cost}
      //               displayType={'text'}
      //               thousandSeparator={true}
      //               decimalScale={2}
      //               fixedDecimalScale={true}
      //               style={{ marginLeft: '5px' }}
      //             />
      //           </td>
      //           <td colSpan="1"></td>
      //           <td colSpan="1" style={{ textAlign: 'right', backgroundColor: '#5497613d' }}>
      //
      //             <NumberFormat
      //               value={scope.actual_total_direct_cost}
      //               displayType={'text'}
      //               thousandSeparator={true}
      //               decimalScale={2}
      //               fixedDecimalScale={true}
      //               style={{ marginLeft: '5px' }}
      //             />
      //           </td>
      //           <td colSpan="1" style={{ textAlign: 'right' }}>
      //
      //             <NumberFormat
      //               value={scope.total_direct_cost_balance}
      //               displayType={'text'}
      //               thousandSeparator={true}
      //               decimalScale={2}
      //               fixedDecimalScale={true}
      //               style={{
      //                 marginLeft: '5px',
      //                 color: scope.total_direct_cost_balance < 0 ? '#B42A19' : '',
      //               }}
      //             />
      //             <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;</span>
      //           </td>
      //         </tr>
      //         <tr className="table-borderless">
      //           <td colSpan="8"></td>
      //         </tr>

      //         <tr className="table-borderless" style={{ fontSize: '14px' }}>
      //           <td colSpan="4">
      //             <CTooltip
      //               content={`Overhead, contingencies, and miscellaneous = ${grantTotal['ocm_percentage']}% of Actual Direct Cost`}
      //               placement="right-end"
      //               color="white"
      //             >
      //               <span>
      //                 OCM
      //                 <FaRegQuestionCircle
      //                   style={{
      //                     width: '15px',
      //                     height: '15px',
      //                     marginLeft: '5px',
      //                     color: '#757070',
      //                   }}
      //                 />
      //               </span>
      //             </CTooltip>
      //           </td>

      //           <td colSpan="1" className="text-right">
      //
      //             <NumberFormat
      //               value={scope.estimated_ocm}
      //               displayType={'text'}
      //               thousandSeparator={true}
      //               decimalScale={2}
      //               fixedDecimalScale={true}
      //               style={{ marginLeft: '5px' }}
      //             />
      //           </td>
      //           <td colSpan="1"></td>
      //           <td colSpan="1" style={{ textAlign: 'right', backgroundColor: '#5497613d' }}>
      //
      //             <NumberFormat
      //               value={scope.actual_ocm}
      //               displayType={'text'}
      //               thousandSeparator={true}
      //               decimalScale={2}
      //               fixedDecimalScale={true}
      //               style={{ marginLeft: '5px' }}
      //             />
      //           </td>
      //           <td colSpan="1" style={{ textAlign: 'right' }}>
      //
      //             <NumberFormat
      //               value={scope.ocm_balance}
      //               displayType={'text'}
      //               thousandSeparator={true}
      //               decimalScale={2}
      //               fixedDecimalScale={true}
      //               style={{
      //                 marginLeft: '5px',
      //                 color: scope.ocm_balance < 0 ? '#B42A19' : '',
      //               }}
      //             />
      //             <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;</span>
      //           </td>
      //         </tr>
      //         {hasVAT !=
      //           2 /**************************** CHANGED FROM "hasVAT != 1" TO "hasVAT != 2" ********************************/ && (
      //           <tr className="table-borderless" style={{ fontSize: '14px' }}>
      //             <td colSpan="4">
      //               <CTooltip
      //                 content="Value Added Tax = 12% of (Actual Direct Cost + OCM + CP)"
      //                 placement="right-end"
      //                 color="white"
      //               >
      //                 <span>
      //                   VAT
      //                   <FaRegQuestionCircle
      //                     style={{
      //                       width: '15px',
      //                       height: '15px',
      //                       marginLeft: '5px',
      //                       color: '#757070',
      //                     }}
      //                   />
      //                 </span>
      //               </CTooltip>
      //             </td>

      //             <td colSpan="1" className="text-right">
      //
      //               <NumberFormat
      //                 value={scope.estimated_vat}
      //                 displayType={'text'}
      //                 thousandSeparator={true}
      //                 decimalScale={2}
      //                 fixedDecimalScale={true}
      //                 style={{ marginLeft: '5px' }}
      //               />
      //             </td>
      //             <td colSpan="1"></td>
      //             <td colSpan="1" style={{ textAlign: 'right', backgroundColor: '#5497613d' }}>
      //
      //               <NumberFormat
      //                 value={scope.actual_vat}
      //                 displayType={'text'}
      //                 thousandSeparator={true}
      //                 decimalScale={2}
      //                 fixedDecimalScale={true}
      //                 style={{ marginLeft: '5px' }}
      //               />
      //             </td>
      //             <td colSpan="1" style={{ textAlign: 'right' }}>
      //
      //               <NumberFormat
      //                 value={scope.vat_balance}
      //                 displayType={'text'}
      //                 thousandSeparator={true}
      //                 decimalScale={2}
      //                 fixedDecimalScale={true}
      //                 style={{
      //                   marginLeft: '5px',
      //                   color: scope.vat_balance < 0 ? '#B42A19' : '',
      //                 }}
      //               />
      //               <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;</span>
      //             </td>
      //           </tr>
      //         )}

      //         <tr className="table-borderless" style={{ fontSize: '14px' }}>
      //           <td colSpan="4">
      //             <CTooltip
      //               content={`Contractor’s Profit = ${grantTotal['cp_percenteage']}% of Actual Direct Cost`}
      //               placement="right-end"
      //               color="white"
      //             >
      //               <span>
      //                 CP
      //                 <FaRegQuestionCircle
      //                   style={{
      //                     width: '15px',
      //                     height: '15px',
      //                     marginLeft: '5px',
      //                     color: '#757070',
      //                   }}
      //                 />
      //               </span>
      //             </CTooltip>
      //           </td>

      //           <td colSpan="1" className="text-right">
      //
      //             <NumberFormat
      //               value={scope.estimated_cp}
      //               displayType={'text'}
      //               thousandSeparator={true}
      //               decimalScale={2}
      //               fixedDecimalScale={true}
      //               style={{ marginLeft: '5px' }}
      //             />
      //           </td>
      //           <td colSpan="1"></td>
      //           <td colSpan="1" style={{ textAlign: 'right', backgroundColor: '#5497613d' }}>
      //
      //             <NumberFormat
      //               value={scope.actual_cp}
      //               displayType={'text'}
      //               thousandSeparator={true}
      //               decimalScale={2}
      //               fixedDecimalScale={true}
      //               style={{ marginLeft: '5px' }}
      //             />
      //           </td>
      //           <td colSpan="1" style={{ textAlign: 'right' }}>
      //
      //             <NumberFormat
      //               value={scope.cp_balance}
      //               displayType={'text'}
      //               thousandSeparator={true}
      //               decimalScale={2}
      //               fixedDecimalScale={true}
      //               style={{
      //                 marginLeft: '5px',
      //                 color: scope.cp_balance < 0 ? '#B42A19' : '',
      //               }}
      //             />
      //             <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;</span>
      //           </td>
      //         </tr>

      //         <tr style={{ fontSize: '14px' }}>
      //           <td colSpan="4">
      //             <CTooltip content="Indirect Cost = OCM + CP + VAT" placement="right-end" color="white">
      //               <span>
      //                 Total Indirect Cost
      //                 <FaRegQuestionCircle
      //                   style={{
      //                     width: '15px',
      //                     height: '15px',
      //                     marginLeft: '5px',
      //                     color: '#757070',
      //                   }}
      //                 />
      //               </span>
      //             </CTooltip>
      //           </td>

      //           <td colSpan="1" className="text-right">
      //
      //             <NumberFormat
      //               value={scope.estimated_total_indirect_cost}
      //               displayType={'text'}
      //               thousandSeparator={true}
      //               decimalScale={2}
      //               fixedDecimalScale={true}
      //               style={{ marginLeft: '5px' }}
      //             />
      //           </td>
      //           <td colSpan="1"></td>
      //           <td colSpan="1" style={{ textAlign: 'right', backgroundColor: '#5497613d' }}>
      //
      //             <NumberFormat
      //               value={scope.actual_total_indirect_cost}
      //               displayType={'text'}
      //               thousandSeparator={true}
      //               decimalScale={2}
      //               fixedDecimalScale={true}
      //               style={{ marginLeft: '5px' }}
      //             />
      //           </td>
      //           <td colSpan="1" style={{ textAlign: 'right' }}>
      //
      //             <NumberFormat
      //               value={scope.total_indirect_cost_balance}
      //               displayType={'text'}
      //               thousandSeparator={true}
      //               decimalScale={2}
      //               fixedDecimalScale={true}
      //               style={{
      //                 marginLeft: '5px',
      //                 color: scope.total_indirect_cost_balance < 0 ? '#B42A19' : '',
      //               }}
      //             />
      //             <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;</span>
      //           </td>
      //         </tr>

      //         <tr className="table-borderless" style={{ fontSize: '14px' }}>
      //           <td colSpan="4">
      //             <CTooltip
      //               content=" Contract Cost = Actual Direct Cost + Indirect Cost"
      //               placement="right-end"
      //               color="white"
      //             >
      //               <span>
      //                 Total Contract Cost
      //                 <FaRegQuestionCircle
      //                   style={{
      //                     width: '15px',
      //                     height: '15px',
      //                     marginLeft: '5px',
      //                     color: '#757070',
      //                   }}
      //                 />
      //               </span>
      //             </CTooltip>
      //           </td>

      //           <td colSpan="1" className="text-right">
      //
      //             <NumberFormat
      //               value={scope.estimated_total_contract_cost}
      //               displayType={'text'}
      //               thousandSeparator={true}
      //               decimalScale={2}
      //               fixedDecimalScale={true}
      //               style={{ marginLeft: '5px' }}
      //             />
      //           </td>
      //           <td colSpan="1"></td>
      //           <td colSpan="1" style={{ textAlign: 'right', backgroundColor: '#5497613d' }}>
      //
      //             <NumberFormat
      //               value={scope.actual_total_contract_cost}
      //               displayType={'text'}
      //               thousandSeparator={true}
      //               decimalScale={2}
      //               fixedDecimalScale={true}
      //               style={{ marginLeft: '5px' }}
      //             />
      //           </td>
      //           <td colSpan="1" style={{ textAlign: 'right' }}>
      //
      //             <NumberFormat
      //               value={scope.total_contract_cost_balance}
      //               displayType={'text'}
      //               thousandSeparator={true}
      //               decimalScale={2}
      //               fixedDecimalScale={true}
      //               style={{
      //                 marginLeft: '5px',
      //                 color: scope.total_contract_cost_balance < 0 ? '#B42A19' : '',
      //               }}
      //             />
      //             <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;</span>
      //           </td>
      //         </tr>

      //         <tr className="table-borderless">
      //           <td> </td>
      //         </tr>
      //         <tr className="table-borderless">
      //           <td> </td>
      //         </tr>
      //         <tr>
      //           <td
      //             colSpan="8"
      //             style={{
      //               borderBottom: '5px solid #549764',
      //               borderTop: '1px solid white',
      //             }}
      //           ></td>
      //         </tr>
      //       </AccordionItemPanel>
      //       </tbody>
      //       </table>
      //     </AccordionItem>
      // </Accordion>
    );
  });

  const updateItems = (data, index, checked) => {
    // data.forEach((item, i) => {
    //   if (i === index) {
    //     console.log('i', i);

    //     item.labor.forEach((item) => {
    //       if (item.new || item.edit || item.remove) {
    //         item.is_variance_order = checked;
    //         item.is_sub_con = false;
    //       }
    //     });

    //     item.equipment.forEach((item) => {
    //       if (item.new || item.edit || item.remove) {
    //         item.is_variance_order = checked;
    //         item.is_sub_con = false;
    //       }
    //     });

    //     item.material.forEach((item) => {
    //       if (item.new || item.edit || item.remove) {
    //         item.is_variance_order = checked;
    //         item.is_sub_con = false;
    //       }
    //     });
    //   } else {
    //     item.labor.forEach((item) => {
    //       if (item.new || item.edit || item.remove) {
    //         item.is_variance_order = false;
    //         item.is_sub_con = false;
    //       }
    //     });

    //     item.equipment.forEach((item) => {
    //       if (item.new || item.edit || item.remove) {
    //         item.is_variance_order = false;
    //         item.is_sub_con = false;
    //       }
    //     });

    //     item.material.forEach((item) => {
    //       if (item.new || item.edit || item.remove) {
    //         item.is_variance_order = false;
    //         item.is_sub_con = false;
    //       }
    //     });
    //   }

    //   // Additionally, set is_sub_con to false for all items
    // });
    // console.log('updateItems data', data);
    console.log('updateItems data', data);
    const updateItem = (item) => {
      console.log('updateItem item', item);
      if (item.new || item.edit || item.remove) {
        item.is_variance_order = checked;
        // item.is_sub_con = false;
      }
    };

    const updateList = (list) => {
      if (list && list.length > 0) {
        list.forEach(updateItem);
      }
    };

    const laborList = data[index].labor ? data[index].labor : data.labor;
    const equipmentList = data[index].equipment ? data[index].equipment : data.equipment;
    const materialList = data[index].material ? data[index].material : data.material;

    console.log('laborList', laborList);
    console.log('equipmentList', equipmentList);
    console.log('materialList', materialList);

    updateList(laborList);
    updateList(equipmentList);
    updateList(materialList);
  };

  const handleCheckboxChange = (props) => {
    let data = [...varianceOrderData];
    updateItems(data, props.Index, props.checked);
    setVarianceOrderData(data);
  };

  const varianceOrderAction = (checked) => {
    let data = [...varianceOrderData];
    updateItems(data, 0, checked);
    setVarianceOrderData(data);
    SaveSow(2, varianceOrderData);
    setModalVarianceOrder(!modalVarianceOrder);
  };

  const varianceOrderSubmit = () => {
    let data = [...varianceOrderData];

    // Filter out items where any of labor, equipment, or material has is_variance_order
    const filteredData = data.filter((item) => {
      const hasVarianceOrderInArray = (array) => array?.some((element) => element.is_variance_order && (element.new || element.edit));

      return !hasVarianceOrderInArray(item.labor) && !hasVarianceOrderInArray(item.equipment) && !hasVarianceOrderInArray(item.material);
    });

    if (filteredData) {
      filteredData.forEach((item, index) => {
        updateItems(item, 0, false);
      });
    }

    console.log('varianceOrderSubmit filteredData', filteredData);
    SaveSow(2, varianceOrderData);
    setModalVarianceOrder(!modalVarianceOrder);
  };

  const varianceOrderYes = () => {
    varianceOrderAction(true);
  };

  const varianceOrderNo = () => {
    varianceOrderAction(false);
  };

  const varianceOrderPreview = varianceOrderData.map((data, Index) => {
    const labor = data.labor.map((labor, Index) => {
      if (labor.new || labor.edit) {
        return (
          <tr
            style={{
              textAlign: 'center',
              // color: labor.status == "add" ? "#3A7D21" : "#B42A19",
              backgroundColor: labor.new || labor.edit ? '#5abc6d' : '#e8786a',
            }}
            key={Index}
          >
            <td>{labor.designation}</td>
            <td>{labor.estimated_workers}</td>
            <td>{labor.duration}</td>
            <td>{labor.rate}</td>
            <td>{labor.estimated_cost}</td>
            <td>{labor.hired_workers}</td>
            <td>{labor.actual_cost}</td>
            <td>{labor.balance}</td>
          </tr>
        );
      }
    });

    const equipment = data.equipment.map((equipment, Index) => {
      if (equipment.new || equipment.edit) {
        return (
          <tr
            style={{
              textAlign: 'center',
              // color: equipment.status == "add" ? "#3A7D21" : "#B42A19",
              backgroundColor: equipment.new || equipment.edit ? '#5abc6d' : '#e8786a',
            }}
            key={Index}
          >
            <td>{equipment.description}</td>
            <td>{equipment.estimated_quantity}</td>
            <td>{equipment.duration}</td>
            <td>{equipment.rate}</td>
            <td>{equipment.estimated_cost}</td>
            <td>{equipment.ordered_quantity}</td>
            <td>{equipment.actual_cost}</td>
            <td>{equipment.balance}</td>
          </tr>
        );
      }
    });

    const material = data.material.map((material, Index) => {
      if (material.new || material.edit) {
        return (
          <tr
            style={{
              textAlign: 'center',
              // color: material.status == "add" ? "#3A7D21" : "#B42A19",
              backgroundColor: material.new || material.edit ? '#5abc6d' : '#e8786a',
            }}
            key={Index}
          >
            <td>{material.description}</td>
            <td>{material.estimated_quantity}</td>
            <td>{material.unit}</td>
            <td>{material.unit_cost}</td>
            <td>{material.estimated_cost}</td>
            <td>{material.ordered_quantity}</td>
            <td>{material.actual_cost}</td>
            <td>{material.balance}</td>
          </tr>
        );
      }
    });
    return (
      <tbody key={Index}>
        <tr
          className="table-borderless"
          style={{
            fontWeight: '600',
            fontSize: '16px',
          }}
        >
          <td colSpan={8} style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between', width: '100%' }}>
            <span style={{ marginRight: '10px', whiteSpace: 'nowrap' }}>{data.phase}</span> {varianceOrderData.length > 1 && <input type="checkbox" style={{ width: '20px', height: '20px' }} onChange={(e) => handleCheckboxChange({ Index, checked: e.target.checked })} />}
          </td>
        </tr>
        <tr
          className="table-borderless"
          style={{
            fontWeight: '600',
            fontSize: '16px',
          }}
        >
          <td colSpan={8}>{data[0]}</td>
        </tr>
        <tr
          style={{
            background: '#F4F4F7',
            fontWeight: '600',
            fontSize: '14px',
          }}
        >
          <td style={{ width: '200px', textAlign: 'center' }}>Designation</td>
          <td>Estimated Workers</td>
          <td>Duration</td>
          <td>Rate</td>
          <td>Estimated Cost</td>
          <td>Hired Workers </td>
          <td>Actual Cost</td>
          <td>Balance</td>
        </tr>
        <tr>
          <td colSpan={8} style={{ textAlign: 'left', fontWeight: '600', fontSize: '16px' }}>
            Labor
          </td>
        </tr>

        {labor}
        <tr
          style={{
            background: '#F4F4F7',
            fontWeight: '600',
            fontSize: '14px',
          }}
        >
          <td style={{ width: '200px', textAlign: 'center' }}>Description</td>
          <td>Estimated Quantity</td>
          <td>Duration</td>
          <td>Rate</td>
          <td>Estimated Cost</td>
          <td>Ordered Quantity </td>
          <td>Actual Cost</td>
          <td>Balance</td>
        </tr>
        <tr>
          <td colSpan={8} style={{ textAlign: 'left', fontWeight: '600', fontSize: '16px' }}>
            Equipment
          </td>
        </tr>
        {equipment}
        <tr
          style={{
            background: '#F4F4F7',
            fontWeight: '600',
            fontSize: '14px',
          }}
        >
          <td style={{ width: '200px', textAlign: 'center' }}>Description</td>
          <td>Estimated Quantity</td>
          <td>Unit</td>
          <td>Unit Cost</td>
          <td>Estimated Cost</td>
          <td>Ordered Quantity </td>
          <td>Actual Cost</td>
          <td>Balance</td>
        </tr>
        <tr>
          <td colSpan={8} style={{ textAlign: 'left', fontWeight: '600', fontSize: '16px' }}>
            Material
          </td>
        </tr>
        {material}
      </tbody>
    );
  });

  const handleDownload = async (project_id, type) => {
    setIsLoading(true);
    await DownloadReport(project_id, type)
      .then(() => {
        setIsLoading(false);
      })
      .catch((error) => {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Something went wrong...',
          confirmButtonColor: '#23303D',
        });
        setIsLoading(false);
      });
  };

  return (
    <>
      {loading ? <Loading /> : ''}
      {/* HIDDEN TEMPORARY - JOHN */}
      {isEstimateRecoToolAllowed && (
        <RiskCalculator
          isOpen={isCalculatorOpen}
          title={calculatorTitle}
          sow={calculatorSow}
          work_item={calculatorWorkItem}
          EstWorkers={EstWorkers}
          durationOnchange={durationOnchange}
          index={index}
          scopeIndex={sowIndex}
          toggleCalculator={toggleCalculator}
          materialName={materialName}
          qtyonchange={qtyonchange}
          eqdurationonchange={eqdurationonchange}
          laborProductivity={laborProductivity}
          equipmentProductivity={equipmentProductivity}
          isSowAdded={isSowAdded}
          updateIsSowAdded={updateIsSowAdded}
          setCalculatorStatus={setCalculatorStatus}
          projectID={props.project_id}
          materialQuantity={materialQuantity}
          matquantityonchange={matquantityonchange}
          areaOrVolumeOnChange={areaOrVolumeOnChange}
          areaOrVolumeUnitOnChange={areaORVolumeUnitOnChange}
        />
      )}
      {/* HIDDEN TEMPORARY - JOHN */}
      <CRow className="table-size">
        <CCol xs="12" sm="12" md="12" lg="12">
          <CCol xs="12" sm="12" md="12" lg="12" style={{ marginTop: '20px', padding: '0px' }}>
            <CRow>
              <CCol xl="6">
                <label class="form-label" for="exampleDataList">
                  Search
                </label>
                <input
                  type="search"
                  class="form-control"
                  id="exampleDataList"
                  list="datalistOptions"
                  placeholder="Type scope of work to search..."
                  onChange={(e) => {
                    console.log('onChange event fired', e.target.value);
                    // setSearch(e.target.value);
                    // searchSow(e.target.value);
                    debouncedSearchSow(e.target.value);
                  }}
                  // onKeyPress={(e) => searchSow(e)}
                />
                <datalist id="datalistOptions">{sowOptionSearch}</datalist>
              </CCol>
              <CCol xl="6">
                <CCol lg="12" style={{ textAlign: 'right', fontSize: '12px' }}>
                  <span>
                    Last edit was made {dateUpdated} by {updatedBy}
                  </span>
                </CCol>
                <CCol lg="12" style={{ textAlign: 'right', fontSize: '12px' }}>
                  <span>Status: {props.estimate_status == 1 ? 'Drafted' : 'Submitted'}</span>
                </CCol>
              </CCol>
            </CRow>

            <CCol lg="12" style={{ marginTop: '10px' }}></CCol>
            <CRow>
              <CCol md="8" lg="8" style={{ padding: '0px' }}>
                <CCol md="3" lg="3">
                  {(permission['create'][0] == 1 || permission['update'][0] == 1) && (
                    <CButton color="aimhi-textlight-neutral" size="sm" block variant="outline" onClick={AddSow}>
                      Add
                    </CButton>
                  )}
                </CCol>
              </CCol>
              <CCol md="4" lg="4" className="float-right">
                <CRow>
                  <CCol md="6" lg="6">
                    {permission['create'][0] == 1 && isDownloadableReportAllowed && (
                      // <CButton
                      //   color="aimhi-textlight-neutral"
                      //   size="sm"
                      //   block
                      //   variant="outline"
                      //   onClick={DownloadEstimate}
                      // >
                      //   Download
                      // </CButton>
                      <CDropdown>
                        <CDropdownToggle block color="aimhi-textlight-neutral" variant="outline" size="sm" disabled={dupadata.length <= 0 || dupadata.some((item) => Object.values(item).some((value) => value === ''))}>
                          {isLoading ? (
                            <>
                              <CSpinner color="#23303D" size="sm" /> Download
                            </>
                          ) : (
                            'Download'
                          )}
                        </CDropdownToggle>
                        <CDropdownMenu block size="sm">
                          <CDropdownItem
                            onClick={() => {
                              handleDownload(props.project_id, 1);
                            }}
                          >
                            Detailed Estimate
                          </CDropdownItem>
                          <CDropdownItem
                            onClick={() => {
                              handleDownload(props.project_id, 2);
                            }}
                          >
                            BOM
                          </CDropdownItem>
                          <CDropdownItem
                            onClick={() => {
                              handleDownload(props.project_id, 3);
                            }}
                          >
                            BOQ
                          </CDropdownItem>
                        </CDropdownMenu>
                      </CDropdown>
                    )}
                  </CCol>

                  {permission['create'][0] == 1 && (
                    <CCol md="6" lg="6">
                      {estimateStatus == 2 ? (
                        <CButton color="aimhi-textlight-neutral" size="sm" block onClick={() => checkVarianceOrder()}>
                          Save Edited
                        </CButton>
                      ) : (
                        <CDropdown>
                          <CDropdownToggle block color="aimhi-textlight-neutral" size="sm">
                            Save as
                          </CDropdownToggle>
                          <CDropdownMenu>
                            {/* <CDropdownItem header>Header</CDropdownItem>
                <CDropdownItem disabled>Action Disabled</CDropdownItem> */}
                            <CDropdownItem onClick={() => SaveSow(1)}>Save as draft</CDropdownItem>
                            {/* <CDropdownDivider /> */}
                            <CDropdownItem onClick={() => SaveSow(2)}>Save as submitted</CDropdownItem>
                          </CDropdownMenu>
                        </CDropdown>
                      )}

                      {/* <CButton
                    color="aimhi-textlight-neutral"
                    size="sm"
                    variant="outline"
                    block
                    onClick={SaveSow}
                  >
                    Save
                  </CButton> */}
                    </CCol>
                  )}
                </CRow>
              </CCol>
            </CRow>
          </CCol>
          {searchLoading ? (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '500px' }}>
              <CSpinner style={{ width: '4rem', height: '4rem' }} variant="grow" />{' '}
              <CLabel className="h2" style={{ marginLeft: '10px', marginTop: '12px' }}>
                Searching...
              </CLabel>
            </div>
          ) : (
            <>
              {printEstimate == false && tbody}
              {dupadata.length == 0 && (
                <div style={{ textAlign: 'center', marginTop: '200px', height: '500px' }}>
                  <CLabel className="h2"> No data found!</CLabel>
                </div>
              )}
              {dupadata.length != 0 && (
                <table
                  className="table table-sm"
                  style={{
                    marginTop: '40px',
                    textAlign: 'justify',
                    fontWeight: 'bold',
                    fontSize: '15px',
                  }}
                >
                  <tr style={{ textAlign: 'right' }}>
                    <th colSpan="4"></th>
                    <th>Estimated Cost</th>
                    <th colSpan="1"></th>
                    <th>Actual Cost</th>
                    <th>Balance</th>
                  </tr>
                  {errorhandling == false && (
                    <tbody>
                      <tr className="table-borderless" style={{ fontSize: '14px' }}>
                        <td colSpan="4">Total Variance Order of Labor </td>

                        <td colSpan="1" style={{ textAlign: 'right' }}>
                          ₱
                          <NumberFormat value={grantTotal?.total_estimated_labor_cost_vo} displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true} style={{ marginLeft: '5px' }} />
                        </td>
                        <td colSpan="1"></td>

                        <td colSpan="1" style={{ textAlign: 'right' }}>
                          ₱
                          <NumberFormat value={grantTotal?.total_actual_labor_cost_vo} displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true} style={{ marginLeft: '5px' }} />
                        </td>

                        <td colSpan="1" style={{ textAlign: 'right' }}>
                          ₱
                          <NumberFormat
                            value={grantTotal?.total_labor_balance_vo}
                            displayType={'text'}
                            thousandSeparator={true}
                            decimalScale={2}
                            fixedDecimalScale={true}
                            style={{
                              marginLeft: '5px',
                              color: grantTotal?.total_labor_balance < 0 ? '#B42A19' : '',
                            }}
                          />
                        </td>
                      </tr>
                      <tr className="table-borderless" style={{ fontSize: '14px' }}>
                        <td colSpan="4">Total Variance Order of Equipment </td>

                        <td colSpan="1" style={{ textAlign: 'right' }}>
                          ₱
                          <NumberFormat value={grantTotal?.total_estimated_equipment_cost_vo} displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true} style={{ marginLeft: '5px' }} />
                        </td>
                        <td colSpan="1"></td>
                        <td colSpan="1" style={{ textAlign: 'right' }}>
                          ₱
                          <NumberFormat value={grantTotal?.total_actual_equipment_cost_vo} displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true} style={{ marginLeft: '5px' }} />
                        </td>
                        <td colSpan="1" style={{ textAlign: 'right' }}>
                          ₱
                          <NumberFormat
                            value={grantTotal?.total_equipment_balance_vo}
                            displayType={'text'}
                            thousandSeparator={true}
                            decimalScale={2}
                            fixedDecimalScale={true}
                            style={{
                              marginLeft: '5px',
                              color: grantTotal?.total_equipment_balance_vo < 0 ? '#B42A19' : '',
                            }}
                          />
                        </td>
                      </tr>

                      <tr className="table-borderless" style={{ fontSize: '14px' }}>
                        <td colSpan="4">Total Variance Order of Material </td>

                        <td colSpan="1" style={{ textAlign: 'right' }}>
                          ₱
                          <NumberFormat value={grantTotal?.total_estimated_material_cost_vo} displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true} style={{ marginLeft: '5px' }} />
                        </td>

                        <td colSpan="1"></td>
                        <td colSpan="1" style={{ textAlign: 'right' }}>
                          ₱
                          <NumberFormat value={grantTotal?.total_actual_material_cost_vo} displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true} style={{ marginLeft: '5px' }} />
                        </td>
                        <td colSpan="1" style={{ textAlign: 'right' }}>
                          ₱
                          <NumberFormat
                            value={grantTotal?.total_material_balance_vo}
                            displayType={'text'}
                            thousandSeparator={true}
                            decimalScale={2}
                            fixedDecimalScale={true}
                            style={{
                              marginLeft: '5px',
                              color: grantTotal?.total_material_balance < 0 ? '#B42A19' : '',
                            }}
                          />
                        </td>
                      </tr>

                      <tr style={{ fontSize: '14px' }}>
                        <td colSpan="4">
                          <CTooltip content="Total variance order cost for the project" placement="right-end" color="white">
                            <span>
                              Total Variance Order Cost
                              <FaRegQuestionCircle
                                style={{
                                  width: '15px',
                                  height: '15px',
                                  marginLeft: '5px',
                                  color: '#757070',
                                }}
                              />
                            </span>
                          </CTooltip>
                        </td>

                        <td colSpan="1" style={{ textAlign: 'right' }}>
                          ₱
                          <NumberFormat value={grantTotal?.estimated_variance_order_cost} displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true} style={{ marginLeft: '5px' }} />
                        </td>
                        <td></td>
                        <td colSpan="1" style={{ textAlign: 'right' }}>
                          ₱
                          <NumberFormat value={grantTotal?.actual_variance_order_cost} displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true} style={{ marginLeft: '5px' }} />
                        </td>

                        <td colSpan="1" style={{ textAlign: 'right' }}>
                          ₱
                          <NumberFormat
                            value={0}
                            displayType={'text'}
                            thousandSeparator={true}
                            decimalScale={2}
                            fixedDecimalScale={true}
                            style={{
                              marginLeft: '5px',
                              color: grantTotal?.total_variance_order_balance < 0 ? '#B42A19' : '',
                            }}
                          />
                        </td>
                      </tr>

                      <tr className="table-borderless">
                        <td colSpan="8"></td>
                      </tr>

                      <tr className="table-borderless" style={{ fontSize: '14px' }}>
                        <td colSpan="4">Total Labor </td>

                        <td colSpan="1" style={{ textAlign: 'right' }}>
                          ₱
                          <NumberFormat value={grantTotal?.total_estimated_labor_cost} displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true} style={{ marginLeft: '5px' }} />
                        </td>
                        <td colSpan="1"></td>

                        <td colSpan="1" style={{ textAlign: 'right' }}>
                          ₱
                          <NumberFormat value={grantTotal?.total_actual_labor_cost} displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true} style={{ marginLeft: '5px' }} />
                        </td>

                        <td colSpan="1" style={{ textAlign: 'right' }}>
                          ₱
                          <NumberFormat
                            value={grantTotal?.total_labor_balance}
                            displayType={'text'}
                            thousandSeparator={true}
                            decimalScale={2}
                            fixedDecimalScale={true}
                            style={{
                              marginLeft: '5px',
                              color: grantTotal?.total_labor_balance < 0 ? '#B42A19' : '',
                            }}
                          />
                        </td>
                      </tr>
                      <tr className="table-borderless" style={{ fontSize: '14px' }}>
                        <td colSpan="4">Total Equipment </td>

                        <td colSpan="1" style={{ textAlign: 'right' }}>
                          ₱
                          <NumberFormat value={grantTotal?.total_estimated_equipment_cost} displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true} style={{ marginLeft: '5px' }} />
                        </td>
                        <td colSpan="1"></td>
                        <td colSpan="1" style={{ textAlign: 'right' }}>
                          ₱
                          <NumberFormat value={grantTotal?.total_actual_equipment_cost} displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true} style={{ marginLeft: '5px' }} />
                        </td>
                        <td colSpan="1" style={{ textAlign: 'right' }}>
                          ₱
                          <NumberFormat
                            value={grantTotal?.total_equipment_balance}
                            displayType={'text'}
                            thousandSeparator={true}
                            decimalScale={2}
                            fixedDecimalScale={true}
                            style={{
                              marginLeft: '5px',
                              color: grantTotal?.total_equipment_balance < 0 ? '#B42A19' : '',
                            }}
                          />
                        </td>
                      </tr>

                      <tr className="table-borderless" style={{ fontSize: '14px' }}>
                        <td colSpan="4">Total Material </td>

                        <td colSpan="1" style={{ textAlign: 'right' }}>
                          ₱
                          <NumberFormat value={grantTotal?.total_estimated_material_cost} displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true} style={{ marginLeft: '5px' }} />
                        </td>

                        <td colSpan="1"></td>
                        <td colSpan="1" style={{ textAlign: 'right' }}>
                          ₱
                          <NumberFormat value={grantTotal?.total_actual_material_cost} displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true} style={{ marginLeft: '5px' }} />
                        </td>
                        <td colSpan="1" style={{ textAlign: 'right' }}>
                          ₱
                          <NumberFormat
                            value={grantTotal?.total_material_balance}
                            displayType={'text'}
                            thousandSeparator={true}
                            decimalScale={2}
                            fixedDecimalScale={true}
                            style={{
                              marginLeft: '5px',
                              color: grantTotal?.total_material_balance < 0 ? '#B42A19' : '',
                            }}
                          />
                        </td>
                      </tr>

                      <tr style={{ fontSize: '14px' }}>
                        <td colSpan="4">
                          <CTooltip content="Total Direct Cost = Total Labor Cost + Total Equipment Cost + Total Material Cost + Total Variance Order Cost" placement="right-end" color="white">
                            <span>
                              Total Direct Cost
                              <FaRegQuestionCircle
                                style={{
                                  width: '15px',
                                  height: '15px',
                                  marginLeft: '5px',
                                  color: '#757070',
                                }}
                              />
                            </span>
                          </CTooltip>
                        </td>

                        <td colSpan="1" style={{ textAlign: 'right' }}>
                          ₱
                          <NumberFormat value={grantTotal?.estimated_total_direct_cost} displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true} style={{ marginLeft: '5px' }} />
                        </td>
                        <td colSpan="1"></td>

                        <td colSpan="1" style={{ textAlign: 'right' }}>
                          ₱
                          <NumberFormat value={grantTotal?.actual_total_direct_cost} displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true} style={{ marginLeft: '5px' }} />
                        </td>
                        <td colSpan="1" style={{ textAlign: 'right' }}>
                          ₱
                          <NumberFormat
                            value={grantTotal?.total_direct_cost_balance}
                            displayType={'text'}
                            thousandSeparator={true}
                            decimalScale={2}
                            fixedDecimalScale={true}
                            style={{
                              marginLeft: '5px',
                              color: grantTotal?.total_direct_cost_balance < 0 ? '#B42A19' : '',
                            }}
                          />
                        </td>
                      </tr>
                      <tr className="table-borderless">
                        <td colSpan="8"></td>
                      </tr>

                      <tr className="table-borderless" style={{ fontSize: '14px' }}>
                        <td colSpan="4">
                          <CTooltip content={`Overhead, contingencies, and miscellaneous = ${grantTotal?.ocm_percentage}% of Total Actual Direct Cost`} placement="right-end" color="white">
                            <span>
                              OCM
                              <FaRegQuestionCircle
                                style={{
                                  width: '15px',
                                  height: '15px',
                                  marginLeft: '5px',
                                  color: '#757070',
                                }}
                              />
                            </span>
                          </CTooltip>
                        </td>

                        <td colSpan="1" style={{ textAlign: 'right' }}>
                          <NumberFormat value={grantTotal?.estimated_ocm} displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true} style={{ marginLeft: '5px' }} />
                        </td>
                        <td></td>
                        <td colSpan="1" style={{ textAlign: 'right' }}>
                          <NumberFormat value={grantTotal?.actual_ocm} displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true} style={{ marginLeft: '5px' }} />
                        </td>
                        <td colSpan="1" style={{ textAlign: 'right' }}>
                          <NumberFormat
                            value={grantTotal?.ocm_balance}
                            displayType={'text'}
                            thousandSeparator={true}
                            decimalScale={2}
                            fixedDecimalScale={true}
                            style={{
                              marginLeft: '5px',
                              color: grantTotal?.ocm_balance < 0 ? '#B42A19' : '',
                            }}
                          />
                        </td>
                      </tr>

                      {hasVAT != 2 /**************************** CHANGED FROM "hasVAT != 1" TO "hasVAT != 2" ********************************/ && (
                        <tr className="table-borderless" style={{ fontSize: '14px' }}>
                          <td colSpan="4">
                            <CTooltip content="Value Added Tax = 12% of (Total Actual Direct Cost + OCM + CP)" placement="right-end" color="white">
                              <span>
                                VAT
                                <FaRegQuestionCircle
                                  style={{
                                    width: '15px',
                                    height: '15px',
                                    marginLeft: '5px',
                                    color: '#757070',
                                  }}
                                />
                              </span>
                            </CTooltip>
                          </td>

                          <td colSpan="1" style={{ textAlign: 'right' }}>
                            <NumberFormat value={grantTotal?.estimated_vat} displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true} style={{ marginLeft: '5px' }} />
                          </td>
                          <td></td>
                          <td colSpan="1" style={{ textAlign: 'right' }}>
                            <NumberFormat value={grantTotal?.actual_vat} displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true} style={{ marginLeft: '5px' }} />
                          </td>

                          <td colSpan="1" style={{ textAlign: 'right' }}>
                            <NumberFormat
                              value={grantTotal?.vat_balance}
                              displayType={'text'}
                              thousandSeparator={true}
                              decimalScale={2}
                              fixedDecimalScale={true}
                              style={{
                                marginLeft: '5px',
                                color: grantTotal?.vat_balance < 0 ? '#B42A19' : '',
                              }}
                            />
                          </td>
                        </tr>
                      )}

                      <tr className="table-borderless" style={{ fontSize: '14px' }}>
                        <td colSpan="4">
                          <CTooltip content={`Contractor’s Profit = ${grantTotal?.cp_percenteage}% of Total Actual Direct Cost`} placement="right-end" color="white">
                            <span>
                              CP
                              <FaRegQuestionCircle
                                style={{
                                  width: '15px',
                                  height: '15px',
                                  marginLeft: '5px',
                                  color: '#757070',
                                }}
                              />
                            </span>
                          </CTooltip>
                        </td>

                        <td colSpan="1" style={{ textAlign: 'right' }}>
                          <NumberFormat value={grantTotal?.estimated_cp} displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true} style={{ marginLeft: '5px' }} />
                        </td>
                        <td></td>
                        <td colSpan="1" style={{ textAlign: 'right' }}>
                          <NumberFormat value={grantTotal?.actual_cp} displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true} style={{ marginLeft: '5px' }} />
                        </td>

                        <td colSpan="1" style={{ textAlign: 'right' }}>
                          <NumberFormat
                            value={grantTotal?.cp_balance}
                            displayType={'text'}
                            thousandSeparator={true}
                            decimalScale={2}
                            fixedDecimalScale={true}
                            style={{
                              marginLeft: '5px',
                              color: grantTotal?.cp_balance < 0 ? '#B42A19' : '',
                            }}
                          />
                        </td>
                      </tr>

                      <tr style={{ fontSize: '14px' }}>
                        <td colSpan="4">
                          <CTooltip content="Total Indirect Cost = OCM + CP + VAT" placement="right-end" color="white">
                            <span>
                              Total Indirect Cost
                              <FaRegQuestionCircle
                                style={{
                                  width: '15px',
                                  height: '15px',
                                  marginLeft: '5px',
                                  color: '#757070',
                                }}
                              />
                            </span>
                          </CTooltip>
                        </td>

                        <td colSpan="1" style={{ textAlign: 'right' }}>
                          ₱
                          <NumberFormat value={grantTotal?.estimated_total_indirect_cost} displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true} style={{ marginLeft: '5px' }} />
                        </td>
                        <td></td>
                        <td colSpan="1" style={{ textAlign: 'right' }}>
                          ₱
                          <NumberFormat value={grantTotal?.actual_total_indirect_cost} displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true} style={{ marginLeft: '5px' }} />
                        </td>

                        <td colSpan="1" style={{ textAlign: 'right' }}>
                          ₱
                          <NumberFormat
                            value={grantTotal?.total_indirect_cost_balance}
                            displayType={'text'}
                            thousandSeparator={true}
                            decimalScale={2}
                            fixedDecimalScale={true}
                            style={{
                              marginLeft: '5px',
                              color: grantTotal?.total_indirect_cost_balance < 0 ? '#B42A19' : '',
                            }}
                          />
                        </td>
                      </tr>

                      <tr className="table-borderless" style={{ fontSize: '14px' }}>
                        <td colSpan="4">
                          <CTooltip content=" Total Contract Cost = Total Actual Direct Cost + Total Indirect Cost" placement="right-end" color="white">
                            <span>
                              Total Contract Cost
                              <FaRegQuestionCircle
                                style={{
                                  width: '15px',
                                  height: '15px',
                                  marginLeft: '5px',
                                  color: '#757070',
                                }}
                              />
                            </span>
                          </CTooltip>
                        </td>

                        <td colSpan="1" style={{ textAlign: 'right' }}>
                          ₱
                          <NumberFormat value={grantTotal?.estimated_total_contract_cost} displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true} style={{ marginLeft: '5px' }} ode />
                        </td>
                        <td></td>
                        <td colSpan="1" style={{ textAlign: 'right' }}>
                          ₱
                          <NumberFormat value={grantTotal?.actual_total_contract_cost} displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true} style={{ marginLeft: '5px' }} />
                        </td>

                        <td colSpan="1" style={{ textAlign: 'right' }}>
                          ₱
                          <NumberFormat
                            value={grantTotal?.total_contract_cost_balance}
                            displayType={'text'}
                            thousandSeparator={true}
                            decimalScale={2}
                            fixedDecimalScale={true}
                            style={{
                              marginLeft: '5px',
                              color: grantTotal?.total_contract_cost_balance < 0 ? '#B42A19' : '',
                            }}
                          />
                        </td>
                      </tr>

                      <tr className="table-borderless">
                        <td> </td>
                      </tr>
                      <tr className="table-borderless">
                        <td> </td>
                      </tr>
                      <tr>
                        <td
                          colSpan="8"
                          style={{
                            borderBottom: '5px solid #549764',
                            borderTop: '1px solid white',
                          }}
                        ></td>
                      </tr>
                    </tbody>
                  )}
                </table>
              )}
            </>
          )}

          {printEstimate == true && (
            <table className="table" id="my-table">
              <PrintEstimate value={dupadata} />
            </table>
          )}
        </CCol>
      </CRow>

      {/* <EquipmentModal modal={modal} setModal={setModal} onCreateOption={true} equipmentName={equipmentName}></EquipmentModal> */}
      <CModal show={modalCategory} onClose={() => onCloseCategory()} size="md" style={{ borderRadius: '5px' }} centered>
        <CModalHeader closeButton style={{ borderColor: 'white' }}>
          <CModalTitle>Add New Equipment Category</CModalTitle>
        </CModalHeader>

        <CCol sm="12">
          <CForm action="" method="post">
            <CFormGroup>
              <CLabel>Equipment Category</CLabel>
              <CInput value={category} onChange={(e) => setCategory(e.target.value)} placeholder="Enter Equipment Category.." />
            </CFormGroup>
            {/* <CFormGroup>
              <CLabel>Description</CLabel>
              <CInput value={description} onChange={(e) => setDescription(e.target.value)} placeholder="Enter Description.." />
            </CFormGroup> */}
          </CForm>
        </CCol>

        <CModalFooter style={{ borderColor: 'white' }}>
          <CCol md="4" sm="4" lg="4" xl="4">
            <CButton block variant="outline" color="aimhi-textlight-neutral" size="sm" onClick={() => submitCategory()} disabled={!category}>
              Submit
            </CButton>
          </CCol>
        </CModalFooter>
      </CModal>
      <CModal show={modalPosition} onClose={() => onClosePosition()} size="md" style={{ borderRadius: '5px' }} centered>
        <CModalHeader closeButton style={{ borderColor: 'white' }}>
          <CModalTitle>Add New Manpower Position</CModalTitle>
        </CModalHeader>

        <CCol sm="12">
          <CForm action="" method="post">
            <CFormGroup>
              <CLabel>Manpower Position</CLabel>
              <CInput value={position} onChange={(e) => setPosition(e.target.value)} placeholder="Enter Manpower Position.." />
            </CFormGroup>
            <CFormGroup>
              <CLabel>Description</CLabel>
              <CInput value={positionDescription} onChange={(e) => setPositionDescription(e.target.value)} placeholder="Enter Description.." />
            </CFormGroup>
          </CForm>
        </CCol>

        <CModalFooter style={{ borderColor: 'white' }}>
          <CCol md="4" sm="4" lg="4" xl="4">
            <CButton block variant="outline" color="aimhi-textlight-neutral" size="sm" onClick={() => submitPosition()} disabled={!position}>
              Submit
            </CButton>
          </CCol>
        </CModalFooter>
      </CModal>
      <CModal show={modalVarianceOrder} onClose={() => setModalVarianceOrder(!modalVarianceOrder)} size="xl" style={{ borderRadius: '5px' }} centered>
        <CModalHeader closeButton style={{ borderColor: 'white' }}>
          {varianceOrderData.length > 1 ? (
            <>
              <CModalTitle style={{ fontSize: '28px', paddingLeft: '20px', paddingTop: '20px', paddingBottom: '0px' }}>
                Are these variance orders? <h6 style={{ fontStyle: 'italic' }}> Check the checkbox next to the scope of work if it's a variance order.</h6>
              </CModalTitle>
            </>
          ) : (
            <CModalTitle style={{ fontSize: '28px', paddingLeft: '20px', paddingTop: '20px', paddingBottom: '0px' }}>Is this a variance order?</CModalTitle>
          )}
        </CModalHeader>

        <CCol sm="12" style={{ padding: '20px', maxHeight: '600px', overflow: 'auto' }}>
          <table className="table">{varianceOrderPreview}</table>
        </CCol>

        <CModalFooter style={{ borderColor: 'white', display: 'flex', justifyContent: 'flex-end', marginBottom: '20px' }}>
          <CCol md="8" sm="8" lg="8" xl="8">
            {/* <CRow style={{ width: '100%', justifyContent: 'flex-end' }}>
                <CButton
                  style={{ marginRight: '20px', width: '20%' }}
                  size="md"
                  variant="outline"
                  color="aimhi-textlight-neutral"
                  onClick={() => {
                    SaveSow(2);
                    setModalVarianceOrder(!modalVarianceOrder);
                  }}
                >
                  Yes
                </CButton>
                <CButton style={{ width: '20%' }} size="md" variant="outline" color="aimhi-textlight-neutral" onClick={() => {}}>
                  No
                </CButton>
              </CRow> */}
            {varianceOrderData.length > 1 ? (
              <CRow style={{ width: '100%', justifyContent: 'flex-end' }}>
                <CButton
                  style={{ width: '20%' }}
                  size="md"
                  color="aimhi-textlight-neutral"
                  variant="outline"
                  onClick={() => varianceOrderSubmit()} // Replace with your submission logic
                >
                  Submit
                </CButton>
              </CRow>
            ) : (
              <CRow style={{ width: '100%', justifyContent: 'flex-end' }}>
                <CButton style={{ marginRight: '20px', width: '20%' }} size="md" variant="outline" color="aimhi-textlight-neutral" onClick={() => varianceOrderYes()}>
                  Yes
                </CButton>
                <CButton style={{ width: '20%' }} size="md" variant="outline" color="aimhi-textlight-neutral" onClick={() => varianceOrderNo()}>
                  No
                </CButton>
              </CRow>
            )}
          </CCol>
        </CModalFooter>
      </CModal>
    </>
  );
};

export default ProjectBudgetDetail;
