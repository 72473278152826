import { Alert } from '@coreui/coreui';
import $axios from 'src/utils/axios';
import IP from 'src/StoreGlobal/IP';

let companyId = parseInt(localStorage.getItem('company_id'));
let accessToken = localStorage.getItem('token');
let user_id = parseInt(localStorage.getItem('user_id'));

export const EquipmentRequestHistoryDetailsEdit = (data) => {
  return $axios.put(`equipment/request-history/details/edit`, {
    ...data,
  });
};

export const EquipmentRequestHistoryEdit = (companyId, projectId, data) => {
  return $axios.put(`equipment/request-history/${companyId}/${projectId}`);
};

export const GetTotalFuelOrder = (projectId) => {
  return $axios.get(`equipment/fuel/total-order/${projectId}`);
};

// Equipment Module

export const GetEquipmentMasterList = (company_id, page = 1, per_page = 10, search = null, archived = null) => {
  return $axios.get(`equipment/master/list`, { params: { company_id, page, per_page, search, archived } });
};

export const getEquipmentCategory = ( page = 1, limit = 10, search_category ='') => {
  return $axios.get(`equipment/category/list?page=${page}&limit=${limit}&search_category=${search_category}`);
}

export const archiveAllEquipment = () => {
  const data = { type_id: 3}
  return $axios.post(`project/archive/bulk`, data);
}