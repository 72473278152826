import React, { useState, useEffect } from 'react';
import { CButton, CCard, CCol, CRow, CInput, CSelect, CProgress, CProgressBar, CModal, CModalBody, CModalFooter, CModalHeader, CModalTitle, CTooltip, CInvalidFeedback } from '@coreui/react';
import { FaRegImage } from 'react-icons/fa';
import 'react-circular-progressbar/dist/styles.css';
import './ProjectDetails.css';
import { useHistory } from 'react-router-dom';
import NumberFormat from 'react-number-format';
import Loading from '../aimhi-widgets/Loading';
import LoadingLottie from '../../json/UPLODING';
import Lottie from 'react-lottie';
import { useSelector, useDispatch } from 'react-redux';
import { regions, provinces, cities, barangays } from 'select-philippines-address';
import { GetOverviewData, SaveOverview, UploadImage } from 'src/request/OverView';
import { ArchivesRestore, GetProjectCategory, GetProjectType } from 'src/request/Projects';
import { GetPosition } from 'src/request/Manpower';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { useLocation } from 'react-router-dom';
import { FaRegQuestionCircle } from 'react-icons/fa';

const ProjectOverview = (props) => {
  const history = useHistory();
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: LoadingLottie,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  const company_id = parseInt(localStorage.getItem('company_id'));
  let accessToken = localStorage.getItem('token');
  let user_id = parseInt(localStorage.getItem('user_id'));
  const Swal = require('sweetalert2');
  const [loading, setLoading] = useState(true);
  const [projectInfo, setprojectInfo] = useState({});
  const [projectCost, setprojectCost] = useState([]);
  const [projectTeam, setprojectTeam] = useState([]);

  const [keyDates, setkeyDates] = useState({});
  const [position, setposition] = useState([]);
  const [selectedPosition, setselectedPosition] = useState('');
  const [teamName, setteamName] = useState('');
  const [listName, setlistName] = useState([]);
  const [companyName, setcompanyName] = useState('');
  const [clientName, setclientName] = useState('');
  const [phoneNumber, setphoneNumber] = useState('');
  const [email, setemail] = useState('');
  const [projectManager, setprojectManager] = useState('');
  const [siteEngineer, setsiteEngineer] = useState('');
  const [architect, setarchitect] = useState('');
  const [headForeman, setheadForeman] = useState('');
  const [masterPlumber, setmasterPlumber] = useState('');
  const [electricalEngineer, setelectricalEngineer] = useState('');
  const [structuralEngineer, setstructuralEngineer] = useState('');
  const [imageupload, setimageupload] = useState([]);
  const [image, setimage] = useState([]);
  const [checkupload, setcheckupload] = useState(true);
  const [projectInspector, setProjectInspector] = useState('');
  const [proposalSubmission, setProposalSubmission] = useState();
  const [kickOffStart, setKickOffStart] = useState();
  const [turnOver, setTurnOver] = useState();
  const [ocm, setOcm] = useState('');
  const [cp, setCp] = useState('');
  const [hasVat, setHasVat] = useState('');
  const [regionData, setRegion] = useState([]);
  const [provinceData, setProvince] = useState([]);
  const [cityData, setCity] = useState([]);
  const [barangayData, setBarangay] = useState([]);
  const [projectcategory, setProjectCategory] = useState('');
  const [regionAddr, setRegionAddr] = useState('');
  const [provinceAddr, setProvinceAddr] = useState('');
  const [cityAddr, setCityAddr] = useState('');
  const [barangayAddr, setBarangayAddr] = useState('');
  const [streetAddr, setStreetAddr] = useState('');
  const [projectCategoryData, setProjectCategoryData] = useState([]);
  const [customerContactEdit, setcustomerContactEdit] = useState(false);
  const [modal, setModal] = useState(false);
  const [editBilling, setEditBilling] = useState(true);
  const [projectType, setProjectType] = useState([]);
  const [projectTypeData, setprojectTypeData] = useState([]);
  const [contratAmount, setContractAmount] = useState('');
  const [sourcefund, setSourceFund] = useState('');
  const [projectstart, setProjectStart] = useState('');
  const [nodays, setNoDays] = useState('');
  const [bidCategory, setBidCategory] = useState('');
  const [grantTotal, setgrantTotal] = useState({});
  const [reconciliationCycle, setReconciliationCycle] = useState('');

  let permission = useSelector((state) => state.permission);

  const location = useLocation();
  const { project_id } = location.state || {}; // Access project_id from location.state

  // Check if props.project_id is defined
  const idToUse = props.project_id !== undefined ? props.project_id : project_id;

  useEffect(() => {
    // getoverview(props.project_id);
    getoverview(idToUse);
    region();
    ProjectType();
    ProjectCategory();
    console.log('today', projectstart, today);
    console.log('project_id', idToUse);
  }, [idToUse]);

  var now = new Date(projectstart);

  var day = ('0' + now.getDate()).slice(-2);
  var month = ('0' + (now.getMonth() + 1)).slice(-2);

  var today = now.getFullYear() + '-' + month + '-' + day;

  useEffect(() => {}, [permission]);

  const adjustDateFormat = (strDate) => {
    if (strDate) {
      let a = new Date(strDate);

      console.log(a, 'hir');

      let sm = `${a.getMonth() + 1}`.padStart(2, '0');
      let sd = a.getDate();
      let sy = a.getFullYear();
      let formatted = `${sm}/${sd}/${sy}`;
      return formatted;
    }

    return '';
  };

  const region = () => {
    regions().then((response) => {
      setRegion(response);
      console.log(response);
    });
  };

  const province = (e) => {
    let arr = [...regionData];
    let Index = arr.findIndex((a) => a.region_name == e);
    // let code = arr[Index].region_code;

    // Check if Index is valid
    if (Index !== -1 && arr[Index]) {
      let code = arr[Index].region_code;

      setRegionAddr(e);

      provinces(code).then((response) => {
        setProvince(response);
        setProvinceAddr('');
        setCityAddr('');
        setBarangayAddr('');
        setCity([]);
        setBarangay([]);
      });
    } else {
      setRegionAddr('');
    }

    // setRegionAddr(e);

    // provinces(code).then((response) => {
    //   setProvince(response);
    //   setCity([]);
    //   setBarangay([]);
    // });
  };

  const city = (e) => {
    let arr = [...provinceData];
    let Index = arr.findIndex((a) => a.province_name == e);
    // Check if Index is valid
    if (Index !== -1 && arr[Index]) {
      let code = arr[Index].province_code;
      setProvinceAddr(e);
      cities(code).then((response) => {
        setCity(response);
      });
    } else {
      setProvinceAddr('');
    }
  };

  const barangay = (e) => {
    let arr = [...cityData];
    let Index = arr.findIndex((a) => a.city_name == e);

    if (Index !== -1 && arr[Index]) {
      console.log(Index);
      let code = arr[Index].city_code;
      setCityAddr(e);
      console.log(code);
      barangays(code).then((response) => {
        console.log(response);
        setBarangay(response);
      });
    } else {
      setCityAddr('');
    }
  };

  const brgy = (e) => {
    setBarangayAddr(e);
  };

  const getAddress = (valReg, valProv, valCity, valBrgy) => {
    console.log(valReg, valProv, valCity, valBrgy);
    if (valReg != '') {
      regions().then((region) => {
        let Index = region.findIndex((a) => a.region_name.toLowerCase() == valReg.toLowerCase());
        setRegion(region);
        if (Index != -1) {
          provinces(region[Index].region_code).then((province) => {
            console.log(province);
            let ProvIndex = province.findIndex((a) => a.province_name.toLowerCase() == valProv.toLowerCase());

            setProvince(province);
            if (ProvIndex != -1) {
              cities(province[ProvIndex].province_code).then((city) => {
                console.log(city);
                let cityIndex = city.findIndex((e) => e.city_name.toLowerCase() == valCity.toLowerCase());
                setCity(city);
                if (cityIndex != -1) {
                  barangays(city[cityIndex].city_code).then((barangay) => {
                    setBarangay(barangay);
                  });
                }
              });
            }
          });
        }
      });
    }
  };

  const validateData = () => {
    if (
      architect != '' &&
      companyName != '' &&
      clientName != '' &&
      phoneNumber != '' &&
      email != '' &&
      projectcategory != '' &&
      projectType != '' &&
      sourcefund != '' &&
      projectstart != '' &&
      nodays != '' &&
      regionAddr != '' &&
      provinceAddr != '' &&
      cityAddr != '' &&
      barangayAddr != '' &&
      bidCategory != ''
    ) {
      setcustomerContactEdit(true);
      props.validate(false);
    } else {
      setcustomerContactEdit(false);
      props.validate(true);
    }
  };

  const getoverview = (project_id) => {
    GetOverviewData(project_id)
      .then((res) => {
        // alert('trigger here')
        console.log(res.data.response, 'project overview');
        setimage(res.data.response.images);

        // setimage(images);
        setprojectInfo(res.data.response.project_info);
        setprojectCost(res.data.response.project_cost);
        setprojectManager(res.data.response.project_team.project_manager);
        setsiteEngineer(res.data.response.project_team.site_engineer);
        setarchitect(res.data.response.project_team.architect);
        setheadForeman(res.data.response.project_team.head_foreman);
        setmasterPlumber(res.data.response.project_team.master_plumber);
        setelectricalEngineer(res.data.response.project_team.electrical_engineer);
        setstructuralEngineer(res.data.response.project_team.structural_engineer);
        setProjectInspector(res.data.response.project_team.inspector);
        setclientName(res.data.response.customer_contact.client_name);
        setcompanyName(res.data.response.customer_contact.company_name);
        setphoneNumber(res.data.response.customer_contact.phone_number);
        setemail(res.data.response.customer_contact.email);
        // setprojectTeam(res.data.response.project_team);
        // setcustomerContact(res.data.response.customer_contact);

        setkeyDates(res.data.response.key_dates);
        setProposalSubmission(res.data.response.key_dates.proposal_submission);
        setKickOffStart(res.data.response.key_dates.kick_off_start);
        setTurnOver(res.data.response.key_dates.turn_over);
        setOcm(res.data.response.project_info.ocm);
        setCp(res.data.response.project_info.cp);
        setHasVat(res.data.response.project_info.has_vat);
        setContractAmount(res.data.response.project_info.contract_amount);
        getposition(project_id);

        setRegionAddr(res.data.response.project_info.region);
        setProvinceAddr(res.data.response.project_info.province);
        setCityAddr(res.data.response.project_info.city);
        setBarangayAddr(res.data.response.project_info.barangay);
        setStreetAddr(res.data.response.project_info.street);
        setProjectCategory(res.data.response.project_info.project_category_id);
        setProjectType(res.data.response.project_info.project_type_id);
        setBidCategory(res.data.response.project_info.bid_category);
        setProjectStart(res.data.response.project_info.start_date);
        setSourceFund(res.data.response.project_info.source_of_funds);
        setNoDays(res.data.response.project_info.duration);
        setReconciliationCycle(res.data.response.project_info.reconciliation_cycle);

        let textRegion = res.data.response.project_info.region;
        let textProvince = res.data.response.project_info.province;
        let textCity = res.data.response.project_info.city;
        let textBrgy = res.data.response.project_info.barangay;
        console.log(res.data.response);
        getAddress(textRegion, textProvince, textCity, textBrgy);

        // province(res.data.response.project_info.region);

        setLoading(false);
        validateData();

        // alert('trigger end')
      })
      .catch((error) => {
        console.log('projectdetails/ProjectOverview.js', ' ', 'line 155', error);
      });
  };

  // auto save for project team and customer contact
  const saveOverview = (project_id) => {
    // changing projectstart format to yyyy-mm-dd
    const projectstart_date = new Date(projectstart);
    const projectstart_year = projectstart_date.getFullYear();
    const projectstart_month = String(projectstart_date.getMonth() + 1).padStart(2, '0');
    const projectstart_day = String(projectstart_date.getDate()).padStart(2, '0');
    const projectstart_formattedDate = `${projectstart_year}-${projectstart_month}-${projectstart_day}`;

    // changing proposalSubmission format to yyyy-mm-dd
    let proposalSubmission_formattedDate = null;

    if (proposalSubmission) {
      const proposalSubmission_date = new Date(proposalSubmission);
      const proposalSubmission_year = proposalSubmission_date.getFullYear();
      const proposalSubmission_month = String(proposalSubmission_date.getMonth() + 1).padStart(2, '0');
      const proposalSubmission_day = String(proposalSubmission_date.getDate()).padStart(2, '0');
      proposalSubmission_formattedDate = `${proposalSubmission_year}-${proposalSubmission_month}-${proposalSubmission_day}`;
    }

    // changing kickOffStart format to yyyy-mm-dd
    let kickOffStart_formattedDate = null;

    if (kickOffStart) {
      const kickOffStart_date = new Date(kickOffStart);
      const kickOffStart_year = kickOffStart_date.getFullYear();
      const kickOffStart_month = String(kickOffStart_date.getMonth() + 1).padStart(2, '0');
      const kickOffStart_day = String(kickOffStart_date.getDate()).padStart(2, '0');
      kickOffStart_formattedDate = `${kickOffStart_year}-${kickOffStart_month}-${kickOffStart_day}`;
    }

    // changing turnOver format to yyyy-mm-dd
    let turnOver_formattedDate = null;

    if (turnOver) {
      const turnOver_date = new Date(turnOver);
      const turnOver_year = turnOver_date.getFullYear();
      const turnOver_month = String(turnOver_date.getMonth() + 1).padStart(2, '0');
      const turnOver_day = String(turnOver_date.getDate()).padStart(2, '0');
      turnOver_formattedDate = `${turnOver_year}-${turnOver_month}-${turnOver_day}`;
    }

    let data = {
      project_manager: projectManager,
      site_engineer: siteEngineer,
      architect: architect,
      head_foreman: headForeman,
      master_plumber: masterPlumber,
      electrical_engineer: electricalEngineer,
      structural_engineer: structuralEngineer,
      inspector: projectInspector,
      client_company: companyName,
      client_name: clientName,
      client_contact: phoneNumber,
      client_email: email,
      // submission_date: proposalSubmission,
      submission_date: proposalSubmission_formattedDate,
      // kickoff_date: kickOffStart,
      kickoff_date: kickOffStart_formattedDate,
      // turnover_date: turnOver,
      turnover_date: turnOver_formattedDate,
      region: regionAddr,
      province: provinceAddr,
      city: cityAddr,
      barangay: barangayAddr,
      street: streetAddr,
      project_type_id: projectType,
      bid_category: bidCategory,
      project_category_id: projectcategory,
      user_id: user_id,
      source_of_funds: sourcefund,
      // start_date: projectstart,
      start_date: projectstart_formattedDate,
      duration: nodays == '' ? 0 : nodays,
      reconciliation_cycle: reconciliationCycle,
    };

    console.log('before save data: ', data);

    SaveOverview(data, project_id)
      .then((res) => {
        console.log(res);
        if (res.data.status == 1) {
          validateData();
          props.refreshTabStatus(project_id); // callback from ProjectDetails.js
          getoverview(props.project_id);
        }
      })
      .catch((error) => {
        console.log('projectdetails/ProjectOverview.js', ' ', 'line 172', error);
      });
  };

  const SaveBilling = (project_id) => {
    let data = {
      ocm: ocm,
      cp: cp,
      has_vat: hasVat,
      user_id: user_id,
      total_budget: contratAmount,
    };

    SaveOverview(data, project_id)
      .then((res) => {
        console.log(res);
        if (res.data.status == 1) {
          validateData();
          getoverview(props.project_id);
        }
      })
      .catch((error) => {
        console.log('projectdetails/ProjectOverview.js', ' ', 'line 172', error);
      });
  };
  const getposition = (project_id) => {
    GetPosition(project_id)
      .then((res) => {
        let response = res.data.response;
        let arr = [];

        for (var i in response) {
          arr.push({
            position: response[i].position,
            dropdown: response[i].dropdown,
          });
        }
        setposition(arr);
      })
      .catch((error) => {
        console.log('projectdetails/ProjectOverview.js', ' ', 'line 198', error);
      });
  };

  const arhiveproject = (id, projectname) => {
    Swal.fire({
      title: 'Archive',
      html: "<p>Archiving a project causes it to be archived for all participants.</p><p>Archived project can't be modified by users without admin privilege's.</p>  <p>This project will move to your Archived projects.</p>",
      // icon: "warning",
      showCancelButton: true,
      confirmButtonColor: '#23303D',
      cancelButtonColor: '#B42A19',
      confirmButtonText: 'Yes, archive it!',
    }).then((result) => {
      if (result.isConfirmed) {
        ArchivesRestore(id, 'delete')
          .then(function (response) {
            if (response.data.status == 1) {
              history.push({
                pathname: '../projects/Projects',
              });
              Swal.fire('Success', 'succesfully archive', 'success');
            } else {
            }
          })
          .catch((error) => {
            console.log('projects/Projects.js', ' ', 'line 181', error);
          });
      }
    });
  };
  const positionOnchange = (e) => {
    let pos = [...position];
    let filter = pos.filter((a) => a.position == e.target.value);

    setselectedPosition(e.target.value);
    setlistName(filter[0].dropdown);
  };

  const upload = (event) => {
    // const objectUrl = URL.createObjectURL(event.target.files);
    let arr = [];
    let data = new FormData();
    // let sample = [];
    setcheckupload(false);
    // for (var i in event.target.files) {
    //   arr.push(event.target.files[i]);
    // }

    // let batch = [];
    // let counter = 0;
    // let batch_count = 4;

    var percentCompleted = 0;
    // while (counter < arr.length) {
    //   let data = new FormData();
    //   let a = arr.slice(counter, counter + batch_count);
    //   data.append("project_id", props.project_id);
    //   data.append("company_id", company_id);
    //   for (var i in a) {
    //     console.log(a[i], "overview");
    //     data.append("file", a[i]);
    //     batch.push(a[i]);
    //   }

    // counter += batch_count;
    // data.append("project_id", props.project_id);
    // data.append("company_id", company_id);
    data.append('file', event.target.files[0]);
    
    var config = {
      onUploadProgress: function (progressEvent) {
        percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      },
    };

    UploadImage(data, config, props.project_id)
      .then((res) => {
        data = new FormData();

        // Ash Edit: If upload is successful
        if (res.data.status == 1) {
          setcheckupload(true);
          getoverview(props.project_id);
          Swal.fire({
            icon: 'success',
            title: 'Image uploaded successfully!',
            confirmButtonColor: '#23303D',
          });
        } else {
          Swal.fire({
            icon: 'error',
            title: res.data.response,
            text: res.data.description,
            confirmButtonColor: '#23303D',
          });
        }
      })
      .catch((error) => {
        console.log('projectdetails/ProjectOverview.js', ' ', 'line 267', error);
      });
    // }

    // NOTE: THIS IF-SECTION HAS NO USE
    if (percentCompleted == 100) {
      setcheckupload(true);
    }

    // setModal(true);
  };

  const ProjectType = () => {
    GetProjectType()
      .then((res) => {
        if (res.data.status == 1) {
          console.log(res.data.response);
          setprojectTypeData(res.data.response);
        } else {
          Swal.fire({
            icon: 'error',
            title: res.data.response,
            text: res.data.description,
            confirmButtonColor: '#23303D',
            // footer: '<a href="">Why do I have this issue?</a>',
          });
        }

        // setprojectData(res.data);
        // setLoading(false);
      })
      .catch((error) => {
        console.log('projects/AddProject.js', ' ', 'line 147', error);
      });
  };

  // const addProjectTeam = () => {
  //   let data = {
  //     project_id: props.project_id,
  //     position: selectedPosition,
  //     name: teamName,
  //   };

  //   axios
  //     .post(IP + "change this to your route", {
  //       data,
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //     })
  //     .then((res) => {
  //       let arr = [...projectTeam];

  //       arr.push({
  //         position: selectedPosition,
  //         name: teamName,
  //       });

  //       setprojectTeam(arr);
  //     })
  //     .catch((error) => {});
  // };

  // const customerOnchange = (e) => {
  //   customerContact["company_name"] = e.target.value;
  // };

  // const OptionPosition = position.map((pos, Index) => {
  //   return (
  //     <option key={Index} value={pos.position}>
  //       {pos.position}
  //     </option>
  //   );
  // });

  // const optionName = listName.map((name, Index) => {
  //   return (
  //     <option key={Index} value={name.label}>
  //       {name.label}
  //     </option>
  //   );
  // });

  const ProjectCategory = () => {
    GetProjectCategory()
      .then((res) => {
        if (res.data.status == 1) {
          console.log(res);
          setProjectCategoryData(res.data.response);
        } else {
          Swal.fire({
            icon: 'error',
            title: res.data.response,
            text: res.data.description,
            confirmButtonColor: '#23303D',
            // footer: '<a href="">Why do I have this issue?</a>',
          });
        }

        // setprojectData(res.data);
        // setLoading(false);
      })
      .catch((error) => {
        console.log('projects/AddProject.js', ' ', 'line 147', error);
      });
  };

  const option = projectTypeData.map((item, Index) => {
    return (
      <option value={item.value} key={Index}>
        {item.label}
      </option>
    );
  });

  const categoryOptiom = projectCategoryData.map((item, Index) => {
    return (
      <option value={item.value} key={Index}>
        {item.label}
      </option>
    );
  });

  const imagescontent = image.map((data, Index) => {
    return (
      <CCol xs="12" sm="12" md="12" lg="3" xl="3" key={Index} style={{ marginTop: '20px' }}>
        <img src={data} style={{ width: '100%' }} />
      </CCol>
    );
  });

  const ProjectCost = projectCost.map((item, Index) => {
    return (
      <tr key={Index}>
        <td>{item.phase}</td>
        <td style={{ textAlign: 'right' }}>
          {/* Php */}
          <NumberFormat value={item.estimate} displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true} style={{ marginLeft: '5px' }} />
        </td>
        <td style={{ textAlign: 'right' }}>
          {/* Php */}
          <NumberFormat
            // value={item.estimate}
            value={item.exptected}
            displayType={'text'}
            thousandSeparator={true}
            decimalScale={2}
            fixedDecimalScale={true}
            style={{ marginLeft: '5px' }}
          />
        </td>
        <td style={{ textAlign: 'right' }}>
          {/* Php */}
          <NumberFormat value={item.actual} displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true} style={{ marginLeft: '5px' }} />
        </td>
      </tr>
    );
  });

  return (
    <>
      {loading ? <Loading /> : ''}
      <CCol xs="12" sm="12" md="12" lg="12" style={{ padding: '1px', marginTop: '20px' }}>
        <CRow>
          <CCol xs="12" sm="12" md="12" lg="3" xl="3">
            {image.length == 0 ? (
              <CCol xs="12" sm="12" md="12" lg="12" style={{ padding: '0px' }}>
                {checkupload == true ? (
                  <CCard style={{ textAlign: 'center', height: '200px' }} className="upload_design">
                    <span style={{ marginTop: '10%' }}>
                      <FaRegImage
                        style={{
                          width: '100px',
                          height: '100px',
                          opacity: '50%',
                        }}
                      />
                    </span>
                    <input type="file" name="file" onChange={upload} />
                    <span>Click here to upload photos</span>
                  </CCard>
                ) : (
                  <CCard style={{ textAlign: 'center', height: '200px' }} className="upload_design">
                    <Lottie options={defaultOptions} height={120} width={150} className="loading-lottie" style={{ marginTop: '10px' }} />
                  </CCard>
                )}
              </CCol>
            ) : (
              <CRow>
                <CCol xs="12" sm="12" md="12" lg="12">
                  <img src={image[0]} className="project-img-main" />
                </CCol>
                {image.length > 4 && (
                  <CCol xs="4" sm="12" md="4" lg="4">
                    <img src={image[1]} className="project-img-sub" />
                  </CCol>
                )}

                {image.length > 4 && (
                  <CCol xs="4" sm="12" md="4" lg="4">
                    <img src={image[2]} className="project-img-sub" />
                  </CCol>
                )}
                {image.length > 4 && (
                  <CCol xs="4" sm="12" md="4" lg="4">
                    <img src={image[3]} className="project-img-sub" />
                  </CCol>
                )}
              </CRow>
            )}
            <CCol xs="12" sm="12" md="12" lg="12" style={{ padding: '0px' }}>
              <CRow style={{ marginTop: '30px' }}>
                <CCol xs="6" sm="6" md="6" lg="6">
                  {image.length != 0 && (
                    <label className="upload-file" style={{ marginTop: '10px' }}>
                      <input type="file" name="file" id="file-input" onChange={upload} className="upload-button upload-file" />

                      <span>Upload </span>
                    </label>
                  )}
                </CCol>
              </CRow>
            </CCol>
          </CCol>
          <CCol xs="12" sm="12" md="12" lg="9" xl="9">
            <CCol xs="12" sm="12" md="12" lg="12" className="tab-margin">
              <CRow>
                <CCol xs="12" sm="12" md="8" lg="12" xl="8">
                  <CCol xs="12" sm="12" md="12" lg="12">
                    <CRow>
                      <CCol xs="2" sm="2" md="2" lg="2" className="Total-label">
                        <span>Total Estimate</span>
                      </CCol>
                      <CCol xs="10" sm="10" md="4" lg="4" className="Total-value">
                        Php
                        <NumberFormat value={projectInfo.total_estimate} displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true} style={{ marginLeft: '5px' }} />
                      </CCol>
                      <CCol xs="2" sm="2" md="2" lg="2" className="Total-label">
                        <span>Total Actual Cost</span>
                      </CCol>
                      <CCol xs="10" sm="10" md="4" lg="4" className="Total-value">
                        <NumberFormat value={projectInfo.total_cost} displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true} style={{ marginLeft: '5px' }} />
                      </CCol>
                    </CRow>
                  </CCol>
                  <CProgress size="m">
                    <CProgressBar color="aimhi-success" value={projectInfo.total_cost} max={projectInfo.total_budget} />
                  </CProgress>
                  <CCol xs="12" sm="12" md="12" lg="12">
                    <CRow>
                      <CCol xs="6" sm="6" md="6" lg="6" className="project-start-text">
                        <span>START: {projectInfo.start_date}</span>
                      </CCol>
                      <CCol xs="6" sm="6" md="6" lg="6" className="project-end-text">
                        <span>END: {projectInfo.end_date}</span>
                      </CCol>
                    </CRow>
                  </CCol>
                </CCol>
                <CCol xs="12" sm="12" md="4" lg="12" xl="4" style={{ padding: '0' }}>
                  <CRow>
                    <CCol xs="12" sm="12" md="4" lg="4" style={{ padding: '5px' }}>
                      <CCard color="aimhi-primary" style={{ padding: '2px' }}>
                        <span className="card-days-left-value">{projectInfo.days_left}</span>
                        <span className="card-days-left-label">Days Left</span>
                      </CCard>
                    </CCol>
                    <CCol xs="12" sm="12" md="4" lg="4" style={{ padding: '5px' }}>
                      <CCard color="aimhi-primary" style={{ padding: '2px' }}>
                        <span className="card-days-left-value">{projectInfo.budget_spent}%</span>
                        <span className="card-days-left-label">Budget Spent</span>
                      </CCard>
                    </CCol>
                    <CCol xs="12" sm="12" md="4" lg="4" style={{ padding: '5px' }}>
                      <CCard color="aimhi-primary" style={{ padding: '2px' }}>
                        <span className="card-days-left-value">
                          <NumberFormat value={projectInfo.progress} displayType={'text'} thousandSeparator={true} decimalScale={2} />%
                        </span>
                        <span className="card-days-left-label">Progress</span>
                      </CCard>
                    </CCol>
                  </CRow>
                </CCol>
              </CRow>
            </CCol>
            <CCol xs="12" sm="12" md="12" lg="12" xl="12" style={{ paddingTop: '0px' }}>
              <CRow>
                <CCol xs="12" sm="12" md="12" lg="12" xl="7">
                  <CCol xs="12" sm="12" md="12" lg="12">
                    <span className="row-label">Project Cost</span>
                    <CCol xs="12" sm="12" md="12" lg="12" className="border-bottom"></CCol>
                  </CCol>
                  <table className="table borderless">
                    <tbody>
                      <tr style={{ fontSize: '12px' }}>
                        <td></td>
                        <td style={{ textAlign: 'right' }}>
                          <u>Estimate (PHP)</u>
                        </td>
                        <td style={{ textAlign: 'right' }}>
                          <CTooltip content="This is the expected cost for this month." placement="right-end" color="white">
                            <u>Expected (PHP)</u>
                          </CTooltip>
                        </td>
                        <td style={{ textAlign: 'right' }}>
                          <u>Actual (PHP)</u>
                        </td>
                      </tr>
                      {ProjectCost}
                    </tbody>
                  </table>
                  <CCol xs="12" sm="12" md="12" lg="12">
                    <span className="row-label">Billing Information</span>
                    <CCol xs="12" sm="12" md="12" lg="12" className="border-bottom"></CCol>
                  </CCol>
                  <table className="table borderless">
                    <thead></thead>
                    <tbody>
                      <tr>
                        <td>OCM %</td>
                        <td>
                          <CInput size="sm" value={ocm} onChange={(e) => setOcm(e.target.value)} disabled={editBilling} />
                        </td>
                      </tr>
                      <tr>
                        <td>Contract Amount</td>
                        <td>
                          <CInput size="sm" value={contratAmount} onChange={(e) => setContractAmount(e.target.value)} disabled={editBilling} />
                        </td>
                      </tr>
                      <tr>
                        <td>Contractor’s Profit %</td>
                        <td>
                          <CInput size="sm" value={cp} onChange={(e) => setCp(e.target.value)} disabled={editBilling} />
                        </td>
                      </tr>
                      <tr>
                        <td>Vat Type</td>
                        <td>
                          <CSelect value={hasVat} onChange={(e) => setHasVat(e.target.value)} disabled={editBilling} size="sm">
                            <option disabled value="">
                              VAT Type
                            </option>
                            <option value="1">Vatable</option>
                            <option value="0">Without VAT</option>
                          </CSelect>
                        </td>
                      </tr>
                      <tr>
                        <td></td>
                        <td>
                          {editBilling == true ? (
                            <CButton size="sm" color="aimhi-textlight-neutral" variant="outline" block onClick={() => setEditBilling(!editBilling)}>
                              Edit
                            </CButton>
                          ) : (
                            <CButton
                              size="sm"
                              color="aimhi-textlight-neutral"
                              block
                              onClick={() => {
                                SaveBilling(props.project_id);
                                setEditBilling(!editBilling);
                              }}
                            >
                              Save
                            </CButton>
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <CCol xs="12" sm="12" md="12" lg="12">
                    <span className="row-label">Project Team</span>
                    <CCol xs="12" sm="12" md="12" lg="12" className="border-bottom"></CCol>
                  </CCol>
                  <table className="table borderless">
                    <thead></thead>
                    <tbody>
                      <tr>
                        <td>Project Manager</td>
                        <td>
                          <CInput size="sm" value={projectManager} onChange={(e) => setprojectManager(e.target.value)} disabled={customerContactEdit} />
                        </td>
                      </tr>
                      <tr>
                        <td>Site Engineer</td>
                        <td>
                          <CInput size="sm" value={siteEngineer} onChange={(e) => setsiteEngineer(e.target.value)} disabled={customerContactEdit} />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Architect<span style={{ color: '#B42A19' }}>*</span>
                        </td>
                        <td>
                          <CInput size="sm" value={architect} onChange={(e) => setarchitect(e.target.value)} disabled={customerContactEdit} invalid={!architect} />
                          {!architect && <CInvalidFeedback>Architect name is required.</CInvalidFeedback>}
                        </td>
                      </tr>
                      <tr>
                        <td>Head Foreman</td>
                        <td>
                          <CInput size="sm" value={headForeman} onChange={(e) => setheadForeman(e.target.value)} disabled={customerContactEdit} />
                        </td>
                      </tr>
                      <tr>
                        <td>Master Plumber</td>
                        <td>
                          <CInput size="sm" value={masterPlumber} onChange={(e) => setmasterPlumber(e.target.value)} disabled={customerContactEdit} />
                        </td>
                      </tr>
                      <tr>
                        <td>Electrical Engineer</td>
                        <td>
                          <CInput size="sm" value={electricalEngineer} onChange={(e) => setelectricalEngineer(e.target.value)} disabled={customerContactEdit} />
                        </td>
                      </tr>
                      <tr>
                        <td>Structural Engineer</td>
                        <td>
                          <CInput size="sm" value={structuralEngineer} onChange={(e) => setstructuralEngineer(e.target.value)} disabled={customerContactEdit} />
                        </td>
                      </tr>
                      <tr>
                        <td>Project Inspector</td>
                        <td>
                          <CInput value={projectInspector} onChange={(e) => setProjectInspector(e.target.value)} size="sm" disabled={customerContactEdit} />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <CCol xs="12" sm="12" md="12" lg="12">
                    <span className="row-label">Client Information</span>
                    <CCol xs="12" sm="12" md="12" lg="12" className="border-bottom"></CCol>
                  </CCol>
                  <table className="table borderless">
                    <tbody>
                      <tr>
                        <td>
                          Company Name &nbsp;&nbsp;
                          <span style={{ color: '#B42A19' }}>*</span>
                        </td>
                        <td>
                          <CInput value={companyName} onChange={(e) => setcompanyName(e.target.value)} size="sm" disabled={customerContactEdit} invalid={!companyName} />
                          {!companyName && <CInvalidFeedback>Company name is required.</CInvalidFeedback>}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Client Name
                          <span style={{ color: '#B42A19' }}>*</span>
                        </td>
                        <td>
                          <CInput value={clientName} onChange={(e) => setclientName(e.target.value)} size="sm" disabled={customerContactEdit} invalid={!clientName} />
                          {!clientName && <CInvalidFeedback>Client name is required.</CInvalidFeedback>}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Phone Number <span style={{ color: '#B42A19' }}>*</span>
                        </td>
                        <td>
                          <CInput value={phoneNumber} onChange={(e) => setphoneNumber(e.target.value)} size="sm" disabled={customerContactEdit} invalid={!phoneNumber} />
                          {!phoneNumber && <CInvalidFeedback>Phone number is required.</CInvalidFeedback>}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Email <span style={{ color: '#B42A19' }}>*</span>
                        </td>
                        <td>
                          <CInput value={email} onChange={(e) => setemail(e.target.value)} size="sm" disabled={customerContactEdit} invalid={!email} />
                          {!email && <CInvalidFeedback>Email is required.</CInvalidFeedback>}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Region <span style={{ color: '#B42A19' }}>*</span>
                        </td>
                        <td style={{ width: '280px' }}>
                          <CSelect onChange={(e) => province(e.target.value)} onSelect={(e) => region(e.target.value)} placeholder="Region" disabled={customerContactEdit} value={regionAddr} size="sm" invalid={!regionAddr}>
                            <option>Select Region</option>
                            {regionData &&
                              regionData.length > 0 &&
                              regionData.map((item) => (
                                <option key={item.region_code} value={item.region_name}>
                                  {item.region_name}
                                </option>
                              ))}
                          </CSelect>
                          {!regionAddr && <CInvalidFeedback>Region is required.</CInvalidFeedback>}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Province <span style={{ color: '#B42A19' }}>*</span>
                        </td>
                        <td>
                          <CSelect onChange={(e) => city(e.target.value)} disabled={customerContactEdit} placeholder="Province" value={provinceAddr} size="sm" invalid={!provinceAddr}>
                            <option value="">Select Province</option>
                            {provinceData &&
                              provinceData.length > 0 &&
                              provinceData.map((item) => (
                                <option key={item.province_code} value={item.province_name}>
                                  {item.province_name}
                                </option>
                              ))}
                          </CSelect>
                          {!provinceAddr && <CInvalidFeedback>Province is required.</CInvalidFeedback>}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          City <span style={{ color: '#B42A19' }}>*</span>
                        </td>
                        <td>
                          <CSelect onChange={(e) => barangay(e.target.value)} disabled={customerContactEdit} placeholder="City/Municipality" value={cityAddr} size="sm" invalid={!cityAddr}>
                            <option value="">Select City</option>
                            {cityData &&
                              cityData.length > 0 &&
                              cityData.map((item) => (
                                <option key={item.city_code} value={item.city_name}>
                                  {item.city_name}
                                </option>
                              ))}
                          </CSelect>
                          {!cityAddr && <CInvalidFeedback>City is required.</CInvalidFeedback>}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Barangay <span style={{ color: '#B42A19' }}>*</span>
                        </td>
                        <td>
                          <CSelect onChange={(e) => brgy(e.target.value)} disabled={customerContactEdit} placeholder="Barangay" value={barangayAddr} size="sm" invalid={!barangayAddr}>
                            <option value="">Select Barangay</option>
                            {barangayData &&
                              barangayData.length > 0 &&
                              barangayData.map((item) => (
                                <option key={item.brgy_code} value={item.brgy_name}>
                                  {item.brgy_name}
                                </option>
                              ))}
                          </CSelect>
                          {!barangayAddr && <CInvalidFeedback>Barangay is required.</CInvalidFeedback>}
                        </td>
                      </tr>
                      <tr>
                        <td>Street</td>
                        <td>
                          <CInput type="text" placeholder="Street Name" onChange={(e) => setStreetAddr(e.target.value)} value={streetAddr} className="input-design" disabled={customerContactEdit} size="sm" />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Project Category <span style={{ color: '#B42A19' }}>*</span>
                        </td>
                        <td>
                          <CSelect placeholder="Project Category" onChange={(e) => setProjectCategory(e.target.value)} value={projectcategory} className="input-design" disabled={customerContactEdit} size="sm" invalid={!projectcategory}>
                            <option disabled value="">
                              Project Category
                            </option>

                            {categoryOptiom}
                            {/* <option value="Government">Government</option> */}
                          </CSelect>
                          {!projectcategory && <CInvalidFeedback>Project category is required.</CInvalidFeedback>}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Project Type <span style={{ color: '#B42A19' }}>*</span>
                        </td>
                        <td>
                          <CSelect placeholder="Project Category" onChange={(e) => setProjectType(e.target.value)} value={projectType} className="input-design" disabled={customerContactEdit} size="sm" invalid={!projectType}>
                            <option disabled value="">
                              Project Type
                            </option>
                            {option}
                            {/* <option value="Government">Government</option> */}
                          </CSelect>

                          {!projectType && <CInvalidFeedback>Project type is required.</CInvalidFeedback>}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Bid Category <span style={{ color: '#B42A19' }}>*</span>
                        </td>
                        <td>
                          <CSelect value={bidCategory} onChange={(e) => setBidCategory(e.target.value)} disabled={customerContactEdit} className="input-design" size="sm" invalid={!bidCategory}>
                            <option disabled value="">
                              Bid Category
                            </option>
                            <option value="500,000 and below"> 500,000 and below</option>
                            <option value="More than 500,00 up to 1 Million">More than 500,00 up to 1 Million</option>
                            <option value="More than 1 Million up to 5 Million">More than 1 Million up to 5 Million</option>
                            <option value="More than 5 Million up to 10 Million">More than 5 Million up to 10 Million</option>
                            <option value="More than 10 Million up to 50 Million">More than 10 Million up to 50 Million</option>
                            <option value="More than 50 Million up to 500 Million">More than 50 Million up to 500 Million</option>
                            <option value="More than 500 Million">More than 500 Million</option>
                          </CSelect>
                          {!bidCategory && <CInvalidFeedback>Bid category is required.</CInvalidFeedback>}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Implementing Client <span style={{ color: '#B42A19' }}>*</span>
                        </td>
                        <td>
                          <CSelect value={sourcefund} onChange={(e) => setSourceFund(e.target.value)} disabled={customerContactEdit} invalid={!sourcefund}>
                            <option value="">Implementing Client</option>
                            <option value="DPWH"> DPWH</option>
                            <option value="Provincial Government">Provincial Government</option>
                            <option value="Local Government Unit">Local Government Unit</option>
                            <option value="Private">Private</option>
                            <option value="Joint Venture">Joint Venture</option>
                          </CSelect>
                          {!sourcefund && <CInvalidFeedback> Implementing client is required.</CInvalidFeedback>}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Start Date <span style={{ color: '#B42A19' }}>*</span>
                        </td>
                        <td>
                          <DatePicker
                            className={`input-design form-control ${customerContactEdit ? 'overviewDates2' : 'overviewDates'}`}
                            placeholderText="mm/dd/yyyy"
                            selected={projectstart ? new Date(projectstart) : null}
                            value={projectstart}
                            disabled={customerContactEdit}
                            onChange={(value) => {
                              const date = new Date(value);
                              const month = String(date.getMonth() + 1).padStart(2, '0');
                              const day = String(date.getDate()).padStart(2, '0');
                              const year = date.getFullYear();
                              const formattedDate = `${month}/${day}/${year}`;

                              setProjectStart(formattedDate);
                            }}
                            // className={customerContactEdit ? 'overviewDates2' : 'overviewDates'}
                            invalid={!projectstart}
                          />
                          {!projectstart && <CInvalidFeedback> Start date is required.</CInvalidFeedback>}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          No of Days <span style={{ color: '#B42A19' }}>*</span>
                        </td>
                        <td>
                          <CInput type="number" placeholder="No of Days" size="md" onChange={(e) => setNoDays(e.target.value)} className="input-design" value={nodays} disabled={customerContactEdit} invalid={!nodays} />
                          {!nodays && <CInvalidFeedback>No. of days is required.</CInvalidFeedback>}
                        </td>
                      </tr>

                      <tr></tr>
                    </tbody>
                  </table>
                  <CCol xs="12" sm="12" md="12" lg="12" xl="12">
                    <CRow>
                      <CCol md="4">
                        {permission['delete'][0] == 1 && (
                          <CButton className="float-right" color="aimhi-textlight-neutral" size="sm" block onClick={() => arhiveproject(props.project_id)}>
                            Add to Archive
                          </CButton>
                        )}
                      </CCol>
                      <CCol md="4">
                        {customerContactEdit == true ? (
                          <CButton color="aimhi-textlight-neutral" variant="outline" block size="sm" onClick={() => setcustomerContactEdit(false)}>
                            Edit
                          </CButton>
                        ) : (
                          <CButton
                            color="aimhi-textlight-neutral"
                            block
                            size="sm"
                            onClick={() => saveOverview(props.project_id, setcustomerContactEdit(true))}
                            disabled={!architect || !companyName || !clientName || !phoneNumber || !email || !regionAddr || !provinceAddr || !cityAddr || !barangayAddr || !projectcategory || !projectType || !projectstart || !nodays || !bidCategory || !reconciliationCycle}
                          >
                            Save
                          </CButton>
                        )}
                      </CCol>
                    </CRow>
                  </CCol>
                </CCol>
                <CCol xs="12" sm="12" md="12" lg="12" xl="5">
                  <CCol xs="12" sm="12" md="12" lg="12">
                    <span className="row-label">Key Dates</span>
                    <CCol xs="12" sm="12" md="12" lg="12" className="border-bottom"></CCol>
                  </CCol>
                  <table className="table borderless">
                    <tbody>
                      <tr>
                        <td>Proposal Submission</td>
                        <td>
                          {/* <CInput
                            type="date"
                            value={proposalSubmission}
                            onChange={(e) =>
                              setProposalSubmission(e.target.value)
                            }
                            size="sm"
                            disabled={customerContactEdit}
                          /> */}

                          <DatePicker
                            className="input-design form-control sm"
                            placeholderText="mm/dd/yyyy"
                            value={proposalSubmission}
                            disabled={customerContactEdit}
                            onChange={(value) => {
                              const date = new Date(value);
                              const month = String(date.getMonth() + 1).padStart(2, '0');
                              const day = String(date.getDate()).padStart(2, '0');
                              const year = date.getFullYear();
                              const formattedDate = `${month}/${day}/${year}`;
                              setProposalSubmission(formattedDate);
                            }}
                          />
                        </td>
                        {/* <td>{keyDates.proposal_submission}</td> */}
                      </tr>
                      <tr>
                        <td>Kick-off Start</td>
                        <td>
                          {/* <CInput
                            type="date"
                            value={kickOffStart}
                            onChange={(e) => setKickOffStart(e.target.value)}
                            size="sm"
                            disabled={customerContactEdit}
                          /> */}

                          <DatePicker
                            className="input-design form-control sm"
                            placeholderText="mm/dd/yyyy"
                            value={kickOffStart}
                            disabled={customerContactEdit}
                            onChange={(value) => {
                              const date = new Date(value);
                              const month = String(date.getMonth() + 1).padStart(2, '0');
                              const day = String(date.getDate()).padStart(2, '0');
                              const year = date.getFullYear();
                              const formattedDate = `${month}/${day}/${year}`;

                              setKickOffStart(formattedDate);
                            }}
                          />
                        </td>
                        {/* <td>{keyDates.kick_off_start}</td> */}
                      </tr>
                      <tr>
                        <td>Turn Over</td>
                        <td>
                          {/* <CInput
                            type="date"
                            value={turnOver}
                            onChange={(e) => setTurnOver(e.target.value)}
                            size="sm"
                            disabled={customerContactEdit}
                          /> */}

                          <DatePicker
                            className="input-design form-control sm"
                            placeholderText="mm/dd/yyyy"
                            value={turnOver}
                            disabled={customerContactEdit}
                            onChange={(value) => {
                              const date = new Date(value);
                              const month = String(date.getMonth() + 1).padStart(2, '0');
                              const day = String(date.getDate()).padStart(2, '0');
                              const year = date.getFullYear();
                              const formattedDate = `${month}/${day}/${year}`;

                              setTurnOver(formattedDate);
                            }}
                          />
                        </td>
                        {/* <td>{keyDates.turn_over}</td> */}
                      </tr>

                      {/* Hidden by ASH */}
                      <tr>
                        <td>
                          Reconciliation Cycle <span style={{ color: '#B42A19' }}>*</span>
                          <CTooltip content="Determines system's frequency in verifying estimate data for accurate metrics." placement="right-end" color="white">
                            <FaRegQuestionCircle
                              style={{
                                width: '15px',
                                height: '15px',
                                marginLeft: '5px',
                                color: '#757070',
                              }}
                            />
                          </CTooltip>
                        </td>
                        <td>
                          <CSelect
                            // onChange={(e) => setProjectType(e.target.value)}
                            value={reconciliationCycle}
                            className="input-design"
                            size="sm"
                            disabled={customerContactEdit}
                            onChange={(e) => setReconciliationCycle(e.target.value)}
                            invalid={!reconciliationCycle}
                          >
                            <option disabled value="">
                              Reconciliation Cycle
                            </option>
                            <option value="daily">Daily</option>
                            <option value="weekly">Weekly</option>
                            <option value="bi-weekly">Bi-Weekly</option>
                            <option value="monthly">Monthly</option>
                            <option value="quarterly">Quarterly</option>
                          </CSelect>
                          {!reconciliationCycle && <CInvalidFeedback>Reconciliation Cycle is required.</CInvalidFeedback>}
                        </td>
                      </tr>
                      {/* Hidden by ASH */}
                    </tbody>
                  </table>
                </CCol>
              </CRow>
            </CCol>
          </CCol>
        </CRow>
      </CCol>

      <CModal show={modal} onClose={() => setModal(!modal)} size="lg" style={{ borderRadius: '5px' }}>
        <CModalHeader closeButton style={{ borderColor: 'white' }}>
          <CModalTitle>Picture(s)</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <div className="modal-body-limit">
            <CCol md="12" sm="12" lg="12" xl="12">
              <CRow>
                <CCol md="12" sm="12" lg="3" xl="3">
                  <CCard
                    style={{
                      textAlign: 'center',
                      width: '100%',
                      height: '110px',
                      marginTop: '20px',
                    }}
                    className="upload_design"
                  >
                    <span style={{ marginTop: '10%' }}>
                      <FaRegImage
                        style={{
                          width: '50px',
                          height: '50px',
                          opacity: '50%',
                        }}
                      />
                    </span>
                    <input type="file" name="file" multiple onChange={upload} />
                    <span style={{ fontSize: '12px' }}>Click here to upload photos</span>
                  </CCard>
                </CCol>
                {imagescontent}
              </CRow>
            </CCol>
          </div>
        </CModalBody>
        <CModalFooter style={{ borderColor: 'white' }}>
          {/* <CCol md="4" sm="4" lg="4" xl="4">
            <CButton
              block
              color="aimhi-primary"
              size="sm"
              onClick={() => send()}
              disabled={email == ""}
            >
              SEND
            </CButton>
          </CCol> */}
        </CModalFooter>
      </CModal>
    </>
  );
};

export default ProjectOverview;
