// mobile number validation for input fields
export const validateMobileNumber = (previousValue, newValue) => {
  // philippine format ONLY
  const pattern = /9[0-9]{0,9}/
  const regex = pattern.exec(newValue)

  if (regex !== null) {
    return regex[0]
  }

  return previousValue
}


export const validateProjectId = (previousValue, newValue) => {
  const pattern = /[\/\w\d-]+/
  const regex = pattern.exec(newValue)

  if (regex !== null) {
    return regex[0]
  }
  
  return previousValue
}
