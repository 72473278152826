import React, { useEffect, useState } from 'react';
import { CModal, CModalBody, CModalHeader, CModalFooter, CButton, CFormGroup, CInput, CLabel, CCol, CForm } from '@coreui/react';
import Select, { components } from 'react-select';
import { GetProjectsMaterialUtilization, GetMaterialUtilizationProjectData, SaveTransferMaterial, GetProjectStockBalance, GetPsowId } from 'src/request/OnsiteRecords';
import { isEmpty, set } from 'lodash';

// Custom MultiValueLabel component
const MultiValueLabel = (props) => {
  let label = props.data.label;
  if (label.length > 10) {
    label = `${label.slice(0, 10)}...`;
  }

  return <components.MultiValueLabel {...props}>{label}</components.MultiValueLabel>;
};

const TransferForm = ({ isOpen, onClose, isUpdate, item, onSuccess, projectId }) => {
  const Swal = require('sweetalert2');
  const [validated, setValidated] = useState(false);
  const [projectOptions, setProjectOptions] = useState([]);
  const [phaseOptions, setPhaseOptions] = useState([]);
  const [workItemOptions, setWorkItemOptions] = useState([]);
  const [materialOptions, setMaterialOptions] = useState([]);
  const [psowData, setPsowData] = useState([]);
  const [form, setForm] = useState({ project_id: '', materials: [] });

  const handleInputChange = (index) => (event) => {
    const newMaterials = [...form.materials];
    const qty = event.target.value;
    newMaterials[index].qty = qty;
    newMaterials[index].isInvalid = parseInt(qty) > newMaterials[index].stock_balance;
    newMaterials[index].isEmpty = qty === '' || !qty;
    setForm({ ...form, materials: newMaterials });
  };

  const handleSelectChange = (name) => (selectedOption) => {
    setForm({ ...form, [name]: selectedOption.value });

    if (name === 'project_id' && selectedOption.value) {
      const fetchMaterialOptions = (project_id, page, pageSize, search = null) => {
        GetMaterialUtilizationProjectData(project_id, page, pageSize, search)
          .then((response) => {
            const materialOptionsData = response.data.data.map((material) => ({ value: `${material.material_id}-${material.sow_id}`, label: `${material.material_description}(${material.sow_name})`, stock_balance: material.stock_balance }));
            setMaterialOptions(materialOptionsData);
          })
          .catch((error) => {
            console.error('error', error);
          });
      };

      const fetchPsow = (sender_project_id, receiver_project_id) => {
        GetPsowId(sender_project_id, receiver_project_id)
          .then((response) => {
            setPsowData(response.data.response);
          })
          .catch((error) => {
            console.error('error', error);
          });
      };
      fetchMaterialOptions(projectId, 1, 10);
      fetchPsow(projectId, selectedOption.value);

      // fetchPhaseOptions();
    }
    // else {
    //   // fetch material options
    //   const fetchMaterialOptions = async (project_id, page, pageSize, search = null) => {
    //     await GetMaterialUtilizationProjectData(project_id, page, pageSize, search)
    //       .then((response) => {
    //         console.log('response material options', response);
    //         setMaterialOptions(response.data.data);
    //       })
    //       .catch((error) => {
    //         console.error('error', error);
    //       });
    //   };
    //   fetchMaterialOptions(form.project_id, 1, 10);
    // }
  };
  const handleMaterialSelectChange = (selectedOptions) => {
    const selectedValues = selectedOptions
      ? selectedOptions.map((option) => ({
          id: option.value,
          label: option.label,
          // Initialize with default values that might be overwritten if the material was already selected
          qty: '',
          stock_balance: option.stock_balance || '',
          // request_qty: option.request_qty || '',
          // inbound_id: option.inbound_id,
        }))
      : [];

    // Preserve quantities for already selected materials
    const updatedMaterials = selectedValues.map((newMaterial) => {
      const existingMaterial = form.materials.find((material) => material.id === newMaterial.id);
      if (existingMaterial) {
        return {
          ...newMaterial,
          qty: existingMaterial.qty,
          available_qty: existingMaterial.available_qty,
          request_qty: existingMaterial.request_qty,
        };
      }
      return newMaterial;
    });

    // if (validated) {
    //   setValidated(false);
    // }

    setForm({ ...form, materials: updatedMaterials });
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    const formElement = event.currentTarget;
    const isInvalid = form.materials.some((material) => material.isInvalid);
    const isEmpty = form.materials.some((material) => material.isEmpty);
    if ((formElement.checkValidity() === false && form.project_id === '') || isInvalid || isEmpty) {
      setValidated(true);
      event.stopPropagation();
      return;
    } else {
      // const payload = { utilization: form.materials.map((material) => ({ inbound_id: material.inbound_id, utilized_qty: parseInt(material.qty), available_qty: parseInt(material.available_qty) })) };
      // console.log('payload', payload);
      // await SaveMaterialUtilizationData(payload)
      //   .then((response) => {
      //     if (response.status === 200) {
      //       Swal.fire({
      //         icon: 'success',
      //         title: 'Success',
      //         text: 'Report successfully added!',
      //         confirmButtonColor: '#23303D',
      //       });
      //       onClose();
      //       onSuccess();
      //       // resetForm();
      //     }
      //   })
      //   .catch((error) => {
      //     Swal.fire({
      //       icon: 'error',
      //       title: 'Error',
      //       text: 'Something went wrong! Please try again.',
      //       confirmButtonColor: '#23303D',
      //     });
      //   });
      // const payload = { project_sender: projectId, project_receiver: form.project_id, data: form.materials.map((material) => ({ material_id: parseInt(material.id.split('-')[0]), quantity: parseInt(material.qty) })) };
      const payload = {
        project_sender: projectId,
        project_receiver: form.project_id,
        data: form.materials.map((material) => {
          const materialId = parseInt(material.id.split('-')[0]);
          const sowId = parseInt(material.id.split('-')[1]);
          const senderPsow = psowData.sender_data.find((item) => item.material_id === materialId && item.sow_id === sowId);
          const receiverPsow = psowData.receiver_data.find((item) => item.material_id === materialId);

          return {
            material_id: materialId,
            quantity: parseInt(material.qty),
            sender_psow_id: senderPsow ? senderPsow.psow_id : null,
            receiver_psow_id: receiverPsow ? receiverPsow.psow_id : null,
          };
        }),
      };
      await SaveTransferMaterial(payload)
        .then((response) => {
          console.log('response', response);
          if (response.data.response.status === 200) {
            Swal.fire({
              icon: 'success',
              title: 'Success',
              text: response?.data?.response?.message || 'Material transfer successfully submitted!',
              confirmButtonColor: '#23303D',
            });
            onClose();
            onSuccess();
            // resetForm();
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: response?.data?.response || 'Something went wrong! Please try again.',
              confirmButtonColor: '#23303D',
            });
          }
        })
        .catch((error) => {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Something went wrong! Please try again.',
            confirmButtonColor: '#23303D',
          });
        });
    }
  };

  useEffect(() => {
    if (isUpdate && item) {
      setForm(item);
    } else {
      setForm({ project_id: '', sow_id: '', work_item_id: '', materials: [] });
    }
  }, [isUpdate, item]);

  useEffect(() => {
    const fetchProjectOptions = async () => {
      // let filter = !isUpdateEntry ? null : 'incomplete';
      // await GetProjectsMaterialUtilization()
      //   .then((response) => {
      //     const projectOptionsData = response.data.response.filter((project) => project.value !== projectId).map((project) => ({ value: project.value, label: project.label }));
      //     console.log('projectOptionsData', projectOptionsData);
      //     setProjectOptions(projectOptionsData);
      //     // setIsLoading(false);
      //   })
      //   .catch((error) => {
      //     console.error('error', error);
      //   });
      await GetProjectStockBalance()
        .then((response) => {
          const projectOptionsData = response.data.response.data.filter((project) => project.value !== projectId).map((project) => ({ value: project.value, label: project.label }));
          setProjectOptions(projectOptionsData);
          // setSelectedProject(projectOptionsData[0]);
          // setIsLoading(false);
        })
        .catch((error) => {
          console.error('error', error);
        });
    };
    fetchProjectOptions();
  }, []);

  return (
    <CCol style={{ maxHeight: '600px', overflow: 'auto' }}>
      <CModal show={isOpen} onClose={onClose}>
        {!isUpdate ? <CModalHeader>Transfer Materials</CModalHeader> : <CModalHeader>Update Entry</CModalHeader>}
        <CForm noValidate onSubmit={handleSubmit}>
          <CModalBody style={{ minHeight: '500px', maxHeight: '600px', overflow: 'auto', paddingLeft: '40px' }}>
            <CFormGroup>
              <CLabel>Transfer to Project</CLabel>
              <Select
                name="project_id"
                placeholder="Select Project"
                options={projectOptions}
                styles={{
                  container: (provided) => ({
                    ...provided,
                    width: '98%',
                  }),
                }}
                onChange={handleSelectChange('project_id')}
              />
              {validated && !form.project_id && <CLabel style={{ fontSize: '11.2px', color: '#E55353' }}>Please select a Project.</CLabel>}
            </CFormGroup>

            <CFormGroup>
              <CLabel>Materials</CLabel> <span style={{ color: '#757070', fontSize: '12px', marginLeft: '8px' }}>{`(You can select more than one material.)`}</span>
              <Select
                value={form.materials.length > 0 ? materialOptions.filter((option) => form.materials.map((material) => material.id).includes(option.value)) : ''}
                name="material_id"
                placeholder="Select Material"
                isMulti
                options={materialOptions}
                styles={{
                  container: (provided) => ({
                    ...provided,
                    width: '98%',
                  }),
                }}
                onChange={handleMaterialSelectChange}
                isDisabled={!form.project_id}
                components={{ MultiValueLabel }}
              />
              {/* {validated && !form.material_id && <CLabel style={{ fontSize: '11.2px', color: '#E55353' }}>Please select a material.</CLabel>} */}
              {validated && !form.materials.length && <CLabel style={{ fontSize: '11.2px', color: '#E55353' }}>Please select a material.</CLabel>}
            </CFormGroup>
            {form.materials.map((material, index) => (
              <CFormGroup key={index} style={{ padding: '10px' }}>
                <CLabel>{material.label}</CLabel>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <CInput name="material_qty" placeholder="0" type="number" value={material.qty} style={{ width: '100px' }} required invalid={(validated && !material.qty) || (validated && material.isInvalid)} onChange={handleInputChange(index)} onWheel={(e) => e.target.blur()} />
                  <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '20px', alignItems: 'end' }}>
                    <span style={{ fontSize: '120%', fontWeight: 'bold' }}> / {material.stock_balance} Available stock to transfer</span>
                  </div>
                </div>
                <div>
                  {validated && material.isInvalid && <CLabel style={{ fontSize: '11.2px', color: '#E55353' }}>Should not exceed stock balance</CLabel>}
                  {validated && material.isEmpty && <CLabel style={{ fontSize: '11.2px', color: '#E55353' }}>Please input quantity</CLabel>}
                  {/* <span style={{ fontSize: '120%', marginLeft: (validated && !material.qty) || (validated && material.isInvalid) ? '20px ' : '120px' }}> {material.request_qty} Requested QTY</span> */}
                </div>
              </CFormGroup>
            ))}
          </CModalBody>
          <CModalFooter>
            {!isUpdate ? (
              <CButton color="aimhi-textlight-neutral" type="submit">
                Save
              </CButton>
            ) : (
              <CButton color="aimhi-textlight-neutral" type="submit">
                Update
              </CButton>
            )}
            <CButton color="aimhi-textlight-neutral" variant="outline" onClick={onClose}>
              Cancel
            </CButton>
          </CModalFooter>
        </CForm>
      </CModal>
    </CCol>
  );
};

export default TransferForm;
