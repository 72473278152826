import $axios from 'src/utils/axios';
import IP from 'src/StoreGlobal/IP';

let companyId = parseInt(localStorage.getItem('company_id'));
let accessToken = localStorage.getItem('token');
let user_id = parseInt(localStorage.getItem('user_id'));

export const getSchedule = (projectId) => {
  // NOTE: Need to refetch values from local storage again
  let values = {
    companyId: parseInt(localStorage.getItem('company_id')) || null,
    token: localStorage.getItem('token') || null,
  };

  return $axios.get(`schedule/${values.companyId}/${projectId}`);
};

export const getCatchupRecommendation = (projectId) => {
  return $axios.get(`recommender/catch-up`, { params: { project_id: projectId, test_shift: 200 } });
};

export const getDiversionRecommendation = (projectId) => {
  return $axios.get(`recommender/diversion`, { params: { project_id: projectId } });
};

export const getCatchupWeatherRecommendation = (projectId) => {
  return $axios.get(`recommender/catch-up-project`, { params: { project_id: projectId } });
};
