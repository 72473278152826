import React, { useEffect, useState } from 'react';
import { CButton, CCard, CCardBody, CCol, CDataTable, CInput, CPagination, CRow, CLabel, CSpinner } from '@coreui/react';
import ReorderPoint from './ReorderPoint';
import { GetMaterialUtilizationProjectData } from 'src/request/OnsiteRecords';
import { DownloadReport } from 'src/request/Projects';

const MaterialUtilizationProject = (props) => {
  const Swal = require('sweetalert2');
  const fields = [
    {
      key: 'material_description',
      label: 'Material',
      _style: { border: 'none', width: '50%' },
    },
    {
      key: 'received_qty_over_stock_balance',
      label: 'Stock Balance / Received Quantity',
      _style: { border: 'none', width: '50%' },
    },
    {
      key: 'sow_name',
      label: 'SOW',
      _style: { display: 'none' },
    },
  ];

  const [isReorderPointOpen, setIsReorderPointOpen] = useState(false);
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleReorderPoint = () => {
    setIsReorderPointOpen(true);
  };

  const handleOnPageChange = (e) => {
    setCurrentPage(e);
  };

  useEffect(() => {
    fetchData(props.project_id, currentPage, 10, searchQuery);
  }, [currentPage, searchQuery]);

  const fetchData = (project_id, page, pageSize, search = null) => {
    GetMaterialUtilizationProjectData(project_id, page, pageSize, search).then((response) => {
      setData(response.data.data);
      setTotalPages(response.data.total_pages);
    });
  };

  const debounce = (func, delay) => {
    let debounceTimer;
    return function (...args) {
      const context = this;
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => func.apply(context, args), delay);
    };
  };

  const handleSearchChange = debounce((e) => {
    setSearchQuery(e.target.value);
  }, 300);

  const handleDownloadReport = async () => {
    setIsLoading(true);
    await DownloadReport(props.project_id, 6);
    setIsLoading(false);
  };

  return (
    <CCard className="shadow border-0 even" style={{ minHeight: '800px' }}>
      <CCardBody>
        <h5> Material Utilization</h5>
        <div className="d-flex justify-content-end">
          <CButton color="aimhi-textlight-neutral" variant="outline" className="mb-3" style={{ alignSelf: 'flex-start', marginRight: '12px' }} onClick={handleDownloadReport}>
            {isLoading ? (
              <>
                <CSpinner color="#23303D" size="sm" />
                <span style={{ marginLeft: '8px' }}>Download</span>
              </>
            ) : (
              'Download'
            )}
          </CButton>
          <CButton color="aimhi-textlight-neutral" className="mb-3" style={{ alignSelf: 'flex-start' }} onClick={handleReorderPoint}>
            Set Reorder Point
          </CButton>
        </div>
        <ReorderPoint isOpen={isReorderPointOpen} onClose={() => setIsReorderPointOpen(!isReorderPointOpen)} projectId={props.project_id} />
        <CDataTable
          items={data}
          fields={fields}
          itemsPerPage={10}
          // pagination
          // tableFilter
          scopedSlots={{
            material_description: (item) => <td style={{ border: 'none' }}>{`${item.material_description} (${item.sow_name})`}</td>,
            received_qty_over_stock_balance: (item) => {
              let stock_balance = Math.max(0, parseInt(item.stock_balance ?? 0));
              let progress = (stock_balance / parseInt(item.total_order)) * 100;

              let color = '#3a7d21';
              if (progress >= 50 && progress === 100) {
                color = '#3a7d21';
              } else if (progress < 50) {
                color = '#f2e442';
              }
              return (
                <td style={{ border: 'none' }}>
                  <div className="progress" style={{ position: 'relative', borderRadius: '10px', height: '20px', background: progress === 0 ? '#B42A19' : '' }}>
                    <div
                      className="progress-bar"
                      style={{
                        width: progress + '%',
                        background: color,
                        height: '20px',
                        color: color === '#3a7d21' ? 'white' : 'black',
                      }}
                    >
                      {stock_balance}
                    </div>
                    <span
                      style={{
                        position: 'absolute',
                        top: '10px',
                        left: '85%',
                        color: progress === 0 || progress >= 92 ? 'white' : '#23303d',
                      }}
                    >
                      {stock_balance}/{item.total_order}
                    </span>
                  </div>
                </td>
              );
            },
            sow_name: (item) => {
              return <td style={{ display: 'none' }}>{item.sow_name}</td>;
            },
          }}
          overTableSlot={
            <CRow xs="12" md="6" lg="6" xl="6">
              <CCol xs="6" md="6" lg="3" xl="3" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', padding: '12px', marginLeft: '10px' }}>
                <CLabel style={{ marginRight: '12px' }}>Search</CLabel>
                {/* <CInput type="text" placeholder="Search" onChange={() => {}} /> */}
                <CInput type="text" placeholder="Search" onChange={handleSearchChange} />
              </CCol>
            </CRow>
          }
        />
        {totalPages > 1 && (
          <CPagination
            activePage={currentPage}
            pages={totalPages}
            onActivePageChange={(i) => {
              if (i === 0) {
                i = 1;
              }
              handleOnPageChange(i);
            }}
          ></CPagination>
        )}
      </CCardBody>
    </CCard>
  );
};
export default MaterialUtilizationProject;
