import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { CButton, CCard, CCardBody, CCol, CDataTable, CInput, CPagination, CRow, CLabel, CSpinner, CTabs, CNav, CNavItem, CNavLink, CBadge, CModal, CModalBody, CModalHeader, CModalFooter, CFormGroup } from '@coreui/react';
import { GetTransferMaterialList, ReceiveTransferredMaterial } from 'src/request/OnsiteRecords';

const ReceiveTransferMaterial = ({ project_id, onSuccess }) => {
  const Swal = require('sweetalert2');
  let permission = useSelector((state) => state.permission);
  const receiveFields = [
    {
      key: 'id',
      label: 'Transaction ID',
      _style: {},
    },
    {
      key: 'date_created',
      label: 'Date',
      _style: {},
    },
    {
      key: 'sender_name',
      label: 'From (Project)',
      _style: {},
    },
    {
      key: 'created_by',
      label: 'Submitted By',
      _style: {},
    },
    {
      key: 'final_status',
      label: 'Status',
      _style: {},
    },
    {
      key: 'action',
      label: 'Action',
      _style: {},
    },
  ];

  const [receiveData, setReceiveData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [recordDetails, setRecordDetails] = useState([]);

  useEffect(() => {
    // GetTransferMaterialList(1, 10, null, project_id, 1, null)
    //   .then((response) => {
    //     console.log('received list response', response.data.data);
    //     setReceiveData(response.data.data);
    //     setTotalPages(response.data.total_pages);
    //   })
    //   .catch((error) => {
    //     console.error('error', error);
    //   });
    fetchData(currentPage, 5, null, project_id, 1, null);
  }, [project_id, , currentPage]);

  const fetchData = (page, pageSize, search = null, project_id, received = null, debt = null) => {
    GetTransferMaterialList(page, pageSize, search, project_id, received, debt)
      .then((response) => {
        console.log('history list response', response.data.data);
        setReceiveData(response.data.data);
        setTotalPages(response.data.total_pages);
      })
      .catch((error) => {
        console.error('error', error);
      });
  };

  const handleOnPageChange = (e) => {
    setCurrentPage(e);
  };

  const handleReceiveMaterials = (recordDetails) => {
    console.log('recordDetails', recordDetails);
    setIsModalOpen(true);
    setRecordDetails(recordDetails);
  };

  const getStatus = (status) => {
    switch (status) {
      case 0:
        return <CLabel style={{ color: '#FA7503', margin: 0 }}>Pending</CLabel>;
      case 1:
        return <CLabel style={{ color: '#3A7D21', margin: 0 }}>Approved for delivery</CLabel>;
      case 2:
        return <CLabel style={{ color: '#B42A19', margin: 0 }}>Rejected</CLabel>;
      case 3:
        return <CLabel style={{ color: '#3A7D21', margin: 0 }}>Received</CLabel>;
      default:
        return 'Pending';
    }
  };

  const handleSubmit = async () => {
    const payload = {
      transfer_material_id: recordDetails.id,
      status: 3,
    };
    await ReceiveTransferredMaterial(payload)
      .then((response) => {
        if (response.status === 200) {
          Swal.fire({
            icon: 'success',
            title: 'Success',
            text: response?.data?.response?.message || 'Material has been successfully received!',
            confirmButtonColor: '#23303D',
          });
          setIsModalOpen(false);
          fetchData(currentPage, 5, null, project_id, 1, null);
          onSuccess();
        }
      })
      .catch((error) => {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Something went wrong! Please try again.',
          confirmButtonColor: '#23303D',
        });
      });
  };

  return (
    <>
      <CDataTable
        items={receiveData}
        fields={receiveFields}
        itemsPerPage={10}
        responsive
        scopedSlots={{
          final_status: (item) => (
            <td>
              {item.final_status === 0 ? (
                <CBadge color="aimhi-orange" shape="pill" style={{ padding: '8px' }}>
                  Pending
                </CBadge>
              ) : item.final_status === 1 ? (
                <CBadge color="aimhi-success" shape="pill" style={{ padding: '8px' }}>
                  Approved for delivery
                </CBadge>
              ) : item.final_status === 2 ? (
                <CBadge color="aimhi-danger" shape="pill" style={{ padding: '8px' }}>
                  Rejected
                </CBadge>
              ) : (
                <CBadge color="aimhi-success" shape="pill" style={{ padding: '8px' }}>
                  Received
                </CBadge>
              )}
            </td>
          ),
          date_created: (item) => (
            <td>
              {new Date(item.date_created).toLocaleDateString('en-US', {
                month: '2-digit',
                day: '2-digit',
                year: 'numeric',
              })}
            </td>
          ),
          action: (item) => (
            <td>
              {(permission['create'][8] === 1 || permission['update'][8] === 1) && (
                <CButton color="aimhi-textlight-neutral" onClick={() => handleReceiveMaterials(item)}>
                  Receive
                </CButton>
              )}
            </td>
          ),
        }}
      />
      {totalPages > 1 && (
        <CPagination
          activePage={currentPage}
          pages={totalPages}
          onActivePageChange={(i) => {
            if (i === 0) {
              i = 1;
            }
            handleOnPageChange(i);
          }}
        ></CPagination>
      )}
      <CModal show={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <CModalHeader>Transfer Material Details</CModalHeader>
        <CModalBody>
          <CRow>
            <CCol style={{ marginLeft: '15px' }}>
              <CLabel>
                Transaction ID: <strong>{recordDetails.id}</strong>
              </CLabel>
            </CCol>
            <CCol style={{ textAlign: 'right', marginRight: '15px' }}>
              <CLabel>
                Date:
                <strong style={{ marginLeft: '8px' }}>
                  {new Date(recordDetails.date_created).toLocaleDateString('en-US', {
                    year: 'numeric',
                    month: 'short',
                    day: '2-digit',
                  })}
                </strong>
              </CLabel>
            </CCol>
          </CRow>

          <CCol>
            <CLabel>
              Transaction Type: <strong>{recordDetails.initial_status}</strong>
            </CLabel>
          </CCol>
          <CCol>
            <CLabel>
              From (Project): <strong>{recordDetails.sender_name}</strong>
            </CLabel>
          </CCol>
          <CCol>
            <CLabel>
              Created by: <strong>{recordDetails.created_by}</strong>
            </CLabel>
          </CCol>
          <CCol>
            <CLabel>Status: {getStatus(recordDetails.final_status)}</CLabel>
          </CCol>
          {recordDetails.final_status === 1 ? (
            <CCol>
              <CLabel>
                Approved by: <strong>{recordDetails.approved_for_delivery_by_name}</strong>
              </CLabel>
            </CCol>
          ) : recordDetails.final_status === 2 ? (
            <CCol>
              <CLabel>
                Rejected by: <strong>{recordDetails.rejected_for_delivery_by_name}</strong>
              </CLabel>
            </CCol>
          ) : recordDetails.final_status === 3 ? (
            <CCol>
              <CLabel>
                Received by: <strong>{recordDetails.approved_on_site_received_by_name}</strong>
              </CLabel>
            </CCol>
          ) : (
            ''
          )}
          <CCol style={{ marginTop: '12px' }}>
            <CLabel>Materials to received:</CLabel>
          </CCol>

          <CCol>
            <table style={{ width: '100%', borderCollapse: 'collapse', marginBottom: '20px' }}>
              <thead>
                <tr>
                  <th style={{ border: '1px solid #DEDEE1', padding: '8px' }}>Material Name</th>
                  <th style={{ border: '1px solid #DEDEE1', padding: '8px' }}>Quantity</th>
                </tr>
              </thead>
              <tbody>
                {recordDetails?.record_details?.map((record, index) => (
                  <tr key={index}>
                    <td style={{ border: '1px solid #DEDEE1', padding: '8px' }}>{record.material_name}</td>
                    <td style={{ border: '1px solid #DEDEE1', padding: '8px' }}>{record.quantity}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </CCol>
        </CModalBody>
        <CModalFooter>
          <CButton color="aimhi-textlight-neutral" onClick={() => handleSubmit()}>
            Receive
          </CButton>
        </CModalFooter>
      </CModal>
    </>
  );
};

export default ReceiveTransferMaterial;
